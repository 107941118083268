<template>
    <div>
        <emailing-navigation
            :id="email_id"
            :breadcrumbs="breadcrumbs"
        >
        </emailing-navigation>

        <div class="ibox">
            <div class="ibox-title">
                <h5> Slanje testnog E-maila</h5>
            </div>
            <div class="ibox-content">
                <forms
                        :settings="form_settings"
                        @onFormButtonClick="send"
                        @onInput="onInput"
                >
                </forms>
            </div>
        </div>
    </div>
</template>

<script>
import EmailingNavigation from "../EmailingNavigation";
import Forms from "../../../../components/form/Forms";
import axios from "axios";
import toastr from 'toastr'
export default {
    name: "EmailingTest",
    components: {Forms, EmailingNavigation},
    data(){
        return {
            email_id: this.$route.params.email_id,
            breadcrumbs: [],
            errors: {},
            form_settings: {},
            email: ''
        }
    },
    methods: {

        setup_form(){

            let vm = this

            let settings = {
                width: 6,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: [
                                    {
                                        name: 'email',
                                        type: 'input',
                                        title: 'Email',
                                        value:  '',
                                        width: 12,
                                        error: vm.errors['email'] ? vm.errors['email'] : '',
                                        note: '',
                                        explanation: '',
                                        placeholder: '',
                                        required: false,
                                        readonly: vm.disabled,
                                        disabled: vm.disabled,
                                        config: {
                                            title_width: 4,
                                            input_width: 8
                                        }
                                    }

                                ],
                            }
                        ],
                    },
                ],
                buttons: [
                    {
                        id: 'send',
                        title: 'Pošalji',
                        type: 'primary',
                        action: 'send',
                        config: {
                            method: 'POST',
                            url: process.env.VUE_APP_API_URL + vm.url
                        }
                    }
                ]
            }

            vm.form_settings = settings
        },

        onInput( name, value ){
            this.email = value
        },

        send(){
            let vm = this
            if( this.email )
                axios.get( process.env.VUE_APP_API_URL + 'emailing-predlozak/test/' + vm.email_id + '/' + vm.email )
                    .then( function ( response ) {
                        if( response.data.success ){
                            toastr.success( 'Uspješno poslano!')
                        } else {
                            toastr.error( response.data.messages.general )
                        }
                        console.log( response )
                    } )
                    .catch( function ( error ) {
                        console.log( error )
                    } )
        },

        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/emailing/' + this.email_id + '/log',
                    name: 'Logs'
                }
            ]
        },
    },
    mounted() {
        this.setBreadcrumbs()
        this.setup_form()
    }
}
</script>

<style scoped>

</style>