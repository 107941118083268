<template>
    <div>
        <pogodnosti-navigation
                :id="pogodnost_id"
                :breadcrumbs="breadcrumbs"
        >
        </pogodnosti-navigation>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Nova kategorija</h5>
            </div>
            <div class="ibox-content">
                <pogodnost-kategorija2-forma
                        :disabled="false"
                        :id="null"
                        :modal="false"
                        :action="'create'"
                >
                </pogodnost-kategorija2-forma>
            </div>
        </div>
    </div>
</template>

<script>
import PogodnostiNavigation from "../PogodnostiNavigation";
import PogodnostKategorija2Forma from "./PogodnostKategorija2Forma";
export default {
    name: "PogodnostKategorija2Create",
    components: {PogodnostKategorija2Forma, PogodnostiNavigation},
    data(){
        return {
            breadcrumbs: [],
            pogodnost_id: this.$route.params.pogodnost_id,
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/pogodnosti/' + this.pogodnost_id + '/kategorije2',
                    name: 'Kategorije'
                },
                {
                    path: '/pogodnosti/' + this.pogodnost_id + '/kategorije2/create',
                    name: 'Nova kategorija'
                }
            ]
        }
    },
    mounted() {
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>