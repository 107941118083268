<template>
    <div>
        <forms
                :settings="settings"
                @onFormButtonClick="onFormButtonClick"
                @onInput="onInput"
        ></forms>
    </div>
</template>

<script>
import axios from "axios";
import toastr from 'toastr';
import Forms from "@/components/form/Forms";
export default {
    name: "AdministratorForma",
    components:{ Forms },
    props:{
        user:{
            type: Object
        },
        disabled:{
            type: Boolean,
            default: false
        },
        id:{
            default: null
        },
        modal:{
            type: Boolean,
            default: false
        },
        action: {
            type: String
        }
    },
    data(){
        return {
            settings: {},
            errors: {},
            route: '/administratori',
            url: 'user',
            data: {},
            input_data: {},
        }
    },

    methods: {

        init(){
            let vm = this
            if ( vm.action === 'edit' || vm.action === 'show' ){

                axios.get( process.env.VUE_APP_API_URL + vm.url + '/' + vm.id )
                        .then( function ( response ) {
                            vm.data = response.data
                            vm.$emit( 'returnData', response.data )
                            vm.setupForm()
                        } )
                        .catch( function ( error ) {
                            if(error instanceof Error) {
                                console.log( 'Error: ' . error);
                            } else {
                                console.log( 'Unexpected response: ' . error);
                            }
                        } )

            } else if ( vm.action === 'create' ) {
                vm.data = {}
                vm.setupForm()
            } else {
                return
            }
        },

        setupForm(){

            let vm = this;

            vm.buttons = []

            let button_cancel = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
                action: 'redirect', // request
                config: {
                    url: vm.route + ( vm.action === 'edit' ? ( '/' + vm.id) : '' )
                }
            }

            let button_delete = {
                id: 'delete',
                title: 'Obriši',
                type: 'danger',
                action: 'request',
                config: {
                    method: 'DELETE',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let button_edit = {
                id: 'edit',
                title: 'Uredi',
                type: 'success',
                action: 'redirect',
                config: {
                    url: vm.route +  '/' + vm.id +'/edit'
                }
            }

            let button_save = {
                id: 'save',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + vm.url
                }
            }

            let button_update = {
                id: 'update',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'PUT',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let buttons = []


            if( vm.action === 'edit' ){
                buttons.push( button_cancel)
                buttons.push( button_update)
            } else if( vm.action === 'show' ) {
                buttons.push( button_cancel )
                buttons.push( button_delete )
                buttons.push( button_edit )
            }
            else if ( vm.action === 'create' ) {
                buttons.push( button_cancel )
                buttons.push( button_save )
            } else {
                return
            }



            let username = vm.input_data.username
                    ? vm.input_data.username
                    : (
                            vm.data.username
                                    ? vm.data.username
                                    : ''
                    )

            let username_input = {
                name: 'username',
                type: 'input',
                title: 'Korisničko ime',
                value: username,
                width: 12,
                error: vm.errors['username'] ? vm.errors['username'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {} // email = true || password = true
            }



            let email = vm.input_data.email
                    ? vm.input_data.email
                    : (
                            vm.data.email
                                    ? vm.data.email
                                    : ''
                    )

            let email_input = {
                name: 'email',
                type: 'input',
                title: 'E-mail',
                value: email,
                width: 12,
                error: vm.errors['email'] ? vm.errors['email'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    'email': true
                }
            }


            let password = vm.input_data.password ? vm.input_data.password : ''

            let password_input = {
                name: 'password',
                type: 'input',
                title: 'Lozinka',
                value: password,
                width: 12,
                error: vm.errors['password'] ? vm.errors['password'] : '',
                note: vm.action === 'edit' ? 'Ako ne želite mijenjati password ostavite prazno' : '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    'password': true
                }
            }

            let password_confirm = vm.input_data.password_confirm ? vm.input_data.password_confirm : ''

            let password_confirm_input = {
                name: 'password_confirm',
                type: 'input',
                title: 'Ponovi lozinku',
                value: password_confirm,
                width: 12,
                error: vm.errors['password_confirm'] ? vm.errors['password_confirm'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    'password': true
                }
            }




            let permission = {
                key: vm.input_data.permission
                        ? vm.input_data.permission.id
                        : (
                                vm.data.permission
                                        ? vm.data.permission.id
                                        : ''
                        ),
                value: vm.input_data.permission
                        ? vm.input_data.permission.name
                        : (
                                vm.data.permission
                                        ? vm.data.permission.name
                                        : ''
                        )
            }

            let permission_input = {
                name: 'permission_id',
                type: 'select',
                title: 'Tip korisnika',
                value: permission,
                width: 12,
                error: vm.errors['permission_id'] ? vm.errors['permission_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: function ( ) {
                        let model = this

                        if( vm.action === 'show'){
                            model.options = []
                            return
                        }

                        let filters = {}

                        if( vm.user.permission.id === 1 ){
                            filters['id'] = [1]
                        } else if( vm.user.permission.id === 2 ){
                            filters['id'] = [1,2]
                        }

                        axios.get( process.env.VUE_APP_API_URL +  'user-permission', {
                            params: {
                                filters: filters
                            }
                        } )
                                .then( function ( response ) {
                                    let tmp = [];
                                    response.data.data.forEach( function ( item ) {
                                        tmp.push({
                                            key: item.id,
                                            value: item.name
                                        } );
                                    } );
                                    model.options = tmp
                                } )
                                .catch( function ( error ) {
                                    if(error instanceof Error) {
                                        console.log( 'Error: ' . error);
                                    } else {
                                        console.log( 'Unexpected response: ' . error);
                                    }
                                } )
                    },
                    multiple: false,
                }
            }



            let brend = []
            if( vm.input_data.brend )
                vm.input_data.brend.forEach( function( item ) {
                    brend.push( {
                        key: item.id,
                        value: item.naziv
                    } )
                } )
            else if( vm.data.user_brendovi ){
                vm.data.user_brendovi.forEach( function( item ) {
                    brend.push( {
                        key: item.brend.id,
                        value: item.brend.naziv
                    } )
                } )
            }
            let brend_input = {
                name: 'brend_id',
                type: 'select',
                title: 'Brendovi',
                value: brend,
                width: 12,
                error: vm.errors['brend_id'] ? vm.errors['brend_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled || vm.user.permission.id === 1,
                config: {
                    options: function ( ) {
                        let model = this

                        if( vm.action === 'show'){
                            model.options = []
                            return
                        }

                        axios.get( process.env.VUE_APP_API_URL +  'brend' )
                                .then( function ( response ) {
                                    let tmp = [];
                                    response.data.data.forEach( function ( item ) {
                                        tmp.push({
                                            key: item.id,
                                            value: item.naziv
                                        } );
                                    } );
                                    model.options = tmp
                                } )
                                .catch( function ( error ) {
                                    if(error instanceof Error) {
                                        console.log( 'Error: ' . error);
                                    } else {
                                        console.log( 'Unexpected response: ' . error);
                                    }
                                } )
                    },
                    multiple: true,
                }
            }


            let poslovnica = []
            if( vm.input_data.user_poslovnica )
                vm.input_data.poslovnica.forEach( function( item ) {
                    poslovnica.push( {
                        key: item.id,
                        value: item.naziv
                    } )
                } )
            else if( vm.data.user_poslovnice ){
                vm.data.user_poslovnice.forEach( function( item ) {
                    poslovnica.push( {
                        key: item.poslovnica.id,
                        value: item.poslovnica.naziv
                    } )
                } )
            }
            let poslovnica_input = {
                name: 'poslovnica_id',
                type: 'select',
                title: 'Poslovnice',
                value: poslovnica,
                width: 12,
                error: vm.errors['poslovnica_id'] ? vm.errors['poslovnica_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled || vm.user.permission.id === 1,
                config: {
                    options: function ( ) {
                        let model = this

                        if( vm.action === 'show'){
                            model.options = []
                            return
                        }

                        let brend_ids = []
                        brend.forEach( function ( item ) {
                            brend_ids.push( item.key )
                        } )

                        axios.get( process.env.VUE_APP_API_URL + 'poslovnica', {
                            params: {
                                filters: {
                                    brend_id: brend_ids
                                }
                            }
                        } )
                                .then( function ( response ) {
                                    let tmp = [];
                                    response.data.data.forEach( function ( item ) {
                                        tmp.push({
                                            key: item.id,
                                            value: item.naziv
                                        } );
                                    } );
                                    model.options = tmp
                                } )
                                .catch( function ( error ) {
                                    if(error instanceof Error) {
                                        console.log( 'Error: ' . error);
                                    } else {
                                        console.log( 'Unexpected response: ' . error);
                                    }
                                } )
                    },
                    multiple: true,
                }
            }


            let inputs = []
            inputs.push( username_input )
            inputs.push( email_input )
            inputs.push( password_input )
            inputs.push( password_confirm_input )
            inputs.push( permission_input )
            inputs.push( brend_input )
            inputs.push( poslovnica_input )


            let settings = {
                width: 6,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: inputs,
                            }
                        ],
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },

        /**
         *  on input return data
         */
        onInput( name, value ){
            let vm = this
            if( name === 'brend_id') {
                if( value )
                    axios.get( process.env.VUE_APP_API_URL +  'brend/', {
                        params: {
                            filters: {
                                id: value
                            }
                        }
                    } )
                            .then( function ( response ) {
                                vm.input_data['brend'] = []
                                response.data.data.forEach( function( item ) {
                                    vm.input_data['brend'].push(item)
                                } )
                                vm.input_data['poslovnica'] = []
                                vm.init()
                            } )
                            .catch( function ( error ) {
                                if(error instanceof Error) {
                                    console.log( 'Error: ' . error);
                                } else {
                                    console.log( 'Unexpected response: ' . error);
                                }
                            } )
                else vm.input_data['brend'] = []
            } else if ( name === 'poslovnica_id' ){
                if ( value )
                    axios.get( process.env.VUE_APP_API_URL +  'poslovnica/', {
                        params: {
                            filters: {
                                brend_id: value
                            }
                        }
                    } )
                            .then( function ( response ) {
                                vm.input_data['poslovnica'] = []
                                response.data.data.forEach( function( item ) {
                                    vm.input_data.push(  item )
                                } )
                            } )
                            .catch( function ( error ) {
                                if(error instanceof Error) {
                                    console.log( 'Error: ' . error);
                                } else {
                                    console.log( 'Unexpected response: ' . error);
                                }
                            } )
                else
                    vm.input_data['poslovnica'] = []
            } else if ( name === 'permission_id' ){
                if ( value )
                    axios.get( process.env.VUE_APP_API_URL +  'user-permission/' + value )
                            .then( function ( response ) {
                                vm.input_data['permission'] = response.data
                            } )
                            .catch( function ( error ) {
                                if(error instanceof Error) {
                                    console.log( 'Error: ' . error);
                                } else {
                                    console.log( 'Unexpected response: ' . error);
                                }
                            } )
                else
                    vm.input_data['permission'] = null
            } else {
                vm.input_data[name] = value
            }
        },

        /**
         *  on button click
         */
        onFormButtonClick( id, action, config, data  ){

            if( id === 'save' || id === 'update' ){
                if( data.success){
                    if( id == 'save' )
                        toastr.success( 'Uspješno spremljeno!' )
                    else if ( id == 'update' )
                        toastr.success( 'Uspješno ažurirano!' )
                    this.$router.push({ path: this.route + '/' + data.data.id } )
                } else {
                    this.errors = data.messages
                    this.init()
                }
            } else if( id == 'delete' ){
                toastr.error( 'Uspješno obrisano!' )
                this.$router.push({ path: this.route } )
            }

        },


    },
    mounted() {
        this.init()
    }
}
</script>

<style scoped>

</style>