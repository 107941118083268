<template>
    <div class="" style="color: black">
        <div class="input-group" style="min-width: 150px">
            <input @input="setFilter" type="text" placeholder="od" class="form-control"  v-if="filter" v-model="filter.value.from">
            <span class="input-group-append"> <button @click="unsetFilter('from')" type="button" class="btn btn-sm btn-dark">x</button> </span>
        </div>
        <div class="input-group" style="min-width: 150px">
            <input @input="setFilter" type="text" placeholder="do" class="form-control" v-if="filter" v-model="filter.value.to">
            <span class="input-group-append"> <button @click="unsetFilter('to')" type="button" class="btn btn-sm btn-dark">x</button> </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "NumberFilter",
        props:[
            'data',
            'name',
        ],
        data(){
            return {
                filter: null,
            }
        },
        methods:{
            setFilter(){
                let vm = this;

                setTimeout(function() {
                    vm.$emit( 'set_filter', {
                        key: vm._props.name,
                        value:{
                            from: vm.filter.value.from,
                            to: vm.filter.value.to,
                        }
                    } );
                }, 500);

            },
            unsetFilter( f ){
                this.filter.value[f] = null;
                this.setFilter();
            }
        },
        beforeMount(){
            this.filter = this._props.data;
        },
        mounted() {
        },
    }
</script>

<style scoped>

</style>