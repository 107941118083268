<template>
    <div>
        <kupci-navigation
                :id="id"
                :breadcrumbs="breadcrumbs"
                :user="user"
        >
        </kupci-navigation>


        <div v-show="show_kupac" class="ibox ">
            <div class="ibox-title">
                <h5>Kupac: {{ naziv }} </h5>
            </div>
            <div class="ibox-content">
                <kupac-forma
                        :disabled="true"
                        :id="id"
                        :modal="false"
                        :action="'show'"
                        @returnData="setData"
                        :user="user"
                >
                </kupac-forma>
            </div>
        </div>
    </div>
</template>

<script>
import KupacForma from "./KupacForma";
import KupciNavigation from "./KupciNavigation";
export default {
    name: "KupacDetails",
    components: {KupacForma, KupciNavigation},
    props: ['user'],
    data(){
        return {
            id: this.$route.params.kupac_id,
            breadcrumbs: [],
            naziv: '',
            show_kupac: true,
            kupac: null,
        }
    },
    methods:{
        setData( data ){
            this.naziv = data.ime && data.prezime ? ( data.ime + ' ' + data.prezime ) : ''
            this.kupac = data
        },
    },
    mounted() {
    }
};
</script>

<style scoped>

</style>