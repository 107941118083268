<template>
    <div>
        <breadcrumbs
                :title="'Države'"
                :breadcrumbs="breadcrumbs"
        >
        </breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Nova država</h5><br>
            </div>
            <div class="ibox-content">
                <drzava-forma
                        :disabled="false"
                        :id="null"
                        :modal = false
                        :action="'create'"
                >
                </drzava-forma>
            </div>
        </div>
    </div>

</template>

<script>
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import DrzavaForma from "./DrzavaForma";
export default {
    name: "DrzavaCreate",
    components: {DrzavaForma, Breadcrumbs},
    data(){
        return {
            breadcrumbs: []
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/postavke/drzave',
                    name: 'Države'
                },
                {
                    path: '/postavke/drzave/create',
                    name: 'Nova država'
                }
            ]
        }
    },
    mounted() {
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>