<template>
    <div class="form-check">
        <input class="" type="checkbox" value="" checked id="filledInCheckbox" style="width: 20px; height: 20px; background: green;" >
        <label class="" for="filledInCheckbox"></label>
    </div>
</template>

<script>
export default {
name: "CheckboxCell"
}
</script>

<style scoped>


</style>