import { render, staticRenderFns } from "./PoslovnicaCreate.vue?vue&type=template&id=474b6204&scoped=true&"
import script from "./PoslovnicaCreate.vue?vue&type=script&lang=js&"
export * from "./PoslovnicaCreate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "474b6204",
  null
  
)

export default component.exports