<template>
    <tbody class="">
        <tr v-for="(row, key) in data" :key="key" @click="rowClick(row)" >
            <td v-for="col in headers" :key="col.id">
                <div v-if="typeof col.type === 'function'">
                    {{ col.type.call( this, row, col ) }}
                </div>
                <div v-else>
                    <component
                            :is="col.type + '-cell'"
                            :row="row"
                            :col="col"
                            @buttonClick="buttonClick"
                            @checkboxHandler="checkboxHandler"
                            @inputHandler="inputHandler"
                            @selectHandler="selectHandler"
                            @multiselectHandler="multiselectHandler"
                            @switchHandler="switchHandler"
                    ></component>
                </div>
            </td>
        </tr>
    </tbody>

</template>

<script>

import ButtonCell from "@/components/datatable/parts/cells/ButtonCell";
import CheckboxCell from "@/components/datatable/parts/cells/CheckboxCell";
import ImageCell from "@/components/datatable/parts/cells/ImageCell";
import InputCell from "@/components/datatable/parts/cells/InputCell";
import ProgressCell from "@/components/datatable/parts/cells/ProgressCell";
import SelectCell from "@/components/datatable/parts/cells/SelectCell";
import SwitchCell from "@/components/datatable/parts/cells/SwitchCell";
import TextCell from "@/components/datatable/parts/cells/TextCell";
import TrendCell from "@/components/datatable/parts/cells/TrendCell";

export default {
    name: "Data",
    components: {
        ButtonCell,
        CheckboxCell,
        ImageCell,
        InputCell,
        ProgressCell,
        SelectCell,
        SwitchCell,
        TextCell,
        TrendCell
    },
    props:{
        table_header:{
            type: Array,
            required: true,
        },
        data:{
            type: Array,
            default: function (){
                return [];
            },
        }
    },
    data(){
        return {
            headers: []
        }
    },
    methods:{
        init(){
            let vm = this
            vm.headers = []
            vm.$props.table_header.forEach( function( item ) {
                if( item.visible && item.column_visibility_group.visible )
                    vm.headers.push( item )
            } )
        },


        // row click handler
        rowClick( row ){
            this.$emit( 'rowClick', row )
        },

        // buttons handler
        buttonClick( col, row ){
            this.$emit( 'buttonClick', col, row )
        },

        // checkbox handler
        checkboxHandler( col, row, value ){
            this.$emit( 'checkboxHandler', col, row, value )
        },

        // input handler
        inputHandler( col, row, value ){
            this.$emit( 'inputHandler', col, row, value )
        },

        // select handler
        selectHandler( col, row, value ){
            this.$emit( 'selectHandler', col, row, value )
        },

        // multiselect handler
        multiselectHandler( col, row, value ){
            this.$emit( 'multiselectHandler', col, row, value )
        },

        // switch handler
        switchHandler( col, row, value ){
            this.$emit( 'switchHandler', col, row, value )
        }

    },
    mounted() {
        this.init()
    },
    watch:{
        table_header:{
            handler: function (){
                this.init()
            },
            deep: true
        }
    }
}
</script>

<style scoped>

</style>