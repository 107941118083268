<template>
    <div>

        <breadcrumbs
                :title="'Pogodnosti'"
                :breadcrumbs="allBreadcrumbs"
        >
        </breadcrumbs>

        <nav class="navbar navbar-expand-lg navbar-light" style="background-color: #2F4050;">
            <a
                    :class="{
                            'navbar-brand btn btn-light btn-xl': true,
                            'active': active_route === route
                        }"
                    @click="open(route)"
            >
                <strong>Pogodnost</strong>
            </a>

            <div v-if="pogodnost" class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">



                    <li class="nav-item">
                        <a
                                role="button"
                                :class="{
                                    'nav-link btn btn-light': true,
                                    'active': active_route === route + '/kategorije2'
                                }"
                                @click.prevent="open( route + '/kategorije2' )"
                        >
                            <strong>Kategorije</strong>
                        </a>
                    </li>



                </ul>
            </div>
        </nav>


    </div>
</template>

<script>

import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import axios from "axios";
export default {
    name: "PogodnostiNavigation",
    components: {Breadcrumbs},
    props:[
        'user',
        'id',
        'breadcrumbs'
    ],
    data(){
        return {
            pogodnost : null,
            naziv: '',
            route: '/pogodnosti/' + this.id,
            active_route: '',
            allBreadcrumbs: []
        }
    },
    methods:{
        open( name ){
            if( name !== this.active_route ){
                this.active_route = name
                this.$router.push( name )
            }
        },
        getKupac(){
            let vm = this
            axios.get( process.env.VUE_APP_API_URL + 'pogodnost' + '/' + vm.id )
                    .then( function ( response ) {
                        vm.pogodnost = response.data
                        vm.setBreadcrumbs()
                    } )
                    .catch( function ( error ) {
                        if(error instanceof Error) {
                            console.log( 'Error: ' . error);
                        } else {
                            console.log( 'Unexpected response: ' . error);
                        }
                    } )
        },
        setBreadcrumbs(){
            let vm = this

            vm.allBreadcrumbs = [
                {
                    path: '/pogodnosti',
                    name: 'Pogodnosti'
                },
                {
                    path: ( '/pogodnosti/' + this.id ),
                    name: ( 'Pogodnost: ' + this.pogodnost.naziv )
                },
            ]

            vm.breadcrumbs.forEach( function( b ){
                vm.allBreadcrumbs.push( b )
            } )
        }
    },
    mounted() {
        this.active_route = this.$route.path
        this.getKupac()
    },
    watch:{
        breadcrumbs: {
            deep: true,
            handler: function(){
                //this.setBreadcrumbs()
            }
        },
    },
};
</script>

<style scoped>

</style>