<template>
    <div>
        <kupci-navigation
                v-if="user"
                :id="kupac_id"
                :breadcrumbs="breadcrumbs"
                :user="user"
        ></kupci-navigation>
        <div class="ibox">
            <div class="ibox-title">
                <h5> Povijest promjena </h5>
            </div>
            <div class="ibox-content">
                <datatables
                        v-if="show_datatable"
                        :url="datatables_url"
                        :settings="datatables.settings"
                        :title="datatables.title"
                >
                </datatables>
            </div>
        </div>
    </div>

</template>

<script>
import Datatables from "../../../../components/datatable/Datatables";
import KupciNavigation from "../KupciNavigation";
import axios from "axios";

export default {
    name: "PovijestPromjena",
    components: {Datatables, KupciNavigation},
    data(){
        return {
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL + 'kupac-povijest-promjena',
            datatables: {},
            breadcrumbs: [],
            kupac_id: this.$route.params.kupac_id,
            user: null
        }
    },
    methods:{
        setDatatable() {

            let vm = this;

            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'kupac_id',
                            'visible': false,
                            'filter':{
                                'type': 'none',
                                'value': vm.kupac_id
                            },
                        },
                        {
                            'id': 'created_at',
                            'title': 'Datum i vrijeme',
                            'searchable': false,
                            'column_visibility_group': {
                                id: 'povijest',
                            }
                        },
                        {
                            'id': 'korisnik',
                            'title': 'Korisnik',
                            'type': function ( row ){
                                return row.user ? row.user.username : ''
                            },
                            'searchable': false,
                            'column_visibility_group': {
                                id: 'povijest',
                            }
                        },
                        {
                            'id': 'ime',
                            'title': 'Ime',
                            'searchable': false,
                            'type' : function( row ){
                                return row.arhiva ? ( row.arhiva.ime ) : ''
                            },
                            'column_visibility_group': {
                                id: 'povijest',
                            }
                        },
                        {
                            'id': 'prezime',
                            'title': 'Prezime',
                            'searchable': false,
                            'type' : function( row ){
                                return row.arhiva ? ( row.arhiva.prezime ) : ''
                            },
                            'column_visibility_group': {
                                id: 'povijest',
                            }
                        },
                        {
                            'id': 'datum_rodjenja',
                            'title': 'Datum rođenja',
                            'searchable': false,
                            'type' : function( row ){
                                return row.arhiva ? ( row.arhiva.datum_rodjenja ) : ''
                            },
                            'column_visibility_group': {
                                id: 'povijest',
                            }
                        },
                        {
                            'id': 'spol',
                            'title': 'Spol',
                            'searchable': false,
                            'type' : function( row ){
                                return row.arhiva && ( ["0","1","2"].includes( row.arhiva.spol ) || [0,1,2].includes( row.arhiva.spol ) )
                                        ? (
                                                row.arhiva.spol == "0"
                                                        ? 'Ž'
                                                        : (
                                                                row.arhiva.spol == "1"
                                                                        ? 'M'
                                                                        : 'Nedefinirano'
                                                        )
                                        )
                                        : ''
                            },
                            'column_visibility_group': {
                                id: 'povijest',
                            }
                        },
                        {
                            'id': 'tip_kupca_id',
                            'title': 'Tip kupca',
                            'searchable': false,
                            'type' : function( row ){
                                return row.arhiva ? row.arhiva.tip_kupca_id : ''
                            },
                            'column_visibility_group': {
                                id: 'povijest',
                            }
                        },
                        {
                            'id': 'email',
                            'title': 'E-mail',
                            'searchable': false,
                            'type' : function( row ){
                                return row.arhiva ? ( row.arhiva.email ) : ''
                            },
                            'column_visibility_group': {
                                id: 'povijest',
                            }
                        },
                        {
                            'id': 'mobitel',
                            'title': 'Mobital',
                            'searchable': false,
                            'type' : function( row ){
                                return row.arhiva ? ( row.arhiva.mobitel ) : ''
                            },
                            'column_visibility_group': {
                                id: 'povijest',
                            }
                        },
                        {
                            'id': 'adresa',
                            'title': 'Adresa',
                            'searchable': false,
                            'type' : function( row ){
                                return row.arhiva ? ( row.arhiva.adresa ) : ''
                            },
                            'column_visibility_group': {
                                id: 'povijest',
                            }
                        },
                        {
                            'id': 'mjesto',
                            'title': 'Mjesto',
                            'searchable': false,
                            'type' : function( row ){
                                return row.arhiva ? ( row.arhiva.posta_id ) : ''
                            },
                            'column_visibility_group': {
                                id: 'povijest',
                            }
                        },
                        {
                            'id': 'privola_email',
                            'title': 'Privola email',
                            'searchable': false,
                            'type' : function( row ){
                                return row.arhiva ? ( row.arhiva.privola_email ) : ''
                            },
                            'column_visibility_group': {
                                id: 'povijest',
                            }
                        },
                        {
                            'id': 'privola_mobitel',
                            'title': 'Privola mobitel',
                            'searchable': false,
                            'type' : function( row ){
                                return row.arhiva ? ( row.arhiva.privola_mobitel ) : ''
                            },
                            'column_visibility_group': {
                                id: 'povijest',
                            }
                        },
                        {
                            'id': 'privola_posta',
                            'title': 'Privola posta',
                            'searchable': false,
                            'type' : function( row ){
                                return row.arhiva ? ( row.arhiva.privola_posta ) : ''
                            },
                            'column_visibility_group': {
                                id: 'povijest',
                            }
                        },
                        {
                            'id': 'napomena',
                            'title': 'Napomena',
                            'searchable': false,
                            'type' : function( row ){
                                return row.arhiva ? ( row.arhiva.napomena ) : ''
                            },
                            'column_visibility_group': {
                                id: 'povijest',
                            }
                        },
                        {
                            'id': 'napomena2',
                            'title': 'Napomena',
                            'searchable': false,
                            'type' : function( row ){
                                return row.arhiva ? ( row.arhiva.napomena2 ) : ''
                            },
                            'column_visibility_group': {
                                id: 'povijest',
                            }
                        }
                    ],
                    column_visibility_groups:{
                        'povijest' : 'Povijest promjena'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Povijest promjena',
            }
            vm.show_datatable = true;
        },

        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/kupci/' + this.kupac_id + '/povijest-promjena',
                    name: 'Povijest promjena'
                }
            ]
        },

    },
    mounted() {
        let vm = this
        axios.get( process.env.VUE_APP_API_URL + 'user-auth' )
            .then( function ( response ) {
                vm.user = response.data

            } )
            .catch( function ( error ) {
                if(error instanceof Error) {
                    console.log( 'Error: ' . error);
                } else {
                    console.log( 'Unexpected response: ' . error);
                }
            } )
        vm.setBreadcrumbs()
        vm.setDatatable()
    }
}
</script>

<style scoped>

</style>