<template>
    <div class="row">
        <label :class="title_width"  :for="name" >
            <strong class="float-lg-right float-md-left">{{title}} {{ required ? '*' : ''}}</strong>
        </label>
        <div :class="input_width">
            <span class="form-text m-b-none text-success">
                    {{ note }}
            </span>
            <div class="input-group-append">
                <input
                        :name="name"
                        v-model="ret_value"
                        :placeholder="placeholder"
                        :readonly="readonly"
                        :disabled="disabled"
                        :type=" (config.email ? 'email': '') || (config.password ? 'password': '') || (config.hidden ? 'hidden': '')"
                        class="form-control"
                        @input="_return"
                />
                <span
                        v-if="explanation"
                        class="input-group-addon"
                        data-toggle="tooltip"
                        data-placement="right"
                        :title="explanation"
                >
                    <i class="fa fa-info" aria-hidden="true"></i>
                </span>
            </div>
            <span class="form-text m-b-none text-danger">
                <div class="row">
                    <div class="col-lg-6">
                    {{ error[0] }}
                    </div>
<!--                <div class="col-md-6">-->
<!--                    <button class="btn float-right" style="margin-left: 2px; margin-right: 2px">123</button>-->
<!--                </div>-->
                </div>
            </span>
        </div>

    </div>
</template>


<script>
    export default {
        name: "InputComponent",
        props: [
            'name',
            'value',
            'title',
            'error',
            'note',
            'explanation',
            'placeholder',
            'required',
            'readonly',
            'disabled',
            'config'
        ],
        data(){
            return {
                ret_value: this.value,
                disabled_input: this.disabled,
                title_width: ('col-form-label col-lg-' + ( this.config.title_width ? this.config.title_width : '4' ) ),
                input_width: ('col-lg-' + ( this.config.input_width ? this.config.input_width : '8' ) )
            }
        },
        methods:{
            _return() {
                this.$emit('onInput', this.name, this.ret_value );
            }
        },
        mounted() {
            this.ret_value = this.value
            this._return()
            this.$emit( 'returnComponent', this.name, this )
        },
        watch: {
            value(){
                this.ret_value = this.value
                this._return()
            }
        }
    }
</script>

<style scoped>

</style>
