<template>
    <div>
        <forms
                :settings="settings"
                @onFormButtonClick="onFormButtonClick"
                @onInput="onInput"
        ></forms>
    </div>
</template>

<script>
import axios from "axios";
import toastr from 'toastr';
import Forms from "@/components/form/Forms";
export default {
    name: "EmailingForma",
    components:{ Forms },
    props:{
        user:{
            type: Object
        },
        disabled:{
            type: Boolean,
            default: false
        },
        id:{
            default: null
        },
        modal:{
            type: Boolean,
            default: false
        },
        action: {
            type: String
        }
    },
    data(){
        return {
            settings: {},
            errors: {},
            route: '/emailing',
            url: 'emailing-predlozak',
            data: {},
            input_data: {}
        }
    },

    methods: {

        init(){
            let vm = this
            if ( vm.action === 'edit' || vm.action === 'show' ){

                axios.get( process.env.VUE_APP_API_URL + vm.url + '/' + vm.id )
                        .then( function ( response ) {
                            vm.data = response.data
                            console.log( response.data )
                            vm.$emit( 'returnData', response.data )
                            vm.setupForm()
                        } )
                        .catch( function ( error ) {
                            if(error instanceof Error) {
                                console.log( 'Error: ' . error);
                            } else {
                                console.log( 'Unexpected response: ' . error);
                            }
                        } )
            } else if ( vm.action === 'create' ) {
                vm.data = {}
                vm.setupForm()
            } else {
                return
            }
        },

        setupForm(){

            let vm = this;

            vm.buttons = []

            let button_cancel = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
                action: 'redirect', // request
                config: {
                    url: vm.route + ( vm.action === 'edit' ? ( '/' + vm.id) : '' )
                }
            }

            let button_delete = {
                id: 'delete',
                title: 'Obriši',
                type: 'danger',
                action: 'request',
                config: {
                    method: 'DELETE',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let button_edit = {
                id: 'edit',
                title: 'Uredi',
                type: 'success',
                action: 'redirect',
                config: {
                    url: vm.route +  '/' + vm.id +'/edit'
                }
            }

            let button_save = {
                id: 'save',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + vm.url
                }
            }

            let button_update = {
                id: 'update',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'PUT',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let buttons = []


            if( vm.action === 'edit' ){
                buttons.push( button_cancel)
                buttons.push( button_update)
            } else if( vm.action === 'show' ) {
                buttons.push( button_cancel )
                buttons.push( button_delete )
                buttons.push( button_edit )
            }
            else if ( vm.action === 'create' ) {
                buttons.push( button_cancel )
                buttons.push( button_save )
            } else {
                return
            }

            let brend = {
                key: vm.input_data.brend
                        ? vm.input_data.brend.id
                        : (
                                vm.data.brend
                                        ? vm.data.brend.id
                                        : ''
                        ),
                value: vm.input_data.brend
                        ? vm.input_data.brend.naziv
                        : (
                                vm.data.brend
                                        ? vm.data.brend.naziv
                                        : ''
                        )
            }
            let brend_input = {
                name: 'brend_id',
                type: 'select',
                title: 'Brend',
                value: brend,
                width: 12,
                error: vm.errors['brend_id'] ? vm.errors['brend_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: function ( ) {
                        let model = this

                        if( vm.action === 'show'){
                            model.options = []
                            return
                        }


                        axios.get( process.env.VUE_APP_API_URL +  'brend', {
                            params: {
                                page: 1,
                                per_page: -1,
                            }
                        } )
                                .then( function ( response ) {
                                    let tmp = [];
                                    response.data.data.forEach( function ( item ) {
                                        tmp.push({
                                            key: item.id,
                                            value: item.naziv
                                        } );
                                    } );
                                    model.options = tmp
                                } )
                                .catch( function ( error ) {
                                    if(error instanceof Error) {
                                        console.log( 'Error: ' . error);
                                    } else {
                                        console.log( 'Unexpected response: ' . error);
                                    }
                                } )
                    },
                    title_width: 2,
                    input_width: 4
                }
            }



            let emailing_tip = {
                key: vm.input_data.emailing_tip
                        ? vm.input_data.emailing_tip.id
                        : (
                                vm.data.emailing_tip
                                        ? vm.data.emailing_tip.id
                                        : ''
                        ),
                value: vm.input_data.emailing_tip
                        ? vm.input_data.emailing_tip.naziv
                        : (
                                vm.data.emailing_tip
                                        ? vm.data.emailing_tip.naziv
                                        : ''
                        )
            }
            let emailing_tip_input = {
                name: 'emailing_tip_id',
                type: 'select',
                title: 'Tip',
                value: emailing_tip,
                width: 12,
                error: vm.errors['emailing_tip_id'] ? vm.errors['emailing_tip_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: function ( ) {
                        let model = this

                        if( vm.action === 'show'){
                            model.options = []
                            return
                        }


                        axios.get( process.env.VUE_APP_API_URL +  'emailing-tip', {
                            params: {
                                page: 1,
                                per_page: -1,
                            }
                        } )
                                .then( function ( response ) {
                                    let tmp = [];
                                    response.data.data.forEach( function ( item ) {
                                        tmp.push({
                                            key: item.id,
                                            value: item.naziv
                                        } );
                                    } );
                                    model.options = tmp
                                } )
                                .catch( function ( error ) {
                                    if(error instanceof Error) {
                                        console.log( 'Error: ' . error);
                                    } else {
                                        console.log( 'Unexpected response: ' . error);
                                    }
                                } )
                    },
                    title_width: 2,
                    input_width: 4
                }
            }

            let emailing_settings = {
                key: vm.input_data.emailing_settings_tip
                        ? vm.input_data.emailing_settings.id
                        : (
                                vm.data.emailing_settings
                                        ? vm.data.emailing_settings.id
                                        : ''
                        ),
                value: vm.input_data.emailing_settings
                        ? vm.input_data.emailing_settings.settings.from.address
                        : (
                                vm.data.emailing_settings
                                        ? vm.data.emailing_settings.settings.from.address
                                        : ''
                        )
            }
            let emailing_settings_input = {
                name: 'emailing_settings_id',
                type: 'select',
                title: 'Postavke za slanje',
                value: emailing_settings,
                width: 12,
                error: vm.errors['emailing_settings_id'] ? vm.errors['emailing_settings_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: function ( ) {
                        let model = this

                        if( vm.action === 'show'){
                            model.options = []
                            return
                        }


                        axios.get( process.env.VUE_APP_API_URL +  'emailing-setting', {
                            params: {
                                page: 1,
                                per_page: -1,
                            }
                        } )
                                .then( function ( response ) {
                                    let tmp = [];
                                    response.data.data.forEach( function ( item ) {
                                        tmp.push({
                                            key: item.id,
                                            value: item.settings.from.address
                                        } );
                                    } );
                                    model.options = tmp
                                } )
                                .catch( function ( error ) {
                                    if(error instanceof Error) {
                                        console.log( 'Error: ' . error);
                                    } else {
                                        console.log( 'Unexpected response: ' . error);
                                    }
                                } )
                    },
                    title_width: 2,
                    input_width: 4
                }
            }


            let aktivan = {
                key: vm.input_data.aktivan
                        ? vm.input_data.aktivan === '1' ? '1' : '0'
                        : vm.data.aktivan && vm.data.aktivan === '1' ? '1' : '0',
                value: vm.input_data.aktivan
                        ? ( vm.input_data.aktivan === '1' ? 'DA' : 'NE' )
                        : (
                                vm.data.aktivan && vm.data.aktivan === '1'
                                        ? 'DA'
                                        : 'NE'
                        )
            }

            let aktivan_input = {
                        name: 'aktivan',
                        type: 'select',
                        title: 'Aktivan',
                        value: aktivan,
                        width: 12,
                        error: vm.errors['aktivan'] ? vm.errors['aktivan'] : '',
                        note: '',
                        explanation: '',
                        placeholder: '',
                        required: vm.disabled,
                        readonly: vm.disabled,
                        disabled: vm.disabled,
                        config: {
                            options: [
                                {
                                    key: '0',
                                    value: 'NE'
                                },
                                {
                                    key: '1',
                                    value: 'DA'
                                },
                            ],
                            title_width: 2,
                            input_width: 4
                        }
                    }


            let slanje_bez_privole = {
                key: vm.input_data.slanje_bez_privole
                    ? vm.input_data.slanje_bez_privole.toString() === '1' ? '1' : '0'
                    : vm.data.slanje_bez_privole && vm.data.slanje_bez_privole.toString() === '1' ? '1' : '0',
                value: vm.input_data.slanje_bez_privole
                    ? ( vm.input_data.slanje_bez_privole.toString() === '1' ? 'DA' : 'NE' )
                    : (
                        vm.data.slanje_bez_privole && vm.data.slanje_bez_privole.toString() === '1'
                            ? 'DA'
                            : 'NE'
                    )
            }

            let slanje_bez_privole_input = {
                name: 'slanje_bez_privole',
                type: 'select',
                title: 'Slanje bez privole',
                value: slanje_bez_privole,
                width: 12,
                error: vm.errors['slanje_bez_privole'] ? vm.errors['slanje_bez_privole'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: [
                        {
                            key: '0',
                            value: 'NE'
                        },
                        {
                            key: '1',
                            value: 'DA'
                        },
                    ],
                    title_width: 2,
                    input_width: 4
                }
            }



            let naslov = vm.input_data.naslov
                    ? vm.input_data.naslov
                    : (
                            vm.data.naslov
                                    ? vm.data.naslov
                                    : ''
                    )
            let naslov_input = {
                name: 'naslov',
                type: 'input',
                title: 'Naslov',
                value: naslov,
                width: 12,
                error: vm.errors['naslov'] ? vm.errors['naslov'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 2,
                    input_width: 10
                }
            }

            let text = vm.input_data.text
                    ? vm.input_data.text
                    : (
                            vm.data.text
                                    ? vm.data.text
                                    : ''
                    )
            let text_input = {
                name: 'text',
                type: 'editor',
                title: 'Email',
                value: text,
                width: 12,
                error: vm.errors['text'] ? vm.errors['text'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 1,
                    input_width: 11
                }
            }

            let inputs = []
            inputs.push( brend_input )
            inputs.push( emailing_tip_input )
            inputs.push( emailing_settings_input )
            inputs.push( aktivan_input )
            inputs.push( slanje_bez_privole_input )
            inputs.push( naslov_input )
            inputs.push( text_input )

            let settings = {
                width: 12,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: inputs,
                            }
                        ],
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },

        /**
         *  on input return data
         */
        onInput( name, value ){

            let vm = this;
            if( name === 'brend_id') {
                axios.get( process.env.VUE_APP_API_URL +  'brend/' + value )
                        .then( function ( response ) {
                            vm.input_data['brend'] = response.data
                        } )
                        .catch( function ( error ) {
                            if(error instanceof Error) {
                                console.log( 'Error: ' . error);
                            } else {
                                console.log( 'Unexpected response: ' . error);
                            }
                        } )
            } else if ( name === 'emailing_tip_id' ){
                axios.get( process.env.VUE_APP_API_URL +  'emailing-tip/' + value )
                        .then( function ( response ) {
                            vm.input_data['emailing_tip'] = response.data
                        } )
                        .catch( function ( error ) {
                            if(error instanceof Error) {
                                console.log( 'Error: ' . error);
                            } else {
                                console.log( 'Unexpected response: ' . error);
                            }
                        } )
            } else if ( name === 'emailing_settings_id' ){
                axios.get( process.env.VUE_APP_API_URL +  'emailing-setting/' + value )
                        .then( function ( response ) {
                            vm.input_data['emailing_settings'] = response.data
                        } )
                        .catch( function ( error ) {
                            if(error instanceof Error) {
                                console.log( 'Error: ' . error);
                            } else {
                                console.log( 'Unexpected response: ' . error);
                            }
                        } )
            } else {
                vm.input_data[name] = value
            }
        },

        /**
         *  on button click
         */
        onFormButtonClick( id, action, config, data  ){

            if( id === 'save' || id === 'update' ){
                if( data.success){
                    if( id == 'save' )
                        toastr.success( 'Uspješno spremljeno!' )
                    else if ( id == 'update' )
                        toastr.success( 'Uspješno ažurirano!' )
                    this.$router.push({ path: this.route + '/' + data.data.id } )
                } else {
                    this.errors = data.messages
                    this.init()
                }
            } else if( id == 'delete' ){
                toastr.error( 'Uspješno obrisano!' )
                this.$router.push({ path: this.route } )
            }

        },


    },
    mounted() {
        this.init()
    }
}
</script>

<style scoped>

</style>