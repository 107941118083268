<template>
    <div>
        <router-view
                :user="user"
        ></router-view>
    </div>
</template>

<script>
export default {
    name: "KupacKategorijaBase",
    props:['user']
}
</script>

<style scoped>

</style>