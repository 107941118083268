<template>
    <div style="margin-bottom: 50px">
        <breadcrumbs
                :title="'E-mails'"
                :breadcrumbs="breadcrumbs"
        ></breadcrumbs>

        <div class="ibox ">
            <div class="ibox-title">
                <h5>Uredi E-mail</h5>
            </div>
            <div class="ibox-content">
                <emailing-forma
                        :disabled="false"
                        :id="id"
                        :modal="false"
                        :action="'edit'"
                        @returnData="setData"
                        :user="user"
                >
                </emailing-forma>
            </div>
        </div>


    </div>
</template>

<script>
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import EmailingForma from "./EmailingForma";
export default {
    name: "EmailingEdit",
    components: {EmailingForma, Breadcrumbs},
    props:['user'],
    data(){
        return {
            id: this.$route.params.email_id,
            breadcrumbs: [],
            email: '',
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/emailing',
                    name: 'Emailing'
                },
                {
                    path: '/emailing/' + this.id ,
                    name: 'E-mail: ' + this.email.naslov + ' | ' + this.email.brend.naziv + ': ' + this.email.emailing_tip.naziv
                },
                {
                    path: '/emailing/' + this.id + '/edit',
                    name: 'Uredi email: ' + this.email.naslov + ' | ' + this.email.brend.naziv + ': ' + this.email.emailing_tip.naziv
                }
            ]
        },
        setData( data ){
            this.email = data
            this.setBreadcrumbs()
        }
    }
}
</script>

<style scoped>

</style>