<template>
    <forms
        :settings="settings"
        @onFormButtonClick="onFormButtonClick"
        @onInput="onInput"
    ></forms>
</template>

<script>
import axios from "axios";
import toastr from "toastr";
import Forms from "../../../../components/form/Forms";
export default {
    name: "PoklonForma",
    components:{ Forms },
    props:{
        disabled:{
            type: Boolean,
            default: false
        },
        id:{
            default: null
        },
        modal:{
            type: Boolean,
            default: false
        },
        action: {
            type: String
        },
        user: {
            type: Object
        },
        pogodnost_id:{
            default: null
        },
    },
    data(){
        return {
            settings: {},
            errors: {},
            url: 'poklon',
            data: {},
        }
    },

    methods: {

        init(){
            let vm = this
            if ( vm.action === 'edit' || vm.action === 'show' ){

                axios.get( process.env.VUE_APP_API_URL + vm.url + '/' + vm.id )
                    .then( function ( response ) {
                        vm.data = response.data
                        vm.$emit( 'returnData', response.data )
                        vm.setupForm()
                    } )
                    .catch( function ( error ) {
                        if(error instanceof Error) {
                            console.log( 'Error: ' . error);
                        } else {
                            console.log( 'Unexpected response: ' . error);
                        }
                    } )

            } else if ( vm.action === 'create' ) {
                vm.data = {}
                vm.setupForm()
            } else {
                return
            }
        },

        setupForm(){

            let vm = this;

            vm.buttons = []

            let button_cancel = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
                action: 'cancel', // request
            }

            let button_delete = {
                id: 'delete',
                title: 'Obriši',
                type: 'danger',
                action: 'request',
                config: {
                    method: 'DELETE',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }


            let button_save = {
                id: 'save',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + vm.url
                }
            }

            let button_update = {
                id: 'update',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'PUT',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let buttons = []


            if( vm.action === 'edit' ){
                buttons.push( button_cancel)
                buttons.push( button_delete )
                buttons.push( button_update)
            } else if( vm.action === 'show' ) {
                buttons.push( button_cancel )
                buttons.push( button_delete )
            }
            else if ( vm.action === 'create' ) {
                buttons.push( button_cancel )
                buttons.push( button_save )
            } else {
                return
            }

            let inputs = [];


            let pogodnost_id_input = {
                name: 'pogodnost_id',
                type: 'hidden',
                title: 'Pogodnost',
                value: vm.pogodnost_id,
            }

            let naziv_input = {
                name: 'naziv',
                type: 'input',
                title: 'Naziv',
                value: vm.data['naziv'] ? vm.data['naziv'] : '',
                width: 6,
                error: vm.errors['naziv'] ? vm.errors['naziv'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 2,
                    input_width: 10
                }
            }

            let vrijednost_input = {
                name: 'vrijednost',
                type: 'input',
                title: 'Vrijednost',
                value: vm.data['vrijednost'] ? vm.data['vrijednost'] : '',
                width: 4,
                error: vm.errors['vrijednost'] ? vm.errors['vrijednost'] : '',
                explanation: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }


            inputs.push( pogodnost_id_input )
            inputs.push( naziv_input )
            inputs.push( vrijednost_input )


            let settings = {
                width: 6,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: inputs,
                            },
                            // {
                            //     name: 'Pokloni: ',
                            //     width: 12,
                            //     inputs: inputs_pokloni,
                            // }
                        ],
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },


        /**
         *  on input return data
         */

        onInput( name, value ){
            //let vm = this
            let vm = this;
            vm.data[name] = value
            this.setupForm()
        },

        /**
         *  on button click
         */
        onFormButtonClick( id, action, config, data  ){
            if( id === 'save' || id === 'update' ){
                if( data.success){
                    if( id == 'save' )
                        toastr.success( 'Uspješno spremljeno!' )
                    else if ( id == 'update' )
                        toastr.success( 'Uspješno ažurirano!' )
                    this.data = {}
                    this.errors = {}
                    this.init()
                } else {
                    this.errors = data.messages
                    this.setupForm()
                }
            } else if( id == 'delete' ){
                toastr.error( 'Uspješno obrisano!' )
                this.data = {}
                this.errors = {}
                this.$emit( 'hideForm' )
            } else if( id === 'cancel' ){
                this.data = {}
                this.errors = {}
                this.$emit('hideForm')
            }

            this.$emit('reloadDatatable')

        },


    },
    mounted() {
        this.init()
    },
    watch: {
        id: function (){
            this.data = {}
            this.errors = {}
            this.init()
        },
    }
}
</script>

<style scoped>

</style>
