<template>
    <div>
        <emailing-navigation
                :id="email_id"
                :breadcrumbs="breadcrumbs"
        >
        </emailing-navigation>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Logs (zadnjih 30 dana)</h5>
            </div>
            <div class="ibox-content">
                <datatables
                        v-if="show_datatable"
                        :url="datatables_url"
                        :settings="datatables.settings"
                        :title="datatables.title"
                >
                </datatables>
            </div>
        </div>
    </div>
</template>

<script>
import EmailingNavigation from "../EmailingNavigation";
import Datatables from "../../../../components/datatable/Datatables";
import axios from "axios";
export default {
    name: "EmailingLogovi",
    components: {Datatables, EmailingNavigation},
    data(){
        return {
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL + 'emailing',
            datatables: {},
            breadcrumbs: [],
            email_id: this.$route.params.email_id,
            email: null
        }
    },
    methods:{

        init(){
            let vm = this
            axios.get( process.env.VUE_APP_API_URL + 'emailing-predlozak' + '/' + vm.email_id )
                    .then( function ( response ) {
                        vm.email = response.data
                        vm.setDatatable()
                    } )
                    .catch( function ( error ) {
                        if(error instanceof Error) {
                            console.log( 'Error: ' . error);
                        } else {
                            console.log( 'Unexpected response: ' . error);
                        }
                    } )
        },

        setDatatable() {
            let vm = this

            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'brend_id',
                            'visible': false,
                            'filter':{
                                'type': 'none',
                                'value': vm.email.brend_id
                            },
                        },
                        {
                            'id': 'emailing_tip_id',
                            'visible': false,
                            'filter':{
                                'type': 'none',
                                'value': vm.email.emailing_tip_id
                            },
                        },
                        {
                            'id': 'datum_i_vrijeme_slanja',
                            'title': 'Datum i vrijeme',
                            'searchable': false,
                            'column_visibility_group': {
                                id: 'logs',
                            }
                        },
                        {
                            'id': 'email',
                            'title': 'E-mail',
                            'searchable': false,
                            'column_visibility_group': {
                                id: 'logs',
                            }
                        },
                        {
                            'id': 'ime',
                            'title': 'Ime',
                            'searchable': false,
                            'column_visibility_group': {
                                id: 'logs',
                            }
                        },
                        {
                            'id': 'prezime',
                            'title': 'Prezime',
                            'searchable': false,
                            'column_visibility_group': {
                                id: 'logs',
                            }
                        },
                        {
                            'id': 'poslano',
                            'title': 'Poslano',
                            'type': function( row ){
                                return row.poslano ? 'DA' : 'NE'
                            },
                            'searchable': false,
                            'column_visibility_group': {
                                id: 'logs',
                            }
                        },
                        {
                            'id': 'greska',
                            'title': 'Greška',
                            'searchable': false,
                            'column_visibility_group': {
                                id: 'logs',
                            }
                        },
                    ],
                    column_visibility_groups:{
                        'legs' : 'Logs'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Logs'
            }

            vm.show_datatable = true
        },

        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/emailing/' + this.email_id + '/log',
                    name: 'Logs'
                }
            ]
        },
    },
    mounted() {
        this.setBreadcrumbs()
        this.init()
    }
}
</script>

<style scoped>

</style>