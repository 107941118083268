<template>
    <div>
        <div class="vld-parent">
            <loading :active.sync="open_loader"
                     :can-cancel="false"
                     :is-full-page="true">
            </loading>
        </div>
        <forms
                :settings="settings"
                @onFormButtonClick="onFormButtonClick"
                @onInput="onInput"
        ></forms>
    </div>
</template>

<script>
import axios from "axios"
import toastr from 'toastr'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import Forms from "@/components/form/Forms"
export default {
    name: "KupacForma",
    components:{ Forms, Loading },
    props:{
        user: {
            type: Object
        },
        disabled:{
            type: Boolean,
            default: false
        },
        id:{
            default: null
        },
        modal:{
            type: Boolean,
            default: false
        },
        action: {
            type: String
        }
    },
    data(){
        return {
            settings: {},
            errors: {},
            route: '/kupci',
            url: 'kupac',
            data: {},
            input_data: {},
            open_loader: false,

            // select options
            brendovi_select_options: [],
            poslovnice_select_options: [],
            savjetnice_select_options: [],
            tipovi_kupaca_select_options: [],
            mjesto_select_options: [],
            spol_select_options: [
                {
                    key: '0',
                    value: 'Ž'
                },
                {
                    key: '1',
                    value: 'M'
                },
                {
                    key: '2',
                    value: 'Nedefiniran'
                }
            ],
            da_ne_select_options: [
                {
                    key: '0',
                    value: 'NE'
                },
                {
                    key: '1',
                    value: 'DA'
                }
            ]
        }
    },

    methods: {


        async fetchBrendovi(){
            let vm = this
            vm.user.user_brendovi.forEach( item => {
                vm.brendovi_select_options.push( {
                    key: item.id.toString(),
                    value: item.naziv
                } )
            } )
        },

        async fetchPoslovnice(){
            let vm = this
            vm.user.user_poslovnice.forEach( item => {
                vm.poslovnice_select_options.push( {
                    key: item.id.toString(),
                    value: item.naziv
                } )
            } )
        },

        fetchSavjetnice(){
            // todo:: razmisli da li je ova funkcija asinkrona ili nije
            let vm = this
            const response = axios.get(process.env.VUE_APP_API_URL + 'savjetnica' )
            return response.data.data.map( function( item ) {
                vm.osobe_select.push(
                    {
                        key: item.id.toString(),
                        value: item.ime + ' ' + item.prezime + ' (' + item.oib + ')'
                    }
                )
            } )
        },

        fetchMjesto(){
            // todo:: razmisli da li je ova funkcija asinkrona ili nije
            let vm = this
            const response = axios.get(process.env.VUE_APP_API_URL + 'mjesto' )
            return response.data.data.map( function( item ) {
                vm.osobe_select.push(
                    {
                        key: item.id.toString(),
                        value: item.ime + ' ' + item.prezime + ' (' + item.oib + ')'
                    }
                )
            } )
        },

        fetchTipoviKupaca(){
            // todo:: razmisli da li je ova funkcija asinkrona ili nije
        },

        getBrend( id ){
            return this.brendovi_select_options.find( item => item.key === id )
        },

        getPoslovnica( id ){
            return this.poslovnice_select_options.find( item => item.key === id )
        },

        getSavjetnica( id ){
            return this.savjetnice_select_options.find( item => item.key === id )
        },

        getMjesto( id ){
            return this.mjesto_select_options.find( item => item.key === id )
        },

        getSpol( id ){
            return this.spol_select_options.find( item => item.key === id )
        },

        getDaNe( id ){
            return this.da_ne_select_options.find( item => item.key === id )
        },


        async init(){

            let vm = this

            await vm.fetchBrendovi()
            await vm.fetchPoslovnice()


            if ( vm.action === 'edit' || vm.action === 'show' ){

                axios.get( process.env.VUE_APP_API_URL + vm.url + '/' + vm.id )
                        .then( function ( response ) {
                            vm.data = response.data
                            vm.$emit( 'returnData', response.data )
                            vm.setupForm()
                        } )
                        .catch( function ( error ) {
                            if(error instanceof Error) {
                                console.log( 'Error: ' . error);
                            } else {
                                console.log( 'Unexpected response: ' . error);
                            }
                        } )

            } else if ( vm.action === 'create' ) {
                vm.data = {}
                vm.setupForm()
            } else {
                return
            }
        },

        setupForm(){
            let vm = this;

            vm.buttons = []

            let button_cancel = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
                action: 'redirect', // request
                config: {
                    url: vm.route + ( vm.action === 'edit' ? ( '/' + vm.id) : '' )
                }
            }

            let button_delete = {
                id: 'delete',
                title: 'Obriši',
                type: 'danger',
                action: 'request',
                config: {
                    method: 'DELETE',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let button_edit = {
                id: 'edit',
                title: 'Uredi',
                type: 'success',
                action: 'redirect',
                config: {
                    url: vm.route +  '/' + vm.id +'/edit'
                }
            }

            let button_save = {
                id: 'save',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + vm.url
                }
            }

            let button_update = {
                id: 'update',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'PUT',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let buttons = []


            if( vm.action === 'edit' ){
                buttons.push( button_cancel)
                buttons.push( button_update)
            } else if( vm.action === 'show' ) {
                buttons.push( button_cancel )
                buttons.push( button_delete )
                buttons.push( button_edit )
            }
            else if ( vm.action === 'create' ) {
                buttons.push( button_cancel )
                buttons.push( button_save )
            } else {
                return
            }

            let inputs = [];


            let brend = {
                key: vm.input_data.brend
                        ? vm.input_data.brend.id
                        : (
                                vm.data.brend
                                        ? vm.data.brend.id
                                        : ''
                        ),
                value: vm.input_data.brend
                        ? vm.input_data.brend.naziv
                        : (
                                vm.data.brend
                                        ? vm.data.brend.naziv
                                        : ''
                        )
            }
            let brend_input = {
                name: 'brend_id',
                type: 'select',
                title: 'Brend',
                value: brend,
                width: 12,
                error: vm.errors['brend_id'] ? vm.errors['brend_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: function ( ) {
                        let model = this

                        if( vm.action === 'show'){
                            model.options = []
                            return
                        }

                        let filters = {}
                        let tmp_brendovi = []
                        vm.user.user_brendovi.forEach( function ( item ) {
                            tmp_brendovi.push( item.brend.id )
                        } )
                        if( tmp_brendovi.length )
                            filters['id'] = tmp_brendovi

                        axios.get( process.env.VUE_APP_API_URL +  'brend', {
                            params: {
                                page: 1,
                                per_page: -1,
                                filters: filters
                            }
                        } )
                                .then( function ( response ) {
                                    let tmp = [];
                                    response.data.data.forEach( function ( item ) {
                                        tmp.push({
                                            key: item.id,
                                            value: item.naziv
                                        } );
                                    } );
                                    model.options = tmp
                                } )
                                .catch( function ( error ) {
                                    if(error instanceof Error) {
                                        console.log( 'Error: ' . error);
                                    } else {
                                        console.log( 'Unexpected response: ' . error);
                                    }
                                } )
                    },
                    title_width: 1,
                    input_width: 2
                }
            }


            let ime = vm.input_data.ime
                    ? vm.input_data.ime
                    : (
                            vm.data.ime
                                    ? vm.data.ime
                                    : ''
                    )
            let ime_input = {
                name: 'ime',
                type: 'input',
                title: 'Ime',
                value:  ime,
                width: 3,
                error: vm.errors['ime'] ? vm.errors['ime'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }


            let prezime = vm.input_data.prezime
                    ? vm.input_data.prezime
                    : (
                            vm.data.prezime
                                    ? vm.data.prezime
                                    : ''
                    )
            let prezime_input = {
                name: 'prezime',
                type: 'input',
                title: 'Prezime',
                value: prezime,
                width: 3,
                error: vm.errors['prezime'] ? vm.errors['prezime'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }


            let datum_rodjenja = vm.input_data.datum_rodjenja
                    ? vm.input_data.datum_rodjenja
                    : (
                            vm.data.datum_rodjenja
                                    ? vm.data.datum_rodjenja
                                    : null
                    )
            let datum_rodjenja_input = {
                name: 'datum_rodjenja',
                type: 'datepicker',
                title: 'Datum rođenja',
                value: datum_rodjenja,
                width: 3,
                error: vm.errors['datum_rodjenja'] ? vm.errors['datum_rodjenja'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    mode: 'date',
                    title_width: 4,
                    input_width: 8
                }
            }



            let spol = {
                key: [0,1,2].includes( vm.input_data.spol ) || ['0','1','2'].includes( vm.input_data.spol )
                        ? vm.input_data.spol.toString()
                        : (
                                [0,1,2].includes( vm.data.spol ) || ['0','1','2'].includes( vm.data.spol )
                                        ? vm.data.spol.toString()
                                        : '2'
                        ),
                value: [0,1,2].includes( vm.input_data.spol ) || ['0','1','2'].includes( vm.input_data.spol )
                        ? vm.input_data.spol == '0'
                                ? 'Ž'
                                : (
                                        vm.input_data.spol == '1'
                                                ? 'M'
                                                : 'Nedefiniran'
                                )
                        : (
                                [0,1,2].includes( vm.data.spol ) || ['0','1','2'].includes( vm.data.spol )
                                        ? (
                                                vm.data.spol == '0'
                                                        ? 'Ž'
                                                        : (
                                                                vm.data.spol == '1'
                                                                        ? 'M'
                                                                        : 'Nedefiniran'
                                                        )
                                        ) : ''
                        )
            }


            let spol_input = {
                name: 'spol',
                type: 'select',
                title: 'Spol',
                value: spol,
                width: 3,
                error: vm.errors['spol'] ? vm.errors['spol'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.spol_select_options,
                    title_width: 4,
                    input_width: 8
                }
            }



            let datum_upisa = vm.input_data.datum_upisa
                    ? vm.input_data.datum_upisa
                    : (
                            vm.data.datum_upisa
                                    ? vm.data.datum_upisa
                                    : ''
                    )
            let datum_upisa_input = {
                name: 'datum_upisa',
                type: 'datepicker',
                title: 'Datum upisa',
                value: vm.action !== 'create' ? datum_upisa : '',
                width: 12,
                error: vm.errors['datum_upisa'] ? vm.errors['datum_upisa'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: true,
                config: {
                    mode: 'date',
                    title_width: 1,
                    input_width: 2
                }
            }



            let tvrtka = {
                key: vm.input_data.tip_kupca
                        ? vm.input_data.tip_kupca.id
                        : (
                                vm.data.tip_kupca
                                        ? vm.data.tip_kupca.id
                                        : ''
                        ),
                value: vm.input_data.tip_kupca
                        ? vm.input_data.tip_kupca.naziv
                        : (
                                vm.data.tip_kupca
                                        ? vm.data.tip_kupca.naziv
                                        : ''
                        )
            }
            let tvrtka_input = {
                name: 'tip_kupca_id',
                type: 'select',
                title: 'Tip kupca',
                value: tvrtka,
                width: 12,
                error: vm.errors['tip_kupca_id'] ? vm.errors['tip_kupca_id'] : '',
                note: '',
                explanation: 'Da li se kupac registrira kao tvrtka?',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: function ( ) {
                        let model = this

                        if( vm.action === 'show'){
                            model.options = []
                            return
                        }
                        axios.get( process.env.VUE_APP_API_URL +  'tip-kupca', {
                            params: {
                                page: 1,
                                per_page: -1,
                            }
                        } )
                                .then( function ( response ) {
                                    let tmp = [];
                                    response.data.data.forEach( function ( item ) {
                                        tmp.push({
                                            key: item.id,
                                            value: item.naziv
                                        } );
                                    } );
                                    model.options = tmp
                                } )
                                .catch( function ( error ) {
                                    if(error instanceof Error) {
                                        console.log( 'Error: ' . error);
                                    } else {
                                        console.log( 'Unexpected response: ' . error);
                                    }
                                } )
                    },
                    title_width: 1,
                    input_width: 2
                }
            }



            let email = vm.input_data.email
                    ? vm.input_data.email
                    : (
                            vm.data.email
                                    ? vm.data.email
                                    : ''
                    )
            let email_input = {
                name: 'email',
                type: 'input',
                title: 'Email',
                value:  email,
                width: 3,
                error: vm.errors['email'] ? vm.errors['email'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }



            let mobitel = vm.input_data.mobitel
                    ? vm.input_data.mobitel
                    : (
                            vm.data.mobitel
                                    ? vm.data.mobitel
                                    : ''
                    )
            let mobitel_input = {
                name: 'mobitel',
                type: 'input',
                title: 'Mobitel',
                value: mobitel,
                width: 3,
                error: vm.errors['mobitel'] ? vm.errors['mobitel'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }



            let adresa = vm.input_data.adresa
                    ? vm.input_data.adresa
                    : (
                            vm.data.adresa
                                    ? vm.data.adresa
                                    : ''
                    )
            let adresa_input = {
                name: 'adresa',
                type: 'input',
                title: 'Adresa',
                value: adresa,
                width: 3,
                error: vm.errors['adresa'] ? vm.errors['adresa'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }



            let posta = {
                key: vm.input_data.posta
                        ? vm.input_data.posta.id
                        : (
                                vm.data.posta
                                        ? vm.data.posta.id
                                        : ''
                        ),
                value: vm.input_data.posta
                        ? vm.input_data.posta.postanski_broj + ' ' + (vm.input_data.posta.naselje ? vm.input_data.posta.naselje : ' ') + ' | ' + vm.input_data.posta.mjesto
                        : (
                                vm.data.posta
                                        ? vm.data.posta.postanski_broj + ' ' + (vm.data.posta.naselje ? vm.data.posta.naselje : ' ') + ' | ' + vm.data.posta.mjesto
                                        : ''
                        )
            }

            let posta_id_input = {
                name: 'posta_id',
                type: 'select',
                title: 'Mjesto',
                value: posta,
                width: 3,
                error: vm.errors['posta_id'] ? vm.errors['posta_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: function ( ) {
                        let model = this

                        if( vm.action === 'show'){
                            model.options = []
                            return
                        }
                        axios.get( process.env.VUE_APP_API_URL +  'posta', {
                            params: {
                                page: 1,
                                per_page: -1,
                            }
                        } )
                                .then( function ( response ) {
                                    let tmp = [];
                                    response.data.data.forEach( function ( item ) {
                                        tmp.push({
                                            key: item.id,
                                            value: item.postanski_broj + ' '  + (item.naselje ? item.naselje : ' ')  + ' | ' + item.mjesto
                                        } );
                                    } );
                                    model.options = tmp
                                } )
                                .catch( function ( error ) {
                                    if(error instanceof Error) {
                                        console.log( 'Error: ' . error);
                                    } else {
                                        console.log( 'Unexpected response: ' . error);
                                    }
                                } )
                    },
                    title_width: 2,
                    input_width: 10
                }
            }



            let privola_email = {
                key: vm.input_data.privola_email
                        ? vm.input_data.privola_email.key === '1' ? '1' : '0'
                        : vm.data.privola_email ? '1' : '0',
                value: vm.input_data.privola_email
                        ? ( vm.input_data.privola_email.key === '1' ? 'DA' : 'NE' )
                        : (
                                vm.data.privola_email
                                        ? 'DA'
                                        : 'NE'
                        )
            }
            let privola_email_input = {
                name: 'privola_email',
                type: 'select',
                title: 'Privola e-mail',
                value: privola_email,
                width: 3,
                error: vm.errors['privola_email'] ? vm.errors['privola_email'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.da_ne_select_options,
                    title_width: 4,
                    input_width: 8
                }
            }



            let privola_mobitel = {
                key: vm.input_data.privola_mobitel
                        ? vm.input_data.privola_mobitel.key === '1' ? '1' : '0'
                        : vm.data.privola_mobitel ? '1' : '0',
                value: vm.input_data.privola_mobitel
                        ? ( vm.input_data.privola_mobitel.key === '1' ? 'DA' : 'NE' )
                        : (
                                vm.data.privola_mobitel
                                        ? 'DA'
                                        : 'NE'
                        )
            }
            let privola_mobitel_input = {
                name: 'privola_mobitel',
                type: 'select',
                title: 'Privola mobitel / SMS',
                value: privola_mobitel,
                width: 3,
                error: vm.errors['privola_mobitel'] ? vm.errors['privola_mobitel'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.da_ne_select_options,
                    title_width: 4,
                    input_width: 8
                }
            }



            let privola_posta = {
                key: vm.input_data.privola_posta
                        ? vm.input_data.privola_posta.key === '1' ? '1' : '0'
                        : vm.data.privola_posta ? '1' : '0',
                value: vm.input_data.privola_posta
                        ? ( vm.input_data.privola_posta.key === '1' ? 'DA' : 'NE' )
                        : (
                                vm.data.privola_posta
                                        ? 'DA'
                                        : 'NE'
                        )
            }
            let privola_posta_input = {
                name: 'privola_posta',
                type: 'select',
                title: 'Privola pošta',
                value: privola_posta,
                width: 3,
                error: vm.errors['privola_posta'] ? vm.errors['privola_posta'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.da_ne_select_options,
                    title_width: 4,
                    input_width: 8
                }
            }



            let napomena = vm.input_data.napomena
                    ? vm.input_data.napomena
                    : (
                            vm.data.napomena
                                    ? vm.data.napomena
                                    : ''
                    )
            let napomena_input = {
                name: 'napomena',
                type: 'editor',
                title: 'Napomena 1',
                value: napomena,
                width: 6,
                error: vm.errors['napomena'] ? vm.errors['napomena'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 2,
                    input_width: 10
                }
            }


            let napomena2 = vm.input_data.napomena2
                    ? vm.input_data.napomena2
                    : (
                            vm.data.napomena2
                                    ? vm.data.napomena2
                                    : ''
                    )
            let napomena2_input = {
                name: 'napomena2',
                type: 'editor',
                title: 'Napomena 2',
                value: napomena2,
                width: 6,
                error: vm.errors['napomena2'] ? vm.errors['napomena2'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 2,
                    input_width: 10
                }
            }



            let savjetnica = {
                key: vm.input_data.savjetnica
                        ? vm.input_data.savjetnica.id
                        : (
                                vm.data.savjetnica
                                        ? vm.data.savjetnica.id
                                        : ''
                        ),
                value: vm.input_data.savjetnica
                        ? ( vm.input_data.savjetnica.ime + ' ' + vm.input_data.savjetnica.prezime )
                        : (
                                vm.data.savjetnica
                                        ? ( vm.data.savjetnica.ime + ' ' + vm.data.savjetnica.prezime )
                                        : ''
                        )
            }
            let savjetnica_input = {
                name: 'savjetnica_id',
                type: 'select',
                title: 'Savjetnica',
                value: savjetnica,
                width: 12,
                error: vm.errors['savjetnica'] ? vm.errors['savjetnica'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: function ( ) {
                        let model = this

                        if( vm.action === 'show'){
                            model.options = []
                            return
                        }

                        let filters = {
                            'aktivna': '1',
                            'aktivna_poslovnica': '1'
                        }
                        let tmp_brendovi = []
                        vm.user.user_brendovi.forEach( function ( item ) {
                            tmp_brendovi.push( item.brend.id )
                        } )
                        console.log( vm.user.user_brendovi )
                        if( tmp_brendovi.length )
                            filters['brend_id'] = tmp_brendovi


                        axios.get( process.env.VUE_APP_API_URL +  'savjetnica', {
                            params: {
                                page: 1,
                                per_page: -1,
                                filters:filters
                            }
                        } )
                                .then( function ( response ) {
                                    let tmp = [];
                                    response.data.data.forEach( function ( item ) {
                                        tmp.push({
                                            key: item.id,
                                            value: item.ime + ' ' + item.prezime
                                        } );
                                    } );
                                    model.options = tmp
                                } )
                                .catch( function ( error ) {
                                    if(error instanceof Error) {
                                        console.log( 'Error: ' . error);
                                    } else {
                                        console.log( 'Unexpected response: ' . error);
                                    }
                                } )
                    },
                    title_width: 1,
                    input_width: 3
                }
            }



            let poslovnica = {
                key: vm.input_data.poslovnica
                        ? vm.input_data.poslovnica.id
                        : (
                                vm.data.poslovnica
                                        ? vm.data.poslovnica.id
                                        : ''
                        ),
                value: vm.input_data.poslovnica
                        ? vm.input_data.poslovnica.naziv
                        : (
                                vm.data.poslovnica
                                        ? vm.data.poslovnica.naziv
                                        : ''
                        )
            }
            let poslovnica_input = {
                name: 'poslovnica_id',
                type: 'select',
                title: 'Poslovnica',
                value: poslovnica,
                width: 12,
                error: vm.errors['poslovnica'] ? vm.errors['poslovnica'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: function ( ) {
                        let model = this

                        if( vm.action === 'show'){
                            model.options = []
                            return
                        }

                        let filters = {
                            'aktivna': '1',
                        }
                        let tmp_brendovi = []
                        vm.user.user_brendovi.forEach( function ( item ) {
                            tmp_brendovi.push( item.brend.id )
                        } )
                        if( tmp_brendovi.length )
                            filters['brend_id'] = tmp_brendovi

                        axios.get( process.env.VUE_APP_API_URL +  'poslovnica', {
                            params: {
                                page: 1,
                                per_page: -1,
                                filters: filters
                            }
                        } )
                                .then( function ( response ) {
                                    let tmp = [];
                                    response.data.data.forEach( function ( item ) {
                                        tmp.push({
                                            key: item.id,
                                            value: item.naziv
                                        } );
                                    } );
                                    model.options = tmp
                                } )
                                .catch( function ( error ) {
                                    if(error instanceof Error) {
                                        console.log( 'Error: ' . error);
                                    } else {
                                        console.log( 'Unexpected response: ' . error);
                                    }
                                } )
                    },
                    title_width: 1,
                    input_width: 3
                }
            }



            inputs.push( brend_input )
            inputs.push( poslovnica_input )
            inputs.push( savjetnica_input )
            inputs.push( ime_input )
            inputs.push( prezime_input )
            inputs.push( datum_rodjenja_input )
            inputs.push( spol_input )
            inputs.push( tvrtka_input )
            inputs.push( email_input )
            inputs.push( mobitel_input )
            inputs.push( adresa_input )
            inputs.push( posta_id_input )
            inputs.push( privola_email_input )
            inputs.push( privola_mobitel_input )
            inputs.push( privola_posta_input )
            inputs.push( datum_upisa_input )
            inputs.push( napomena_input )
            inputs.push( napomena2_input )


            let settings = {
                width: 12,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: inputs,
                            }
                        ],
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },

        /**
         *  on input return data
         */
        onInput( name, value ){
            //let vm = this
            let vm = this;
            if( name === 'brend_id') {
                if( value )
                    axios.get( process.env.VUE_APP_API_URL +  'brend/' + value )
                            .then( function ( response ) {
                                vm.input_data['brend'] = response.data
                            } )
                            .catch( function ( error ) {
                                if(error instanceof Error) {
                                    console.log( 'Error: ' . error);
                                } else {
                                    console.log( 'Unexpected response: ' . error);
                                }
                            } )
                else vm.input_data['brend'] = null
            } else if ( name === 'poslovnica_id' ){
                if ( value )
                    axios.get( process.env.VUE_APP_API_URL +  'poslovnica/' + value )
                            .then( function ( response ) {
                                vm.input_data['poslovnica'] = response.data
                            } )
                            .catch( function ( error ) {
                                if(error instanceof Error) {
                                    console.log( 'Error: ' . error);
                                } else {
                                    console.log( 'Unexpected response: ' . error);
                                }
                            } )
                else
                    vm.input_data['poslovnica'] = null
            } else if( name === 'savjetnica_id' ){
                if( value )
                axios.get( process.env.VUE_APP_API_URL +  'savjetnica/' + value )
                        .then( function ( response ) {
                            vm.input_data['savjetnica'] = response.data
                        } )
                        .catch( function ( error ) {
                            if(error instanceof Error) {
                                console.log( 'Error: ' . error);
                            } else {
                                console.log( 'Unexpected response: ' . error);
                            }
                        } )
                else
                    vm.input_data['savjetnica'] = null
            }  else if ( name === 'tip_kupca_id' ){
                if( value )
                    axios.get( process.env.VUE_APP_API_URL +  'tip-kupca/' + value )
                            .then( function ( response ) {
                                vm.input_data['tip_kupca'] = response.data
                            } )
                            .catch( function ( error ) {
                                if(error instanceof Error) {
                                    console.log( 'Error: ' . error);
                                } else {
                                    console.log( 'Unexpected response: ' . error);
                                }
                            } )
                else
                    vm.input_data['tip_kupca'] = null
            } else if ( name === 'posta_id' ){
                if( value )
                    axios.get( process.env.VUE_APP_API_URL +  'posta/' + value )
                            .then( function ( response ) {
                                vm.input_data['posta'] = response.data
                            } )
                            .catch( function ( error ) {
                                if(error instanceof Error) {
                                    console.log( 'Error: ' . error);
                                } else {
                                    console.log( 'Unexpected response: ' . error);
                                }
                            } )
                else
                    vm.input_data['posta'] = null
            } else if ( name === 'spol' ){
                if( value ){
                    vm.input_data[name] = value
                } else {
                    vm.input_data[name] = '2'
                }
            } else if ( name === 'privola_email' ){
                if ( value === '1' )
                    vm.input_data[name] = {
                        key: '1',
                        value: 'DA'
                    }
                else {
                    vm.input_data[name] = {
                        key: '0',
                        value: 'NE'
                    }
                }

            } else if ( name === 'privola_mobitel' ){
                if ( value === '1' )
                    vm.input_data[name] = {
                        key: '1',
                        value: 'DA'
                    }
                else {
                    vm.input_data[name] = {
                        key: '0',
                        value: 'NE'
                    }
                }
            } else if ( name === 'privola_posta' ){
                if ( value === '1' )
                    vm.input_data[name] = {
                        key: '1',
                        value: 'DA'
                    }
                else {
                    vm.input_data[name] = {
                        key: '0',
                        value: 'NE'
                    }
                }
            }  else {
                vm.input_data[name] = value


                if( name === 'email' ){
                    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if( vm.input_data.brend && vm.input_data.brend.id
                            && vm.input_data.email && vm.input_data.email !== ''
                            && re.test( value )
                    )
                        axios.get( process.env.VUE_APP_API_URL +  'kupac', {
                            params: {
                                filters: {
                                    email: this.input_data.email,
                                    brend_id: vm.input_data.brend ? vm.input_data.brend.id : ''
                                }
                            }
                        } )
                                .then( function ( response ) {

                                    let kupac_exist = false;

                                    response.data.data.forEach( function ( kupac ) {

                                        if( kupac.id != vm.id && kupac.email === vm.input_data.email)
                                            kupac_exist = true;

                                    } )

                                    if( kupac_exist )
                                        toastr.error( 'Korisnik sa unesenom E-mail adresom već postoji')

                                    // if ( vm.id ){
                                    //     if( response.data.results === 1 ){
                                    //
                                    //         if( response.data.data[0].id === vm.id ){
                                    //             // ništa
                                    //         } else {
                                    //             toastr.error( 'Korisnik sa unesenom E-mail adresom već postoji')
                                    //         }
                                    //     } else if( response.data.results > 1 ) {
                                    //         toastr.error( 'Korisnik sa unesenom E-mail adresom već postoji')
                                    //     }
                                    // } else {
                                    //     if( response.data.results ){
                                    //         toastr.error( 'Korisnik sa unesenom E-mail adresom već postoji')
                                    //     }
                                    // }

                                } )
                                .catch( function ( error ) {
                                    if(error instanceof Error) {
                                        console.log( 'Error: ' . error);
                                    } else {
                                        console.log( 'Unexpected response: ' . error);
                                    }
                                } )
                } else if( name === 'datum_rodjenja'  || name === 'ime' || name === 'prezime' ){
                    if( vm.input_data.brend && vm.input_data.brend.id
                        && vm.input_data.ime && vm.input_data.ime !== ''
                        && vm.input_data.prezime && vm.input_data.prezime !== ''
                    )
                        axios.get( process.env.VUE_APP_API_URL +  'kupac', {
                            params: {
                                filters: {
                                    datum_rodjenja_exact: vm.input_data.datum_rodjenja,
                                    ime: vm.input_data.ime,
                                    prezime: vm.input_data.prezime,
                                    brend_id: vm.input_data.brend ? vm.input_data.brend.id : ''
                                }
                            }
                        } )
                                .then( function ( response ) {

                                    if ( vm.id ){
                                        if( response.data.results === 1 ){
                                            if( response.data.data[0].id == vm.id ){
                                                // ništa
                                            } else {
                                                toastr.error( 'Korisnik sa unesenom kombinacijom imena prezimena i datuma rođenaj već postoji')
                                            }
                                        } else if( response.data.results > 1 ) {
                                            toastr.error( 'Korisnik sa unesenom kombinacijom imena prezimena i datuma rođenaj već postoji')
                                        }
                                    } else {
                                        if( response.data.results ){
                                            toastr.error( 'Korisnik sa unesenom kombinacijom imena prezimena i datuma rođenaj već postoji')
                                        }
                                    }

                                } )
                                .catch( function ( error ) {
                                    if(error instanceof Error) {
                                        console.log( 'Error: ' . error);
                                    } else {
                                        console.log( 'Unexpected response: ' . error);
                                    }
                                } )
                }

            }
        },

        /**
         *  on button click
         */
        onFormButtonClick( id, action, config, data  ){
            if( id === 'save' || id === 'update' ){
                if( data.success){
                    if( id == 'save' )
                        toastr.success( 'Uspješno spremljeno!' )
                    else if ( id == 'update' )
                        toastr.success( 'Uspješno ažurirano!' )
                    this.$router.push({ path: this.route + '/' + data.data.id } )
                } else {
                    this.errors = data.messages
                    this.init()
                }
            } else if( id == 'delete' ){
                toastr.error( 'Uspješno obrisano!' )
                this.$router.push({ path: this.route } )
            }
        },


    },
    mounted() {
        this.init()
    }

};
</script>

<style scoped>

</style>
