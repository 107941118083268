<template>
    <div>
        <breadcrumbs
                :title="'Kupci'"
                :breadcrumbs="breadcrumbs"
                :user="user"
        >
        </breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Novi kupac</h5><br>
            </div>
            <div class="ibox-content">
                <kupac-forma
                        :disabled="false"
                        :id="null"
                        :modal="false"
                        :action="'create'"
                        :user="user"
                >
                </kupac-forma>
            </div>
        </div>
    </div>
</template>

<script>
import KupacForma from "./KupacForma";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
export default {
  name: "KupacCreate",
    components: {Breadcrumbs, KupacForma},
    props: ['user'],
    data(){
        return {
            breadcrumbs: []
        }
    },
    methods: {
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/kupci',
                    name: 'Kupci'
                },
                {
                    path: '/kupci/create',
                    name: 'Novi kupac'
                }
            ]
        }
    },
    mounted() {
        this.setBreadcrumbs()
    }
};
</script>

<style scoped>

</style>