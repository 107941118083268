<template>
    <div>
        <div class="vld-parent">
            <loading :active.sync="open_loader"
                     :can-cancel="false"
                     :is-full-page="true">
            </loading>
        </div>

        <breadcrumbs
                :title="'Ispisi'"
        ></breadcrumbs>

        <div class="ibox">
            <div class="ibox-title">
                <h5>Ispisi</h5>
                <forms
                        :settings="form_settings"
                        @onInput="onIspisSelect"
                >
                </forms>
            </div>
            <div class="ibox-content">
                <datatables
                        v-if="show_datatable"
                        :url="datatables_url"
                        :settings="datatables.settings"
                        :title="datatables.title"
                >
                </datatables>
            </div>
        </div>

    </div>
</template>

<script>
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import Forms from "../../../components/form/Forms";
import Datatables from "../../../components/datatable/Datatables";
import axios from "axios";

export default {
    name: "Ispisi",
    components: {Breadcrumbs, Loading, Datatables, Forms },
    data(){
        return {
            open_loader: false,

            form_settings: {},

            datatables: {},
            datatables_url: '',
            show_datatable: false
        }
    },
    methods: {
        setup_form(){

            let vm = this

            let settings = {
                width: 12,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: [
                                    {
                                        name: 'ispis',
                                        type: 'select',
                                        title: 'Ispis',
                                        value: {
                                            key: '',
                                            value: ''
                                        },
                                        error: '',
                                        width: 6,
                                        config: {
                                            options: [
                                                {
                                                    key: "1",
                                                    value: "Knjižica 15 godina"
                                                },
                                                {
                                                    key: "2",
                                                    value: "Kupci"
                                                },
                                            ],
                                            multiple: false,
                                            title_width: 2,
                                            input_width: 10
                                        }
                                    }

                                ],
                            }
                        ],
                    },
                ],
                buttons: []
            }

            vm.form_settings = settings
        },

        onIspisSelect( name, value ){
            if( value === "1" ){
                this.ispis1()
            } else if( value === "2" ){
                this.ispis2()
            }
        },

        ispis1(){
            let vm = this;

            vm.datatables = {}
            vm.datatables_url = process.env.VUE_APP_API_URL + 'knjizica-15'
            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'ime',
                            'title': 'Ime',
                            'type': function ( row ) {
                                return row.kupac ? row.kupac.ime : ''
                            },
                            'column_visibility_group': {
                                id: 'ispis',
                            },
                            'searchable': false,
                        },
                        {
                            'id': 'prezime',
                            'title': 'Prezime',
                            'type': function ( row ){
                                return row.kupac ? row.kupac.prezime : ''
                            },
                            'column_visibility_group': {
                                id: 'ispis',
                            },
                            'searchable': false,
                        },
                        {
                            'id': 'datum_rodjenja',
                            'title': 'Datum rođenja',
                            'type': function ( row ){
                                return row.kupac ? row.kupac.datum_rodjenja : ''
                            },
                            'column_visibility_group': {
                                id: 'ispis',
                            },
                            'searchable': false,
                        },
                        {
                            'id': 'email',
                            'title': 'E-mail',
                            'type': function ( row ){
                                return row.kupac ? row.kupac.email : ''
                            },
                            'column_visibility_group': {
                                id: 'ispis',
                            },
                            'searchable': false,
                        },
                        {
                            'id': 'datum',
                            'title': 'Datum izdavanja',
                            'filter': {
                                'type' : 'date'
                            },
                            'column_visibility_group': {
                                id: 'ispis',
                            }
                        },
                        {
                            'id': 'poslovnica_id',
                            'title': 'Poslovnica',
                            'type' : function( row ){
                                return row.poslovnica ? row.poslovnica.naziv : ''
                            },
                            'filter': {
                                'type': 'select',
                                'search': function ( ){
                                    let component = this;
                                    axios.get( process.env.VUE_APP_API_URL +  'poslovnica')
                                            .then( function ( response ) {
                                                let tmp = [];
                                                response.data.data.forEach( function ( item ) {
                                                    tmp.push({
                                                        key: item.id,
                                                        value: item.naziv
                                                    } );
                                                } );
                                                component.$data.tmp = tmp;
                                            } )
                                            .catch( function ( error ) {
                                                if(error instanceof Error) {
                                                    console.log( 'Error: ' . error);
                                                } else {
                                                    console.log( 'Unexpected response: ' . error);
                                                }
                                            } )
                                },
                                'value': null
                            },
                            'column_visibility_group': {
                                id: 'ispis',
                            }
                        },

                    ],
                    column_visibility_groups:{
                        'ispis' : 'Knjižica'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Knjižica 15',
            }
            vm.show_datatable = true;
        },

        ispis2(){
            let vm = this;

            vm.datatables = {}
            vm.datatables_url = process.env.VUE_APP_API_URL + 'kupac'
            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'datum_upisa',
                            'title': 'Datum upisa',
                            'column_visibility_group': {
                                id: 'kupci',
                            },
                            'filter': {
                                'type': 'date',
                            }
                        },
                        {
                            'id': 'ime',
                            'title': 'Ime',
                            'column_visibility_group': {
                                id: 'kupci',
                            }
                        },
                        {
                            'id': 'prezime',
                            'title': 'Prezime',
                            'column_visibility_group': {
                                id: 'kupci',
                            }
                        },
                        {
                            'id': 'datum_rodjenja',
                            'title': 'Datum rođenja',
                            'column_visibility_group': {
                                id: 'kupci',
                            },
                            'filter': {
                                'type': 'date',
                            }
                        },
                        {
                            'id': 'spol',
                            'title': 'Spol',
                            'type': function( row ){
                                return row && [0,1,2].includes( row.spol )
                                        ? (
                                                row.spol === 0
                                                        ? 'Ž'
                                                        : (
                                                                row.spol === 1
                                                                        ? 'M'
                                                                        : 'Nedefinirano'
                                                        )
                                        )
                                        : ''
                            },
                            'filter': {
                                'type': 'select',
                                'search': [
                                    {
                                        key: 0,
                                        value: 'Ž'
                                    },
                                    {
                                        key: 1,
                                        value: 'M'
                                    },
                                    {
                                        key: 2,
                                        value: 'Nedefinirano'
                                    },

                                ],
                                'value': null
                            },
                            'column_visibility_group': {
                                id: 'kupci',
                            },
                        },
                        {
                            'id': 'email',
                            'title': 'Email',
                            'column_visibility_group': {
                                id: 'kupci',
                            },
                        },
                        {
                            'id': 'mobitel',
                            'title': 'Mobitel',
                            'column_visibility_group': {
                                id: 'kupci',
                            },
                        },
                        {
                            'id': 'brend_id',
                            'title': 'Brend',
                            'type': function( row ){
                                return row.brend ? row.brend.naziv : ''
                            },
                            'filter': {
                                'type': 'select',
                                'search': function ( ){
                                    let component = this;

                                    axios.get( process.env.VUE_APP_API_URL +  'brend')
                                            .then( function ( response ) {
                                                let tmp = [];
                                                response.data.data.forEach( function ( item ) {
                                                    tmp.push({
                                                        key: item.id,
                                                        value: item.naziv
                                                    } );
                                                } );
                                                component.$data.tmp = tmp;
                                            } )
                                            .catch( function ( error ) {
                                                if(error instanceof Error) {
                                                    console.log( 'Error: ' . error);
                                                } else {
                                                    console.log( 'Unexpected response: ' . error);
                                                }
                                            } )
                                },
                            },
                            'column_visibility_group': {
                                id: 'kupci',
                            },
                        },
                        {
                            'id': 'adresa',
                            'title': 'Adresa',
                            'column_visibility_group': {
                                id: 'kupci',
                            },
                        },
                        {
                            'id': 'posta_id',
                            'title': 'Naselje',
                            'type' : function( row ) {
                                return row.posta ? row.posta.naselje : ''
                            },
                            'filter': {
                                'type': 'multiselect',
                                'search': function ( ){
                                    let component = this;

                                    axios.get( process.env.VUE_APP_API_URL +  'posta')
                                            .then( function ( response ) {
                                                let tmp = [];
                                                response.data.data.forEach( function ( item ) {
                                                    tmp.push({
                                                        key: item.id,
                                                        value: item.naselje
                                                    } );
                                                } );
                                                component.$data.tmp = tmp;
                                            } )
                                            .catch( function ( error ) {
                                                if(error instanceof Error) {
                                                    console.log( 'Error: ' . error);
                                                } else {
                                                    console.log( 'Unexpected response: ' . error);
                                                }
                                            } )
                                },
                            },
                            'column_visibility_group': {
                                id: 'kupci',
                            },
                        },
                        {
                            'id': 'posta_id',
                            'title': 'Mjesto',
                            'type' : function( row ) {
                                return row.posta ? row.posta.mjesto : ''
                            },
                            'filter': {
                                'type': 'multiselect',
                                'search': function ( ){
                                    let component = this;

                                    axios.get( process.env.VUE_APP_API_URL +  'posta')
                                            .then( function ( response ) {
                                                let tmp = [];
                                                response.data.data.forEach( function ( item ) {
                                                    tmp.push({
                                                        key: item.id,
                                                        value: item.mjesto
                                                    } );
                                                } );
                                                component.$data.tmp = tmp;
                                            } )
                                            .catch( function ( error ) {
                                                if(error instanceof Error) {
                                                    console.log( 'Error: ' . error);
                                                } else {
                                                    console.log( 'Unexpected response: ' . error);
                                                }
                                            } )
                                },
                            },
                            'column_visibility_group': {
                                id: 'kupci',
                            },
                        },
                        {
                            'id': 'postanski_broj',
                            'title': 'Postanski broj',
                            'type' : function( row ) {
                                return row.posta ? row.posta.postanski_broj : ''
                            },
                            'column_visibility_group': {
                                id: 'kupci',
                            },
                        },
                        {
                            'id': 'zupanija_id',
                            'title': 'Županija',
                            'type': function( row ){
                                return row.posta ? ( row.posta.zupanija ? row.posta.zupanija.naziv : '' ) : ''
                            },
                            'filter': {
                                'type': 'multiselect',
                                'search': function ( ){
                                    let component = this;

                                    axios.get( process.env.VUE_APP_API_URL +  'zupanija')
                                            .then( function ( response ) {
                                                let tmp = [];
                                                response.data.data.forEach( function ( item ) {
                                                    tmp.push({
                                                        key: item.id,
                                                        value: item.naziv
                                                    } );
                                                } );
                                                component.$data.tmp = tmp;
                                            } )
                                            .catch( function ( error ) {
                                                if(error instanceof Error) {
                                                    console.log( 'Error: ' . error);
                                                } else {
                                                    console.log( 'Unexpected response: ' . error);
                                                }
                                            } )
                                },
                            },
                            'column_visibility_group': {
                                id: 'kupci',
                            },
                        },
                        // {
                        //     'id': 'posta.zupanija.drzava.naziv',
                        //     'title': '',
                        //     'column_visibility_group': {
                        //         id: 'kupci',
                        //     },
                        // },
                        {
                            'id': 'privola_email',
                            'title': 'Privola e-mail',
                            'type': function( row ){
                                return row.privola_email ? 'Da' : 'Ne'
                            },
                            'filter': {
                                'type': 'select',
                                'search': [
                                    {
                                        key: '1',
                                        value: 'Da'
                                    },
                                    {
                                        key: '0',
                                        value: 'Ne'
                                    }
                                ],
                                'value': null
                            },
                            'column_visibility_group': {
                                id: 'kupci',
                            },
                        },
                        {
                            'id': 'privola_mobitel',
                            'title': 'Privola mobitel',
                            'type': function( row ){
                                return row.privola_mobitel ? 'Da' : 'Ne'
                            },
                            'filter': {
                                'type': 'select',
                                'search': [
                                    {
                                        key: '1',
                                        value: 'Da'
                                    },
                                    {
                                        key: '0',
                                        value: 'Ne'
                                    }
                                ],
                                'value': null
                            },
                            'column_visibility_group': {
                                id: 'kupci',
                            },
                        },
                        {
                            'id': 'privola_posta',
                            'title': 'Privola pošta',
                            'type': function( row ){
                                return row.privola_posta ? 'Da' : 'Ne'
                            },
                            'filter': {
                                'type': 'select',
                                'search': [
                                    {
                                        key: '1',
                                        value: 'Da'
                                    },
                                    {
                                        key: '0',
                                        value: 'Ne'
                                    }
                                ],
                                'value': null
                            },
                            'column_visibility_group': {
                                id: 'kupci',
                            },
                        },
                        {
                            'id': 'poslovnica_id',
                            'title': 'Poslovnica',
                            'type': function( row ){
                                return row.poslovnica ? row.poslovnica.naziv : ''
                            },
                            'filter': {
                                'type': 'multiselect',
                                'search': function ( ){
                                    let component = this;

                                    axios.get( process.env.VUE_APP_API_URL +  'poslovnica')
                                            .then( function ( response ) {
                                                let tmp = [];
                                                response.data.data.forEach( function ( item ) {
                                                    tmp.push({
                                                        key: item.id,
                                                        value: item.naziv
                                                    } );
                                                } );
                                                component.$data.tmp = tmp;
                                            } )
                                            .catch( function ( error ) {
                                                if(error instanceof Error) {
                                                    console.log( 'Error: ' . error);
                                                } else {
                                                    console.log( 'Unexpected response: ' . error);
                                                }
                                            } )
                                },
                                'value': null
                            },
                            'column_visibility_group': {
                                id: 'kupci',
                            },
                        },
                        {
                            'id': 'savjetnica_id',
                            'title': 'Savjetnica',
                            'type': function( row ){
                                return row.savjetnica ? ( row.savjetnica.ime + ' ' + row.savjetnica.prezime ) : ''
                            },
                            'filter': {
                                'type': 'select',
                                'search': function ( ){
                                    let component = this;

                                    axios.get( process.env.VUE_APP_API_URL +  'savjetnica')
                                            .then( function ( response ) {
                                                let tmp = [];
                                                response.data.data.forEach( function ( item ) {
                                                    tmp.push({
                                                        key: item.id,
                                                        value: item.ime + ' ' + item.prezime
                                                    } );
                                                } );
                                                component.$data.tmp = tmp;
                                            } )
                                            .catch( function ( error ) {
                                                if(error instanceof Error) {
                                                    console.log( 'Error: ' . error);
                                                } else {
                                                    console.log( 'Unexpected response: ' . error);
                                                }
                                            } )
                                },
                                'value': null
                            },
                            'column_visibility_group': {
                                id: 'kupci',
                            },
                        },
                    ],
                    column_visibility_groups:{
                        'kupci' : 'Kupci'
                    },
                    order: 'desc',
                    order_by: 'id',

                },
                title: 'Kupci',
            }
            vm.show_datatable = true;
        }
    },
    mounted() {
        this.setup_form()
    }
}
</script>

<style scoped>

</style>