<template>
    <div>
        <breadcrumbs
                :title="'Kategorije kupaca'"
                :breadcrumbs="breadcrumbs"
        >
        </breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Nova kategorija kupaca</h5><br>
            </div>
            <div class="ibox-content">
                <kategorija2-forma
                        :disabled="false"
                        :id="null"
                        :modal = false
                        :action="'create'"
                        :user="user"
                >
                </kategorija2-forma>
            </div>
        </div>
    </div>

</template>

<script>
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import Kategorija2Forma from "./Kategorija2Forma";
export default {
    name: "Kategorij2Create",
    components: {Kategorija2Forma, Breadcrumbs},
    props: ['user'],
    data(){
        return {
            breadcrumbs: []
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/kategorije-kupaca2',
                    name: 'Kategorije'
                },
                {
                    path: '/kategorije-kupaca2/create',
                    name: 'Nova kategorija'
                }
            ]
        }
    },
    mounted() {
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>