<template>
    <div>
        <kupci-navigation
                :id="kupac_id"
                :breadcrumbs="breadcrumbs"
                :user="user"
        >
        </kupci-navigation>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Nova kategorija</h5>
            </div>
            <div class="ibox-content">
                <kupac-kategorija2-forma
                        :disabled="false"
                        :id="null"
                        :modal="false"
                        :action="'create'"
                        :user="user"
                >
                </kupac-kategorija2-forma>
            </div>
        </div>
    </div>
</template>

<script>
import KupciNavigation from "../KupciNavigation";
import KupacKategorija2Forma from "./KupacKategorija2Forma";
export default {
    name: "KupacKategorijaCreate",
    components: {KupacKategorija2Forma, KupciNavigation},
    props:['user'],
    data(){
        return {
            breadcrumbs: [],
            kupac_id: this.$route.params.kupac_id,
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/kupci/' + this.kupac_id + '/kategorije',
                    name: 'Kategorije'
                },
                {
                    path: '/kupci/' + this.kupac_id + '/kategorije/create',
                    name: 'Nova kategorija'
                }
            ]
        }
    }
}
</script>

<style scoped>

</style>