<template>
    <div>
        <div class="vld-parent">
            <loading :active.sync="open_loader"
                     :can-cancel="false"
                     :is-full-page="true">
            </loading>
        </div>

        <breadcrumbs
            :title="'Izvještaji'"
        ></breadcrumbs>

        <div class="ibox">
            <div class="ibox-title">
                <h5>Izvještaj</h5>
            </div>
            <div class="ibox-content">
                <forms
                    :settings="form_settings"
                    @onInput="onInput"
                    @onFormButtonClick="onFormButtonClick"
                >
                </forms>
            </div>
        </div>

    </div>
</template>

<script>

import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import Forms from "../../../components/form/Forms";
import axios from "axios";
import toastr from "toastr";

export default {
    name: "IzvjestajiBase",
    components: {
        Breadcrumbs,
        Loading,
        Forms
    },
    data(){
        return {
            open_loader: false,
            form_settings: {},

            // inputs
            izvjestaj_select: [
                {
                    key: 1,
                    value: "Pogodnost - broj kupaca i iznosi računa po trgovinama"
                },
                {
                    key: 2,
                    value: "Broj upisanih kupaca po trgovini za neki period"
                },
                {
                    key: 3,
                    value: "Broj brisanih privola kupaca po trgovini"
                },
            ],
            brend_select: [],
            pogodnost_select: [],
            datum_od: '',
            datum_do: '',

            izvjestaj: {key: '', value:''},
            brend: {key: '', value:''},
            pogodnost: {key: '', value:''},
        }
    },
    methods: {
        setup_form(){
            let vm = this

            let buttons = []

            if( vm.izvjestaj.key ){
                buttons.push({
                    id: 'download',
                    title: 'Preuzmi',
                    type: 'primary',
                    action: 'download',
                })
            }

            let inputs = []

            let izvjestaj_select = {
                name: 'izvjestaj_id',
                type: 'select',
                title: 'Izvještaj',
                value: vm.izvjestaj,
                error: '',
                width: 12,
                config: {
                    options: vm.izvjestaj_select,
                    multiple: false,
                    title_width: 2,
                    input_width: 10
                }
            }
            inputs.push( izvjestaj_select )


            // todo:: ovdje se dodaju id-jevi izvještaja
            if( [1,2,3].includes( vm.izvjestaj.key ) ){
                let brend_select = {
                    name: 'brend_id',
                    type: 'select',
                    title: 'Brend',
                    value: vm.brend,
                    error: '',
                    width: 12,
                    config: {
                        options: function ( ) {
                            let model = this

                            axios.get( process.env.VUE_APP_API_URL +  'brend' )
                                .then( function ( response ) {
                                    let tmp = [];
                                    response.data.data.forEach( function ( item ) {
                                        tmp.push({
                                            key: item.id,
                                            value: item.naziv
                                        } );
                                        vm.brend_select.push({
                                            key: item.id,
                                            value: item.naziv
                                        })
                                    } );
                                    model.options = tmp
                                } )
                                .catch( function ( error ) {
                                    if(error instanceof Error) {
                                        console.log( 'Error: ' . error);
                                    } else {
                                        console.log( 'Unexpected response: ' . error);
                                    }
                                } )
                        },
                        multiple: false,
                        title_width: 2,
                        input_width: 10
                    }
                }

                inputs.push( brend_select )
            }




            // select pogodnosti
            if( [1].includes( vm.izvjestaj.key ) && vm.brend.key ){
                let pogodnost_select = {
                    name: 'pogodnost_id',
                    type: 'select',
                    title: 'Pogodnost',
                    value: vm.pogodnost,
                    error: '',
                    width: 12,
                    config: {
                        options: function ( ) {
                            let model = this

                            axios.get( process.env.VUE_APP_API_URL +  'pogodnost', {
                                params: {
                                    filters: {
                                        brend_id: vm.brend.key
                                    }
                                }
                            } )
                                .then( function ( response ) {
                                    let tmp = [];
                                    response.data.data.forEach( function ( item ) {
                                        tmp.push({
                                            key: item.id,
                                            value: item.naziv
                                        } );
                                        vm.pogodnost_select.push( {
                                            key:item.id,
                                            value: item.naziv
                                        } )
                                    } );
                                    model.options = tmp
                                } )
                                .catch( function ( error ) {
                                    if(error instanceof Error) {
                                        console.log( 'Error: ' . error);
                                    } else {
                                        console.log( 'Unexpected response: ' . error);
                                    }
                                } )
                        },
                        multiple: false,
                        title_width: 2,
                        input_width: 10
                    }
                }

                inputs.push( pogodnost_select )
            }


            // datum od
            if( [1,2,3].includes( vm.izvjestaj.key ) && vm.brend.key ){
                let datum_od_input = {
                    name: 'datum_od',
                    type: 'datepicker',
                    title: 'Datum od',
                    value: vm.datum_od,
                    width: 6,
                    error: '',
                    note: '',
                    explanation: '',
                    placeholder: '',
                    required: !vm.disabled,
                    readonly: vm.disabled,
                    disabled: vm.disabled,
                    config: {
                        mode: 'date',
                        title_width: 4,
                        input_width: 8
                    }
                }

                inputs.push( datum_od_input )
            }

            // datum do
            if( [1,2,3].includes( vm.izvjestaj.key ) && vm.brend.key ){

                let datum_do_input = {
                    name: 'datum_do',
                    type: 'datepicker',
                    title: 'Datum do',
                    value: vm.datum_do,
                    width: 6,
                    error: '',
                    note: '',
                    explanation: '',
                    placeholder: '',
                    required: !vm.disabled,
                    readonly: vm.disabled,
                    disabled: vm.disabled,
                    config: {
                        mode: 'date',
                        title_width: 4,
                        input_width: 8
                    }
                }

                inputs.push( datum_do_input )
            }




            let settings = {
                width: 6,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: inputs,
                            }
                        ],
                    },
                ],
                buttons: buttons
            }

            vm.form_settings = settings
        },

        onInput( name, value ){
            let vm = this
            if( name === 'izvjestaj_id' && value !== vm.izvjestaj.key  ){
                if( value )
                    vm.izvjestaj = vm.izvjestaj_select.find( item => item.key === value )
                this.setup_form()
            } else if( name === 'brend_id' && value !== vm.brend.key ){
                if( value )
                    vm.brend = vm.brend_select.find( item => item.key === value )
                this.setup_form()
            } else if( name === 'pogodnost_id' && value !== vm.pogodnost.key ){
                if( value )
                    vm.pogodnost = vm.pogodnost_select.find( item => item.key === value )
                this.setup_form()
            } else if ( value ) {
                if( name === 'datum_od' ){
                    vm.datum_od = value
                } else if( name === 'datum_do' ){
                    vm.datum_do = value
                }
                this.setup_form()
            }
        },


        onFormButtonClick( id, action, config, data ){
            let vm = this
            vm.open_loader = true
            axios({
                method: 'POST',
                url: process.env.VUE_APP_API_URL + 'izvjestaj/' + vm.izvjestaj.key,
                data: data,
                responseType: 'blob'
            }
            ).then((response) => {
                vm.open_loader = false
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([response.data]),
                    {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    }
                );
                link.setAttribute('download', vm.izvjestaj.value + '.xlsx');
                document.body.appendChild(link);
                link.click();
            }, (error) => {
                vm.open_loader = false
                console.log(error);
                toastr.error( error.toString() )
            });
        }

    }, mounted() {
        this.setup_form()
    }
}
</script>

import
<style scoped>

</style>
