var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('breadcrumbs',{attrs:{"title":'E-mails',"breadcrumbs":_vm.allBreadcrumbs}}),_c('nav',{staticClass:"navbar navbar-expand-lg navbar-light",staticStyle:{"background-color":"#2F4050"}},[_c('a',{class:{
                        'navbar-brand btn btn-light btn-xl': true,
                        'active': _vm.active_route === _vm.route
                    },on:{"click":function($event){return _vm.open(_vm.route)}}},[_c('strong',[_vm._v("E-mail")])]),_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"navbar-nav mr-auto"},[_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                'nav-link btn btn-light': true,
                                'active': _vm.active_route === _vm.route + '/log'
                            },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/log' )}}},[_c('strong',[_vm._v("Logovi")])])]),_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                'nav-link btn btn-light': true,
                                'active': _vm.active_route === _vm.route + '/test'
                            },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/test' )}}},[_c('strong',[_vm._v("Test")])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }