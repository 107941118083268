<template>
    <div>
        <h2 class="text-navy" style="">
            <i class="fa fa-play fa-rotate-270" style="height: 10px;"></i>
        </h2>
        <!--<h2 class="text-danger" style="height: 10px">
            <i class="fa fa-play fa-rotate-90" style="height: 10px"></i>
        </h2>-->
    </div>
</template>

<script>
export default {
name: "TrendCell"
}
</script>

<style scoped>

</style>