<template>
    <div>
        <breadcrumbs
                :title="'Brendovi'"
                :breadcrumbs="breadcrumbs"
        >
        </breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Novi brend</h5><br>
            </div>
            <div class="ibox-content">
                <brend-forma
                        :disabled="false"
                        :id="null"
                        :modal = false
                        :action="'create'"
                >
                </brend-forma>
            </div>
        </div>
    </div>

</template>

<script>
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import BrendForma from "./BrendForma";
export default {
    name: "BrendCreate",
    components: {BrendForma, Breadcrumbs},
    data(){
        return {
            breadcrumbs: []
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/postavke/brendovi',
                    name: 'Brendovi'
                },
                {
                    path: '/postavke/brendovi/create',
                    name: 'Novi brend'
                }
            ]
        }
    },
    mounted() {
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>