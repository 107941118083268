<template>
    <div class="col-lg-2" id="osobe_length">
        <label>Prikaži
            <select @change="setNumberOfRows" name="osobe_length" class="form-control form-control-sm">
                <option v-for=" ( val, key ) in results_per_page.list" :key="key" :value="key" :selected="key === results_per_page.selected">{{ val }}</option>
            </select>
            rezultata po stranici
        </label>
    </div>
</template>

<script>
export default {
    name: "ResultPerPage",
    props:{
        results_per_page: {
            type: Object,
            required: true,
            validator: function( props ){
                let ret = true;
                for( let key in props.list ){
                    ret = ret && Number.isInteger( parseInt( key ) )
                }
                return ret;
            }
        }
    },
    methods:{
        setNumberOfRows( event ){
            this.$emit( 'setNumberOfRows', event.target.value )
        }
    }
}
</script>

<style scoped>

</style>