<template>
    <div>
        <forms
                :settings="settings"
                @onFormButtonClick="onFormButtonClick"
                @onInput="onInput"
        ></forms>
    </div>
</template>

<script>
import axios from "axios";
import toastr from 'toastr';
import Forms from "@/components/form/Forms";
export default {
    name: "Kategorija2Forma",
    components:{ Forms },
    props:{
        disabled:{
            type: Boolean,
            default: false
        },
        id:{
            default: null
        },
        modal:{
            type: Boolean,
            default: false
        },
        action: {
            type: String
        },
        user: {
            type: Object
        },
    },
    data(){
        return {
            settings: {},
            errors: {},
            route: '/kategorije2',
            url: 'kategorija2',
            data: {},
            input_data: {}
        }
    },

    methods: {

        init(){
            let vm = this
            if ( vm.action === 'edit' || vm.action === 'show' ){

                axios.get( process.env.VUE_APP_API_URL + vm.url + '/' + vm.id )
                        .then( function ( response ) {
                            vm.data = response.data
                            vm.$emit( 'returnData', response.data )
                            vm.setupForm()
                        } )
                        .catch( function ( error ) {
                            if(error instanceof Error) {
                                console.log( 'Error: ' . error);
                            } else {
                                console.log( 'Unexpected response: ' . error);
                            }
                        } )

            } else if ( vm.action === 'create' ) {
                vm.data = {}
                vm.setupForm()
            } else {
                return
            }
        },

        setupForm(){

            let vm = this;

            vm.buttons = []

            let button_cancel = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
                action: 'redirect', // request
                config: {
                    url: vm.route + ( vm.action === 'edit' ? ( '/' + vm.id) : '' )
                }
            }

            let button_delete = {
                id: 'delete',
                title: 'Obriši',
                type: 'danger',
                action: 'request',
                config: {
                    method: 'DELETE',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let button_edit = {
                id: 'edit',
                title: 'Uredi',
                type: 'success',
                action: 'redirect',
                config: {
                    url: vm.route +  '/' + vm.id +'/edit'
                }
            }

            let button_save = {
                id: 'save',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + vm.url
                }
            }

            let button_update = {
                id: 'update',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'PUT',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let buttons = []


            if( vm.action === 'edit' ){
                buttons.push( button_cancel)
                buttons.push( button_update)
            } else if( vm.action === 'show' ) {
                buttons.push( button_cancel )
                buttons.push( button_delete )
                buttons.push( button_edit )
            }
            else if ( vm.action === 'create' ) {
                buttons.push( button_cancel )
                buttons.push( button_save )
            } else {
                return
            }

            console.log( vm.data );

            let settings = {
                width: 12,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: [
                                    {
                                        name: 'naziv',
                                        type: 'input',
                                        title: 'Naziv',
                                        value: vm.data['naziv'] ? vm.data['naziv'] : '',
                                        width: 6,
                                        error: vm.errors['naziv'] ? vm.errors['naziv'] : '',
                                        note: '',
                                        explanation: '',
                                        placeholder: '',
                                        required: !vm.disabled,
                                        readonly: vm.disabled,
                                        disabled: vm.disabled,
                                        config: {
                                            title_width: 2,
                                            input_width: 10
                                        }
                                    },
                                    {
                                        name: 'opis',
                                        type: 'input',
                                        title: 'Opis',
                                        value: vm.data['opis'] ? vm.data['opis'] : '',
                                        width: 12,
                                        error: vm.errors['opis'] ? vm.errors['opis'] : '',
                                        note: '',
                                        explanation: '',
                                        placeholder: '',
                                        required: !vm.disabled,
                                        readonly: vm.disabled,
                                        disabled: vm.disabled,
                                        config: {
                                            title_width: 1,
                                            input_width: 11
                                        }
                                    },
                                    {
                                        name: 'brend_id',
                                        type: 'select',
                                        title: 'Brend',
                                        value: {
                                            key: vm.input_data.brend
                                                    ? vm.input_data.brend.id
                                                    : (
                                                            vm.data.brend
                                                                    ? vm.data.brend.id
                                                                    : ''
                                                    ),
                                            value: vm.input_data.brend
                                                    ? vm.input_data.brend.naziv
                                                    : (
                                                            vm.data.brend
                                                                    ? vm.data.brend.naziv
                                                                    : ''
                                                    )
                                        },
                                        width: 3,
                                        error: vm.errors['brend_id'] ? vm.errors['brend_id'] : '',
                                        note: '',
                                        explanation: '',
                                        placeholder: '',
                                        required: !vm.disabled,
                                        readonly: vm.disabled,
                                        disabled: vm.disabled,
                                        config: {
                                            options: function () {
                                                let model = this

                                                if (vm.action === 'show') {
                                                    model.options = []
                                                    return
                                                }

                                                let filters = {}
                                                let tmp_brendovi = []
                                                vm.user.user_brendovi.forEach(function (item) {
                                                    tmp_brendovi.push(item.brend.id)
                                                })
                                                if (tmp_brendovi.length)
                                                    filters['id'] = tmp_brendovi

                                                axios.get(process.env.VUE_APP_API_URL + 'brend', {
                                                    params: {
                                                        page: 1,
                                                        per_page: -1,
                                                        filters: filters
                                                    }
                                                })
                                                        .then(function (response) {
                                                            let tmp = [];
                                                            response.data.data.forEach(function (item) {
                                                                tmp.push({
                                                                    key: item.id,
                                                                    value: item.naziv
                                                                });
                                                            });
                                                            model.options = tmp
                                                        })
                                                        .catch(function (error) {
                                                            if (error instanceof Error) {
                                                                console.log('Error: '.error);
                                                            } else {
                                                                console.log('Unexpected response: '.error);
                                                            }
                                                        })
                                            },
                                            title_width: 4,
                                            input_width: 8
                                        }
                                    },
                                    {
                                        name: 'datum',
                                        type: 'datepicker',
                                        title: 'Datum',
                                        value: vm.input_data.datum
                                                ? vm.input_data.datum
                                                : (
                                                        vm.data.datum
                                                                ? vm.data.datum
                                                                : null
                                                ),
                                        width: 3,
                                        error: vm.errors['datum'] ? vm.errors['datum'] : '',
                                        note: '',
                                        explanation: '',
                                        placeholder: '',
                                        required: vm.disabled,
                                        readonly: vm.disabled,
                                        disabled: vm.disabled,
                                        config: {
                                            mode: 'date',
                                            title_width: 4,
                                            input_width: 8
                                        }
                                    },
                                    {
                                        name: 'aktivna',
                                        type: 'select',
                                        title: 'Aktivna',
                                        value: {
                                            key: vm.input_data.aktivna
                                                    ? vm.input_data.aktivna === '1' ? '1' : '0'
                                                    : vm.data.aktivna  ? '1' : '0',
                                            value: vm.input_data.aktivna
                                                    ? ( vm.input_data.aktivna === '1' ? 'DA' : 'NE' )
                                                    : (
                                                            vm.data.aktivna
                                                                    ? 'DA'
                                                                    : 'NE'
                                                    )
                                        },
                                        width: 3,
                                        error: vm.errors['aktivna'] ? vm.errors['aktivna'] : '',
                                        note: '',
                                        explanation: '',
                                        placeholder: '',
                                        required: vm.disabled,
                                        readonly: vm.disabled,
                                        disabled: vm.disabled,
                                        config: {
                                            options: [
                                                {
                                                    key: '0',
                                                    value: 'NE'
                                                },
                                                {
                                                    key: '1',
                                                    value: 'DA'
                                                },
                                            ],
                                            title_width: 4,
                                            input_width: 8
                                        }
                                    },
                                    {
                                        name: 'pocetna',
                                        type: 'select',
                                        title: 'Početna',
                                        value: {
                                            key: vm.input_data.pocetna
                                                    ? vm.input_data.pocetna === '1' ? '1' : '0'
                                                    : vm.data.pocetna ? '1' : '0',
                                            value: vm.input_data.pocetna
                                                    ? ( vm.input_data.pocetna === '1' ? 'DA' : 'NE' )
                                                    : (
                                                            vm.data.pocetna
                                                                    ? 'DA'
                                                                    : 'NE'
                                                    )
                                        },
                                        width: 3,
                                        error: vm.errors['pocetna'] ? vm.errors['pocetna'] : '',
                                        note: '',
                                        explanation: 'Kod novog upisa kupca njemu se automatski pridružuje početna kategoriju. Svaki brend mora imati tično jednu početnu kategoriju.',
                                        placeholder: '',
                                        required: vm.disabled,
                                        readonly: vm.disabled,
                                        disabled: vm.disabled,
                                        config: {
                                            options: [
                                                {
                                                    key: '0',
                                                    value: 'NE'
                                                },
                                                {
                                                    key: '1',
                                                    value: 'DA'
                                                },
                                            ],
                                            title_width: 4,
                                            input_width: 8
                                        }
                                    }
                                ],
                            }
                        ],
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },

        /**
         *  on input return data
         */

        onInput( name, value ){
            //let vm = this
            let vm = this;
            if( name === 'brend_id') {
                if( value )
                    axios.get( process.env.VUE_APP_API_URL +  'brend/' + value )
                            .then( function ( response ) {
                                vm.input_data['brend'] = response.data
                            } )
                            .catch( function ( error ) {
                                if(error instanceof Error) {
                                    console.log( 'Error: ' . error);
                                } else {
                                    console.log( 'Unexpected response: ' . error);
                                }
                            } )
                else vm.input_data['brend'] = null
            } else {
                vm.input_data[name] = value
            }
        },

        /**
         *  on button click
         */
        onFormButtonClick( id, action, config, data  ){

            if( id === 'save' || id === 'update' ){
                if( data.success){
                    if( id == 'save' )
                        toastr.success( 'Uspješno spremljeno!' )
                    else if ( id == 'update' )
                        toastr.success( 'Uspješno ažurirano!' )
                    this.$router.push({ path: this.route + '/' + data.data.id } )
                } else {
                    this.errors = data.messages
                    this.init()
                }
            } else if( id == 'delete' ){
                toastr.error( 'Uspješno obrisano!' )
                this.$router.push({ path: this.route } )
            }

        },


    },
    mounted() {
        this.init()
    }
}
</script>

<style scoped>

</style>