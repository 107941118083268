<template>
    <div class="form-group">
        <input
                @input="event_call"
                :type="type"
                :placeholder="placeholder"
                class="form-control"
                :value="value"
                v-model="ret_value"
                :disabled="disabled_input"
                style="height: 20px"
        >
        <span class="form-text m-b-none text-danger">{{ error }}</span>
    </div>
</template>

<script>
export default {
name: "Input"
}
</script>

<style scoped>

</style>