<template>
    <div>
        <forms
                :settings="settings"
                @onFormButtonClick="onFormButtonClick"
        >
        </forms>
    </div>
</template>

<script>
import Forms from "../../../../components/form/Forms";
import axios from "axios";
import toastr from "toastr";
export default {
    name: "PogodnostKategorijaForma",
    components: { Forms },
    props:{
        disabled:{
            type: Boolean,
            default: false
        },
        id:{
            default: null
        },
        modal:{
            type: Boolean,
            default: false
        },
        action: {
            type: String
        },
    },
    data(){
        return {
            settings: {},
            errors: {},
            route: '/pogodnosti/' + this.$route.params.pogodnost_id + '/kategorije2',
            url: 'kategorija2-pogodnost',
            data: {},
            input_data: {},
            pogodnost_id: this.$route.params.pogodnost_id,
            pogodnost: null
        }
    },

    methods: {

        init(){
            let vm = this

            axios.get( process.env.VUE_APP_API_URL + 'pogodnost/' + vm.pogodnost_id )
                    .then( function ( response ) {

                        vm.pogodnost = response.data

                        if ( vm.action === 'edit' || vm.action === 'show' ){
                            axios.get( process.env.VUE_APP_API_URL + vm.url + '/' + vm.id )
                                    .then( function ( response ) {
                                        vm.data = response.data
                                        vm.$emit( 'returnData', response.data )
                                        vm.setupForm()
                                    } )
                                    .catch( function ( error ) {
                                        if(error instanceof Error) {
                                            console.log( 'Error: ' . error);
                                        } else {
                                            console.log( 'Unexpected response: ' . error);
                                        }
                                    } )

                        } else if ( vm.action === 'create' ) {
                            vm.data = {}
                            vm.setupForm()
                        } else {
                            return
                        }

                    } )
                    .catch( function( error ) {
                        if(error instanceof Error) {
                            console.log( 'Error: ' . error);
                        } else {
                            console.log( 'Unexpected response: ' . error);
                        }
                    } )
        },

        setupForm(){

            let vm = this;

            vm.buttons = []

            let button_cancel = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
                action: 'redirect', // request
                config: {
                    url: vm.route + ( vm.action === 'edit' ? ( '/' + vm.id) : '' )
                }
            }

            let button_delete = {
                id: 'delete',
                title: 'Obriši',
                type: 'danger',
                action: 'request',
                config: {
                    method: 'DELETE',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            // let button_edit = {
            //     id: 'edit',
            //     title: 'Uredi',
            //     type: 'success',
            //     action: 'redirect',
            //     config: {
            //         url: vm.route +  '/' + vm.id +'/edit'
            //     }
            // }

            let button_save = {
                id: 'save',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + vm.url
                }
            }

            // let button_update = {
            //     id: 'update',
            //     title: 'Spremi',
            //     type: 'primary',
            //     action: 'request',
            //     config: {
            //         method: 'PUT',
            //         url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
            //     }
            // }

            let buttons = []


            if( vm.action === 'edit' ){
                buttons.push( button_cancel)
                //buttons.push( button_update)
            } else if( vm.action === 'show' ) {
                buttons.push( button_cancel )
                buttons.push( button_delete )
                //buttons.push( button_edit )
            }
            else if ( vm.action === 'create' ) {
                buttons.push( button_cancel )
                buttons.push( button_save )
            } else {
                return
            }


            let settings = {
                width: 6,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: [
                                    {
                                        name: 'pogodnost_id',
                                        type: 'hidden',
                                        value: vm.pogodnost_id,
                                        disabled: vm.disabled,
                                        config: {
                                            hidden:true
                                        }
                                    },
                                    {
                                        name: 'kategorija2_id',
                                        type: 'select',
                                        title: 'Kategorija',
                                        value: {
                                            key: vm.data['kategorija2_id'] ? vm.data['kategorija2_id'] : '',
                                            value: vm.data['kategorija2_id'] ? vm.data['kategorija2']['naziv'] : ''
                                        },
                                        width: 12,
                                        error: vm.errors['kategorija2_id'] ? vm.errors['kategorija2_id'] : '',
                                        note: '',
                                        explanation: '',
                                        placeholder: '',
                                        required: false,
                                        readonly: vm.disabled,
                                        disabled: vm.disabled,
                                        config: {
                                            options: function ( ) {
                                                let model = this

                                                if( vm.action === 'show'){
                                                    model.options = []
                                                    return
                                                }
                                                axios.get( process.env.VUE_APP_API_URL +  'kategorija2', {
                                                    params: {
                                                        filters: {
                                                            brend_id: vm.pogodnost.brend_id,
                                                            aktivna: [1]
                                                        }
                                                    }
                                                })
                                                        .then( function ( response ) {
                                                            let tmp = [];
                                                            response.data.data.forEach( function ( item ) {
                                                                tmp.push({
                                                                    key: item.id,
                                                                    value: item.naziv
                                                                } );
                                                            } );
                                                            model.options = tmp
                                                        } )
                                                        .catch( function ( error ) {
                                                            if(error instanceof Error) {
                                                                console.log( 'Error: ' . error);
                                                            } else {
                                                                console.log( 'Unexpected response: ' . error);
                                                            }
                                                        } )
                                            },
                                            multiple: false,
                                            title_width: 2,
                                            input_width: 10
                                        }
                                    },
                                ],
                            }
                        ],
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },

        /**
         *  on button click
         */
        onFormButtonClick( id, action, config, data  ){

            if( id === 'save' || id === 'update' ){
                if( data.success){
                    if( id == 'save' )
                        toastr.success( 'Uspješno spremljeno!' )
                    else if ( id == 'update' )
                        toastr.success( 'Uspješno ažurirano!' )
                    this.$router.push({ path: this.route } )
                } else {
                    this.errors = data.messages
                    this.init()
                }
            } else if( id == 'delete' ){
                toastr.error( 'Uspješno obrisano!' )
                this.$router.push({ path: this.route } )
            }

        },
    },

    mounted() {
        this.init()
    }
}
</script>

<style scoped>

</style>