<template>
    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-10">
            <h2>{{title}}</h2>
            <ol class="breadcrumb">
                <!-- TODO:: samo zadnji mora biti aktivan, ostali se proslijeđuju u ruter -->
                <li
                        v-for="( item, index) in breadcrumbs"
                        :key="index"
                        class="breadcrumb-item active"
                >
                    <router-link
                            v-if="index !== breadcrumbs.length - 1"
                            :to="{ path: item.path }"
                    >
                        {{ item.name }}
                    </router-link>
                    <span v-else class="text-info" @click.prevent="" disabled="true">{{ item.name }}</span>
                </li>
            </ol>
        </div>
        <div class="col-lg-2">

        </div>
    </div>
</template>

<script>
    export default {
        name: "Breadcrumbs",
        props: [
                'title',
                'breadcrumbs'
        ],
    }
</script>

<style scoped>

</style>