<template>
    <div>
        <breadcrumbs
                :title="'Savjetnice'"
                :breadcrumbs="breadcrumbs"
        ></breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Savjetnice</h5><br>
                <button
                        type="button"
                        class="btn btn-lg btn-primary"
                        @click.prevent="create"
                >
                    Novi unos
                </button>
            </div>
            <div class="ibox-content">
                <datatables
                        v-if="show_datatable"
                        :url="datatables_url"
                        :settings="datatables.settings"
                        :title="datatables.title"
                        @rowClick="rowClick"
                >
                </datatables>
            </div>
        </div>
    </div>

</template>

<script>
import Datatables from "@/components/datatable/Datatables";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
export default {
    name: "Savjetnice",
    components: {Breadcrumbs, Datatables},
    data(){
        return {
            show_datatable : false,
            datatables_url: process.env.VUE_APP_API_URL + 'savjetnica',
            datatables: {},
            breadcrumbs: [],
        }
    },

    methods:{
        rowClick( row ){
            this.$router.push( { path: '/savjetnice/' + row.id } )
        },

        create(){
            this.$router.push( { path: '/savjetnice/create' } )
        },

        setDatatable(){
            let vm = this;
            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'oznaka',
                            'title': 'Oznaka',
                            'column_visibility_group': {
                                id: 'savjetnice',
                                visible: true
                            }
                        },
                        {
                            'id': 'ime',
                            'title': 'Ime',
                            'column_visibility_group': {
                                id: 'savjetnice',
                            }
                        },
                        {
                            'id': 'prezime',
                            'title': 'Prezime',
                            'column_visibility_group': {
                                id: 'savjetnice',
                            }
                        },
                        {
                            'id': 'email',
                            'title': 'E-mail',
                            'column_visibility_group': {
                                id: 'savjetnice',
                            }
                        },
                        {
                            'id': 'gsm',
                            'title': 'GSM',
                            'column_visibility_group': {
                                id: 'savjetnice',
                            }
                        },
                        {
                            'id': 'poslovnica_naziv',
                            'title': 'Poslovnica',
                            'type': function( row ){
                                return row.poslovnica ? row.poslovnica.naziv : '';
                            },
                            'column_visibility_group': {
                                id: 'savjetnice',
                                visible: true
                            }
                        },
                        {
                            'id': 'aktivna',
                            'title': 'Aktivna',
                            'type': function( row ){
                                return row.aktivna ? 'DA' : 'NE';
                            },
                            'filter': {
                                'type': 'select',
                                'search': [
                                    {
                                        key: '1',
                                        value: 'DA'
                                    },
                                    {
                                        key: '0',
                                        value: 'NE'
                                    }
                                ],
                                'value': null
                            },
                            'column_visibility_group': {
                                id: 'savjetnice',
                                visible: true
                            }
                        },

                    ],
                    column_visibility_groups:{
                        'savjetnice' : 'Savjetnice'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Savjetnice',
            }
            vm.show_datatable = true;
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/savjetnice',
                    name: 'Savjetnice'
                }
            ];
        }
    },
    mounted() {
        this.setDatatable()
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>