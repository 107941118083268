import { render, staticRenderFns } from "./KupacKategorija2Edit.vue?vue&type=template&id=684e5fa4&scoped=true&"
import script from "./KupacKategorija2Edit.vue?vue&type=script&lang=js&"
export * from "./KupacKategorija2Edit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "684e5fa4",
  null
  
)

export default component.exports