<template>
    <div>
        <breadcrumbs
                :title="'E-mails'"
                :breadcrumbs="breadcrumbs"
        ></breadcrumbs>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Emails</h5><br>
            </div>
            <button
                    type="button"
                    class="btn btn-lg btn-primary"
                    @click.prevent="create"
            >
                Novi Email
            </button>
            <div class="ibox-content">
                <datatables
                        v-if="show_datatable"
                        :url="datatables_url"
                        :settings="datatables.settings"
                        :title="datatables.title"
                        @rowClick="rowClick"
                >
                </datatables>
            </div>
        </div>

    </div>
</template>

<script>
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Datatables from "../../../components/datatable/Datatables";
export default {
    name: "Emailing",
    components: {Datatables, Breadcrumbs},
    props: ['user'],
    data(){
        return {
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL + 'emailing-predlozak',
            datatables: {},
            breadcrumbs: [],
        }
    },
    methods:{
        rowClick( row ){
            this.$router.push( { path: '/emailing/' + row.id })
        },

        create(){
            this.$router.push( { path: 'emailing/create'} )
        },

        setDatatable(){
            let vm = this

            vm.datatables = {
                settings: {
                    table_header: [
                        {
                            'id' : 'id',
                            'visible': false
                        },
                        {
                            'id': 'brend_id',
                            'title': 'Brend',
                            'searchable': false,
                            'type': function( row ){
                                return row.brend ? row.brend.naziv : ''
                            },
                            'column_visibility_group': {
                                id: 'emailing',
                            }
                        },
                        {
                            'id': 'emailing_tip_id',
                            'title': 'Tip',
                            'searchable': false,
                            'type': function( row ){
                                return row.emailing_tip ? row.emailing_tip.naziv : ''
                            },
                            'column_visibility_group': {
                                id: 'emailing',
                            }
                        },
                        {
                            'id': 'aktivan',
                            'title': 'Aktivan',
                            'type': function( row ){
                                return row.aktivan && row.aktivan === '1' ? 'Da' : 'Ne'
                            },
                            'filter': {
                                'type': 'select',
                                'search': [
                                    {
                                        key: '1',
                                        value: 'Da'
                                    },
                                    {
                                        key: '0',
                                        value: 'Ne'
                                    }
                                ],
                                'value': null
                            },
                            'column_visibility_group': {
                                id: 'kategorija',
                            },
                        },
                        // {
                        //     'id': 'emailing_settings_id',
                        //     'title': 'Postavke',
                        //     'searchable': false,
                        //     'type': function( row ){
                        //         return row.emailing_settings ? row.emailing_settings : ''
                        //     },
                        //     'column_visibility_group': {
                        //         id: 'emailing',
                        //     }
                        // }
                    ],
                    column_visibility_groups:{
                        'emailing' : 'E-mails'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'E-mails'
            }

            vm.show_datatable = true;
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/emailing',
                    name: 'E-mails'
                }
            ]
        }

    }, mounted() {
        this.setDatatable()
        this.setBreadcrumbs()
    }

}
</script>

<style scoped>

</style>