<template>
    <div style="margin-bottom: 50px">
        <emailing-navigation
                :id="id"
                :breadcrumbs="breadcrumbs"
        >
        </emailing-navigation>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Email</h5>
            </div>
            <div class="ibox-content">
                <emailing-forma
                        :disabled="true"
                        :id="id"
                        :modal="false"
                        :action="'show'"
                        @returnData="setData"
                        :user="user"
                ></emailing-forma>
            </div>
        </div>

    </div>
</template>

<script>
import EmailingForma from "./EmailingForma";
import EmailingNavigation from "./EmailingNavigation";
export default {
    name: "EmailingDetails",
    components: {EmailingNavigation, EmailingForma},
    props:['user'],
    data(){
        return {
            id: this.$route.params.email_id,
            breadcrumbs: [],
            email: ''
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [

            ]
        },

        setData( data ) {
            this.email = data.emailing_tip ? data.emailing_tip.naziv : ''
            this.setBreadcrumbs()
        }
    }
}
</script>

<style scoped>

</style>