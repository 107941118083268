<template>
    <div>
        <breadcrumbs
                :title="'Grupe kupaca'"
                :breadcrumbs="breadcrumbs"
        >
        </breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Nova grupa kupaca</h5><br>
            </div>
            <div class="ibox-content">
                <grupa-kupaca-forma
                        :disabled="false"
                        :id="null"
                        :modal = false
                        :action="'create'"
                >
                </grupa-kupaca-forma>
            </div>
        </div>
    </div>

</template>

<script>
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import GrupaKupacaForma from "./GrupaKupacaForma";
export default {
    name: "GrupaKupacaCreate",
    components: {GrupaKupacaForma, Breadcrumbs},
    data(){
        return {
            breadcrumbs: []
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/grupe-kupaca',
                    name: 'Grupe kupaca'
                },
                {
                    path: '/grupe-kupaca/create',
                    name: 'Nova grupa kupaca'
                }
            ]
        }
    },
    mounted() {
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>