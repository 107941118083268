<template>
    <body id="body" :class="{'pace-done':true, 'mini-navbar':show_left_menu_var}">
        <!--<div v-if="settings" id="wrapper" >-->

        <div id="wrapper">

            <navigation-left></navigation-left>

            <div id="page-wrapper" class="gray-bg dashbard-1" >

                <div class="alert alert-success" role="alert" v-if="success">
                    {{ success }}
                </div>
                <div class="alert alert-danger" role="alert" v-if="error">
                    {{ error }}
                </div>

                <navigation-top
                        @show_left_menu="show_left_menu"
                >
                </navigation-top>

                <div>
                    <router-view></router-view>
                </div>

                <navigation-bottom>

                </navigation-bottom>

            </div>

        </div>
    </body>
</template>
<script>
import NavigationLeft from "@/views/layout/NavigationLeft";
import NavigationTop from "@/views/layout/NavigationTop";
import NavigationBottom from "@/views/layout/NavigationBottom";


    export default {
        name: "Home",
        components: {
            NavigationBottom,
            NavigationLeft,
            NavigationTop
        },
        data: function(){
            return {
                show_left_menu_var:false,
                success: null,
                error: null
            }
        },
        methods:{
            show_left_menu(){
                this.show_left_menu_var =! this.show_left_menu_var
            },
        },
        beforeMount() {
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>    