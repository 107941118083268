<template>
    <div>
        <kupci-navigation
                :id="kupac_id"
                :breadcrumbs="breadcrumbs"
                :user="user"
        >
        </kupci-navigation>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Kategorija: {{ naziv }}</h5>
            </div>
            <div class="ibox-content">
                <kupac-kategorija2-forma
                        :disabled="true"
                        :id="id"
                        :modal="false"
                        :action="'show'"
                        @returnData="setData"
                        :user="user"
                >
                </kupac-kategorija2-forma>
            </div>
        </div>
    </div>
</template>

<script>
import KupciNavigation from "../KupciNavigation";
import KupacKategorija2Forma from "./KupacKategorija2Forma";
export default {
    name: "KupacKategorijaDetails",
    components: {KupacKategorija2Forma, KupciNavigation},
    props:['user'],
    data(){
        return {
            breadcrumbs: [],
            id:this.$route.params.kupac_kategorija_id,
            kupac_id: this.$route.params.kupac_id,
            naziv: ''
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/kupci/' + this.kupac_id + '/kategorija',
                    name: 'Kategorije'
                },
                {
                    path: '/kupci/' + this.kupac_id + '/kategorija/' + this.id,
                    name: 'Kategorija: ' + this.naziv
                }
            ]
        },
        setData( data ){
            this.naziv = data.naziv
            this.setBreadcrumbs()
        },

    },
    mounted() {
    }
}
</script>

<style scoped>

</style>