<template>
    <div>
        <breadcrumbs
                :title="'Poslovnice'"
                :breadcrumbs="breadcrumbs"
        >
        </breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Nova poslovnica</h5><br>
            </div>
            <div class="ibox-content">
                <poslovnica-forma
                        :disabled="false"
                        :id="null"
                        :modal = false
                        :action="'create'"
                >
                </poslovnica-forma>
            </div>
        </div>
    </div>

</template>

<script>
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import PoslovnicaForma from "./PoslovnicaForma";
export default {
    name: "PoslovnicaCreate",
    components: {PoslovnicaForma, Breadcrumbs},
    data(){
        return {
            breadcrumbs: []
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/postavke/poslovnice',
                    name: 'Poslovnice'
                },
                {
                    path: '/postavke/poslovnice/create',
                    name: 'Nova poslovnica'
                }
            ]
        }
    },
    mounted() {
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>