<template>
    <div>
        <breadcrumbs
                :title="'Emailing postavke'"
                :breadcrumbs="breadcrumbs"
        >
        </breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Emailing postavke: {{ naziv }}</h5>
            </div>
            <div class="ibox-content">
                <emailing-settings-forma
                        :disabled="false"
                        :id="id"
                        :modal="false"
                        :action="'edit'"
                        @returnData="setData"
                >
                </emailing-settings-forma>
            </div>
        </div>
    </div>

</template>

<script>
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import EmailingSettingsForma from "./EmailingSettingsForma";
export default {
    name: "EmailingSettingsEdit",
    components: {EmailingSettingsForma, Breadcrumbs},
    data(){
        return {
            id: this.$route.params.emailing_postavke_id,
            breadcrumbs: [],
            naziv: ''
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/postavke/emailing-postavke',
                    name: 'Emailing postavke'
                },
                {
                    path: ('/postavke/emailing-postavke/' + this.id ),
                    name: ('Emailing postavke: ' + this.naziv )
                },
                {
                    path: ( '/postavke/emailing-postavke/' + this.id + '/edit' ),
                    name: ( 'Emailing postavke: ' + this.naziv )
                }
            ]
        },
        setData( data ){
            this.naziv = data.naziv ? data.naziv : ''
            this.setBreadcrumbs()
        }
    }
}
</script>

<style scoped>

</style>