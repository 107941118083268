<template>
    <div>
        <breadcrumbs
                :title="'Grupe kupaca'"
                :breadcrumbs="breadcrumbs"
        >
        </breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Grupe kupaca: {{ naziv }}</h5>
            </div>
            <div class="ibox-content">
                <grupa-kupaca-forma
                        :disabled="true"
                        :id="id"
                        :modal="false"
                        :action="'show'"
                        @returnData="setData"
                >
                </grupa-kupaca-forma>
            </div>
        </div>
    </div>

</template>

<script>
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import GrupaKupacaForma from "./GrupaKupacaForma";
export default {
    name: "GrupaKupacaDetails",
    components: {GrupaKupacaForma, Breadcrumbs},
    data(){
        return {
            id: this.$route.params.grupa_kupca_id,
            breadcrumbs: [],
            naziv: ''
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/gruoe-kupaca',
                    name: 'Grupe kupaca'
                },
                {
                    path: ('/grupe-kupaca/' + this.id ),
                    name: ('Grupe kupca: ' + this.naziv )
                }
            ]
        },
        setData( data ){
            this.naziv = data.naziv ? data.naziv : ''
            this.setBreadcrumbs()
        }
    }
}
</script>

<style scoped>

</style>