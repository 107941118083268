var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticStyle:{"width":"100%","padding-bottom":"5px"}},[_c('v-date-picker',{attrs:{"locale":"hr","icon":"calendar","mode":'date',"masks":{input:'DD.MM.YYYY.'},"show-day-popover":false,"popover":{ visibility: 'click' },"input-props":{
                        class:'form-control text-dark',
                        placeholder: 'od'
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var inputValue = ref.inputValue;
                    var inputEvents = ref.inputEvents;
return [_c('div',{staticClass:"input-group"},[_c('input',_vm._g({staticClass:"form-control text-dark",attrs:{"placeholder":"Od"},domProps:{"value":inputValue}},inputEvents)),_c('span',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-sm btn-dark",attrs:{"type":"button"},on:{"click":_vm.unsetFilter}},[_vm._v("x")])])])]}}]),model:{value:(_vm.state1),callback:function ($$v) {_vm.state1=$$v},expression:"state1"}})],1),_c('div',{staticStyle:{"width":"100%","padding-bottom":"5px"}},[_c('v-date-picker',{attrs:{"locale":"hr","icon":"calendar","mode":'date',"masks":{input:'DD.MM.YYYY.'},"show-day-popover":false,"popover":{ visibility: 'click' },"input-props":{
                    class:'form-control text-dark',
                    placeholder: 'do'
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var inputValue = ref.inputValue;
                var inputEvents = ref.inputEvents;
return [_c('div',{staticClass:"input-group"},[_c('input',_vm._g({staticClass:"form-control text-dark",attrs:{"placeholder":"Do"},domProps:{"value":inputValue}},inputEvents)),_c('span',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-sm btn-dark",attrs:{"type":"button"},on:{"click":_vm.unsetFilter}},[_vm._v("x")])])])]}}]),model:{value:(_vm.state2),callback:function ($$v) {_vm.state2=$$v},expression:"state2"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }