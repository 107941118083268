<template>
    <div>
        <kupci-navigation
                :id="kupac_id"
                :breadcrumbs="breadcrumbs"
        >
        </kupci-navigation>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Nova grupa</h5>
            </div>
            <div class="ibox-content">
                <kupac-grupa-forma
                        :disabled="false"
                        :id="null"
                        :modal="false"
                        :action="'create'"
                >
                </kupac-grupa-forma>
            </div>
        </div>
    </div>
</template>

<script>
import KupciNavigation from "../KupciNavigation";
import KupacGrupaForma from "./KupacGrupaForma";
export default {
    name: "KupacGrupaCreate",
    components: {KupacGrupaForma, KupciNavigation},
    data(){
        return {
            breadcrumbs: [],
            kupac_id: this.$route.params.kupac_id,
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/kupci/' + this.kupac_id + '/grupe',
                    name: 'Grupe'
                },
                {
                    path: '/kupci/' + this.kupac_id + '/grupe/create',
                    name: 'Nova grupa'
                }
            ]
        }
    }
}
</script>

<style scoped>

</style>