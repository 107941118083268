<template>
    <div class="" style="background-color: white; min-height: 100%;" v-html="predlozak">
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "EmailPotvrda",
    data(){
        return {
            predlozak: '',
            token: this.$route.params.token,
        }
    },
    methods:{
        setup(){
            let vm = this
            axios.get( process.env.VUE_APP_API_URL + 'emailing-predlozak/potvrda/' + vm.token )
                .then( function ( response) {
                    vm.predlozak = response.data
                } )
            .catch( function( error ) {
                console.log( error )
            } )
        }
    },
    mounted() {
        this.setup()
    }
}
</script>

<style scoped>

</style>