<template>
    <div>
        <pogodnosti-navigation
                :id="pogodnost_id"
                :breadcrumbs="breadcrumbs"
        >
        </pogodnosti-navigation>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Kategorije</h5><br>
                <button
                        type="button"
                        class="btn btn-lg btn-primary"
                        @click.prevent="create"
                >
                    Novi unos
                </button>
            </div>
            <div class="ibox-content">
                <datatables
                        v-if="show_datatable"
                        :url="datatables_url"
                        :settings="datatables.settings"
                        :title="datatables.title"
                        @rowClick="rowClick"
                >
                </datatables>
            </div>
        </div>
    </div>
</template>

<script>
import PogodnostiNavigation from "../PogodnostiNavigation";
import Datatables from "../../../../components/datatable/Datatables";
export default {
    name: "PogodnostKategorije2",
    components: {Datatables, PogodnostiNavigation},
    data(){
        return {
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL + 'kategorija2-pogodnost',
            datatables: {},
            breadcrumbs: [],
            pogodnost_id: this.$route.params.pogodnost_id
        }
    },
    methods:{
        rowClick( row ){
            this.$router.push( { path: '/pogodnosti/' + this.pogodnost_id + '/kategorije2/' + row.id } )
        },

        create(){
            this.$router.push( { path: '/pogodnosti/' + this.pogodnost_id + '/kategorije2/create' } )
        },

        setDatatable() {
            let vm = this;
            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'pogodnost_id',
                            'visible': false,
                            'filter':{
                                'type': 'none',
                                'value': vm.pogodnost_id
                            },
                        },
                        {
                            'id': 'kategorija2_naziv',
                            'title': 'Naziv',
                            'type': function( row ){
                                return row.kategorija2 ? row.kategorija2.naziv : ''
                            },
                            'column_visibility_group': {
                                id: 'kategorije',
                            }
                        },
                        {
                            'id': 'kategorija2_opis',
                            'title': 'Opis',
                            'type': function( row ){
                                return row.kategorija2 ? row.kategorija2.opis : ''
                            },
                            'column_visibility_group': {
                                id: 'kategorije',
                            }
                        },
                        {
                            'id': 'kategorija2_aktivna',
                            'title': 'Aktivna',
                            'type': function( row ){
                                return row.kategorija2.aktivna ? 'Da' : 'Ne'
                            },
                            'filter': {
                                'type': 'select',
                                'search': [
                                    {
                                        key: '1',
                                        value: 'Da'
                                    },
                                    {
                                        key: '0',
                                        value: 'Ne'
                                    }
                                ],
                                'value': null
                            },
                            'column_visibility_group': {
                                id: 'kategorija',
                            },
                        },

                    ],
                    column_visibility_groups:{
                        'kategorije' : 'Kategorije'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Kategorije',
            }
            vm.show_datatable = true;
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/pogodnosti/' + this.kupac_id + '/kategorije2',
                    name: 'Kategorije'
                }
            ]
        }
    },
    mounted() {
        this.setDatatable()
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>