<template>
    <div>
        <breadcrumbs
                :title="'Pogodnosti'"
                :breadcrumbs="breadcrumbs"
        >
        </breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Pogodnost: {{ naziv }}</h5>
            </div>
            <div class="ibox-content">
                <pogodnost-forma
                        :disabled="false"
                        :id="id"
                        :modal="false"
                        :action="'edit'"
                        @returnData="setData"
                        :user="user"
                >
                </pogodnost-forma>
            </div>
        </div>
        <poklon-base
            :pogodnost_id="id"
        ></poklon-base>
    </div>

</template>

<script>
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import PogodnostForma from "./PogodnostForma";
import PoklonBase from "./Pokloni/PoklonBase";
export default {
    name: "PogodnostEdit",
    components: {PoklonBase, PogodnostForma, Breadcrumbs},
    props:['user'],
    data(){
        return {
            id: this.$route.params.pogodnost_id,
            breadcrumbs: [],
            naziv: ''
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/pogodnosti',
                    name: 'pogodnosti'
                },
                {
                    path: ('/pogosnosti/' + this.id ),
                    name: ('Pogodnost: ' + this.naziv )
                },
                {
                    path: ( '/pogodnosti/' + this.id + '/edit' ),
                    name: ( 'Uredi pogodnost: ' + this.naziv )
                }
            ]
        },
        setData( data ){
            this.naziv = data.ime ? data.ime + ' ' + data.prezime : ''
            this.setBreadcrumbs()
        }
    }
}
</script>

<style scoped>

</style>
