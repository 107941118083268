<template>
    <div>
        <breadcrumbs
                :title="'Kupci'"
                :breadcrumbs="breadcrumbs"
                :user="user"
        >
        </breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Uredi kupca: {{ naziv }}</h5>
            </div>
            <div class="ibox-content">
                <kupac-forma
                        :disabled="false"
                        :id="id"
                        :modal="false"
                        :action="'edit'"
                        @returnData="setData"
                        :user="user"
                >
                </kupac-forma>
            </div>
        </div>
    </div>
</template>

<script>
import KupacForma from "./KupacForma";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
export default {
  name: "KupacEdit",
    components: {KupacForma, Breadcrumbs},
    props: ['user'],
    data(){
        return {
            id: this.$route.params.kupac_id,
            breadcrumbs: [],
            naziv: '',
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/kupci',
                    name: 'Kupci'
                },
                {
                    path: ( '/kupci/' + this.id ),
                    name: ( 'Kupac: ' + this.naziv )
                },
                {
                    path: ( '/kupci/' + this.id + '/edit' ),
                    name: ( 'Uredi kupca: ' + this.naziv )
                }
            ]
        },
        setData( data ){
            this.naziv = data.ime && data.prezime ? ( data.ime + ' ' + data.prezime ) : ''
            this.setBreadcrumbs()
        }
    },
    mounted() {
    }
};
</script>

<style scoped>

</style>