import { render, staticRenderFns } from "./EmailingSettingsBase.vue?vue&type=template&id=20b67560&scoped=true&"
import script from "./EmailingSettingsBase.vue?vue&type=script&lang=js&"
export * from "./EmailingSettingsBase.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20b67560",
  null
  
)

export default component.exports