<template>
    <div>
        <kupci-navigation
                :id="kupac_id"
                :breadcrumbs="breadcrumbs"
                :user="user"
        >
        </kupci-navigation>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Nova pogodnost</h5>
            </div>
            <div class="ibox-content">
                <kupac-pogodnost-forma
                        :disabled="false"
                        :id="null"
                        :modal="false"
                        :action="'create'"
                        :user="user"
                >
                </kupac-pogodnost-forma>
            </div>
        </div>
    </div>
</template>

<script>
import KupciNavigation from "../KupciNavigation";
import KupacPogodnostForma from "./KupacPogodnostForma";

export default {
    name: "KupacPogodnostCreate",
    components: {KupacPogodnostForma, KupciNavigation},
    props: ['user'],
    data(){
        return {
            breadcrumbs: [],
            kupac_id: this.$route.params.kupac_id,
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/kupci/' + this.kupac_id + '/pogodnosti',
                    name: 'Pogodnosti'
                },
                {
                    path: '/kupci/' + this.kupac_id + '/pogodnosti/create',
                    name: 'Nova pogosnost'
                }
            ]
        }
    },
    mounted() {
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>