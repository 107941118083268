<template>

    <nav class="navbar-default navbar-static-side" role="navigation">
        <div class="sidebar-collapse">
            <ul class="nav metismenu" id="side-menu">
                <li class="nav-header">
                    <div class="dropdown profile-element">
                        <span class="block m-t-xs font-bold" style="color: white"> {{ app_name ? app_name : '' }}</span>
                        <span class="block m-t-xs font-bold"> {{ app_owner ? app_owner : '' }}</span>
                    </div>
                    <div class="logo-element">
                        {{ app_name ? app_name : '' }}
                    </div>
                </li>

                <li @click="change_active_link('kupci')" :class="{'active': active_link === 'kupci'}">
                    <router-link to="/kupci"><i class="fa fa-address-book"></i> <span class="nav-label">Kupci</span></router-link>
                </li>

                <li v-if="user && [2,3].includes( user.permission_id)" @click="change_active_link('pogodnosti')" :class="{'active': active_link === 'pogodnosti'}">
                    <router-link to="/pogodnosti"><i class="fa fa-star"></i> <span class="nav-label">Pogodnosti</span></router-link>
                </li>

<!--                <li v-if="user && [2,3].includes( user.permission_id)" @click="change_active_link('kategorije-kupaca')" :class="{'active': active_link === 'kategorije-kupaca'}">-->
<!--                    <router-link to="/kategorije-kupaca"><i class="fa fa-tag"></i> <span class="nav-label">Kategorije kupaca</span></router-link>-->
<!--                </li>-->

<!--                <li v-if="user && [2,3].includes( user.permission_id)" @click="change_active_link('grupe-kupaca')" :class="{'active': active_link === 'grupe-kupaca'}">-->
<!--                    <router-link to="/grupe-kupaca"><i class="fa fa-tags"></i> <span class="nav-label">Grupe kupaca</span></router-link>-->
<!--                </li>-->

                <li v-if="user && [2,3].includes( user.permission_id)" @click="change_active_link('kategorije2')" :class="{'active': active_link === 'kategorije2'}">
                    <router-link to="/kategorije2"><i class="fa fa-tags"></i> <span class="nav-label">Kategorije kupaca</span></router-link>
                </li>

                <li v-if="user && [2,3].includes( user.permission_id)" @click="change_active_link('savjetnice')" :class="{'active': active_link === 'savjetnice'}">
                    <router-link to="/savjetnice"><i class="fa fa-female"></i> <span class="nav-label">Savjetnice</span></router-link>
                </li>

                <li v-if="user && [2,3].includes( user.permission_id)" @click="change_active_link('emailing')" :class="{'active': active_link === 'emailing'}">
                    <router-link to="/emailing"><i class="fa fa-envelope"></i> <span class="nav-label">Emailing</span></router-link>
                </li>

                <li v-if="user && [2,3].includes( user.permission_id)" @click="change_active_link('ispisi')" :class="{'active': active_link === 'ispisi'}">
                    <router-link to="/ispisi"><i class="fa fa-print"></i> <span class="nav-label">Ispisi</span></router-link>
                </li>

                <li v-if="user && [2,3].includes( user.permission_id)" @click="change_active_link('izvjestaji')" :class="{'active': active_link === 'izvjestaji'}">
                    <router-link to="/izvjestaji"><i class="fa fa-print"></i> <span class="nav-label">Izvještaji</span></router-link>
                </li>

                <li @click="change_active_link('administratori')" :class="{'active': active_link === 'administratori'}">
                    <router-link to="/administratori"><i class="fa fa-users"></i> <span class="nav-label">Administratori</span></router-link>
                </li>

                <li v-if="user && [2,3].includes( user.permission_id)" :class="{'active': active_link === 'postavke' || sec_level_menu === 'postavke' }">
                    <a @click="open_menu('postavke')" aria-expanded="true"><i class="fa fa-cogs"></i> <span class="nav-label">Postavke</span><span class="fa arrow"></span></a>
                    <ul v-if="sec_level_menu === 'postavke'" :class="{'nav':true, 'nav-second-level':true, 'collapse':true, 'in':sec_level_menu === 'postavke' }">

                        <li @click="change_active_link('postavke/brendovi', 'postavke' )" :class="{'active': active_link === 'postavke/brendovi'}">
                            <router-link to="/postavke/brendovi"><span class="nav-label"></span>Brendovi</router-link>
                        </li>

                        <li @click="change_active_link('postavke/poslovnice', 'postavke' )" :class="{'active': active_link === 'postavke/poslovnice'}">
                            <router-link to="/postavke/poslovnice"><span class="nav-label"></span>Poslovnice</router-link>
                        </li>

                        <li class="hr-line-dashed"></li>

                        <li @click="change_active_link('postavke/poste', 'postavke' )" :class="{'active': active_link === 'postavke/poste'}">
                            <router-link to="/postavke/poste"><span class="nav-label"></span>Pošte</router-link>
                        </li>

                        <li @click="change_active_link('postavke/zupanije', 'postavke' )" :class="{'active': active_link === 'postavke/zupanije'}">
                            <router-link to="/postavke/zupanije"><span class="nav-label"></span>Županije</router-link>
                        </li>

                        <li @click="change_active_link('postavke/drzave', 'postavke' )" :class="{'active': active_link === 'postavke/drzave'}">
                            <router-link to="/postavke/drzave"><span class="nav-label"></span>Države</router-link>
                        </li>

                        <li class="hr-line-dashed"></li>

                        <li @click="change_active_link('postavke/emailing-postavke', 'postavke' )" :class="{'active': active_link === 'postavke/emailing-postavke'}">
                            <router-link to="/postavke/emailing-postavke"><span class="nav-label"></span>Emailing postavke</router-link>
                        </li>

                    </ul>
                </li>


            </ul>

        </div>
    </nav>

    

</template>

<script>
    import axios from "axios";

    export default {
        name: "Navigation",
        props:[
        ],
        data(){
            return {
                active_link: 'events',
                active_link_old: 'events',
                sec_level_menu: null,
                app_owner:process.env.VUE_APP_APP_OWNER,
                app_name:process.env.VUE_APP_APP_NAME,
                user: {}
            }
        },
        methods:{
            change_active_link( active_link, sec_level_menu = null ){
                this.sec_level_menu = sec_level_menu
                this.active_link_old = this.active_link;
                this.active_link = active_link
            },
            open_menu( sec_level_menu ){
                if( this.sec_level_menu === sec_level_menu ){
                    this.active_link = this.active_link_old
                    this.sec_level_menu = null
                } else {
                    this.active_link_old = this.active_link;
                    this.active_link = sec_level_menu
                    this.sec_level_menu = sec_level_menu
                }
            }
        },
        mounted() {
            let vm = this
            axios.get( process.env.VUE_APP_API_URL + 'user-auth' )
                    .then( function ( response ) {
                        vm.user = response.data
                    } )
                    .catch( function ( error ) {
                        if(error instanceof Error) {
                            console.log( 'Error: ' . error);
                        } else {
                            console.log( 'Unexpected response: ' . error);
                        }
                    } )
        }
    }
</script>

<style scoped>

</style>