<template>
    <div  class="" >
        <div class="" style="width: 100%; padding-bottom: 5px">
            <v-date-picker
                        locale="hr"
                        icon='calendar'
                        :mode="'date'"
                        v-model="state1"
                        :masks="{input:'DD.MM.YYYY.'}"
                        :show-day-popover=false
                        :popover="{ visibility: 'click' }"
                        :input-props="{
                            class:'form-control text-dark',
                            placeholder: 'od'
                        }"
            >
                <template v-slot="{ inputValue, inputEvents }">
                    <div class="input-group">
                        <input
                                placeholder="Od"
                                class='form-control text-dark'
                                :value="inputValue"
                                v-on="inputEvents"
                        />
                        <span  class="input-group-append"><button @click="unsetFilter" type="button" class="btn btn-sm btn-dark">x</button> </span>
                    </div>
                </template>
            </v-date-picker>

        </div>

        <div class="" style="width: 100%; padding-bottom: 5px">
            <v-date-picker
                    locale="hr"
                    icon='calendar'
                    :mode="'date'"
                    v-model="state2"
                    :masks="{input:'DD.MM.YYYY.'}"
                    :show-day-popover=false
                    :popover="{ visibility: 'click' }"
                    :input-props="{
                        class:'form-control text-dark',
                        placeholder: 'do'
                    }"
            >
            <template v-slot="{ inputValue, inputEvents }">
                <div class="input-group">
                    <input
                            placeholder="Do"
                            class='form-control text-dark'
                            :value="inputValue"
                            v-on="inputEvents"
                    />
                    <span  class="input-group-append"><button @click="unsetFilter" type="button" class="btn btn-sm btn-dark">x</button> </span>
                </div>
            </template>
            </v-date-picker>

        </div>
    </div>
</template>

<script>
    import DatePicker from 'v-calendar/lib/components/date-picker.umd'
    import moment from 'moment'
    export default {
        name: "DateFilter",
        components:{
            'v-date-picker': DatePicker,
        },
        props:[
            'data',
            'name',
        ],
        data(){
            return {
                filter: {
                    value: {
                        from: '',
                        to: ''
                    }
                },
                state1 : null,
                state2 : null
            }
        },
        methods:{
            unsetFilter(){
                this.filter.value.from = null;
                this.filter.value.to = null;
                this.state1 = null;
                this.state2 = null;
            }
        },
        mounted() {

            let vm = this;
            vm.filter.value.from = vm.data.from
            if( vm.data.from )
                vm.state1 = moment( vm.data.from, 'DD.MM.YYYY.').toDate()

            vm.filter.value.to = vm.data.to
            if( vm.data.to )
                vm.state2 = moment( vm.data.to, 'DD.MM.YYYY.').toDate()
        },
        watch: {
            state1(){
                let vm = this;

                if( vm.state1 ){
                    vm.filter.value.from = moment( vm.state1, 'DD.MM.YYYY' ).format('DD.MM.YYYY.')
                    vm.$emit( 'set_filter', {
                        key: vm.name,
                        value:{
                            from:  vm.filter.value.from,
                            to: vm.filter.value.to,
                        }
                    } );
                } else {
                    vm.filter.value.from = null;
                    vm.$emit( 'set_filter', {
                        key: vm.name,
                        value:{
                            from:  vm.filter.value.from,
                            to: vm.filter.value.to,
                        }
                    } );
                }

            },
            state2(){
                let vm = this;

                if( vm.state2 ){
                    vm.filter.value.to = moment( vm.state2, 'DD.MM.YYYY' ).format('DD.MM.YYYY.')
                    vm.$emit( 'set_filter', {
                        key: vm.name,
                        value:{
                            from:  vm.filter.value.from,
                            to: vm.filter.value.to,
                        }
                    } );
                } else {
                    vm.filter.value.to = null;
                    vm.$emit( 'set_filter', {
                        key: vm.name,
                        value:{
                            from:  vm.filter.value.from,
                            to: vm.filter.value.to,
                        }
                    } );
                }

            },
        }

    }
</script>

<style scoped>
    .date-picker input{
        display: block !important;
        width: 100% !important;
    }
</style>