import Vue from "vue";
import VueRouter from "vue-router";


import Login from "../views/auth/Login.vue";
import ForgotPassword from "../views/auth/ForgotPassword.vue";
import ResetPassword from "../views/auth/ResetPassword.vue";
import Logout from "@/views/auth/Logout";

import Home from "@/views/layout/Home";

import AdministratorBase from "@/views/pages/Administratori/AdministratorBase";
import Administratori from "@/views/pages/Administratori/Administratori";
import AdministratorDetails from "@/views/pages/Administratori/AdministratorDetails";
import AdministratorEdit from "@/views/pages/Administratori/AdministratorEdit";
import AdministratorCreate from "@/views/pages/Administratori/AdministratorCreate";

import KupacBase from "../views/pages/Kupci/KupacBase";
import Kupci from "../views/pages/Kupci/Kupci";
import KupacDetails from "../views/pages/Kupci/KupacDetails";
import KupacEdit from "../views/pages/Kupci/KupacEdit";
import KupacCreate from "../views/pages/Kupci/KupacCreate";


import Knjizica15Base from "../views/pages/Kupci/Knizica15/Knjizica15Base";
import Knjizica15Create from "../views/pages/Kupci/Knizica15/Knjizica15Create";
import Knjizica15Details from "../views/pages/Kupci/Knizica15/Knjizica15Details";


import EmailingBase from "../views/pages/Emailing/EmailingBase";
import Emailing from "../views/pages/Emailing/Emailing";
import EmailingCreate from "../views/pages/Emailing/EmailingCreate";
import EmailingDetails from "../views/pages/Emailing/EmailingDetails";
import EmailingEdit from "../views/pages/Emailing/EmailingEdit";
import EmailingLogovi from "../views/pages/Emailing/EmailingLogovi/EmailingLogovi";
import EmailingTest from "../views/pages/Emailing/EmailingTest/EmailingTest";


import Ispisi from "../views/pages/Ispisi/Ispisi";

import BrendBase from "../views/pages/Postavke/Brendovi/BrendBase";
import Brendovi from "../views/pages/Postavke/Brendovi/Brendovi";
import BrendCreate from "../views/pages/Postavke/Brendovi/BrendCreate";
import BrendDetails from "../views/pages/Postavke/Brendovi/BrendDetails";
import BrendEdit from "../views/pages/Postavke/Brendovi/BrendEdit";


import DrzavaBase from "../views/pages/Postavke/Drzave/DrzavaBase";
import Drzave from "../views/pages/Postavke/Drzave/Drzave";
import DrzavaCreate from "../views/pages/Postavke/Drzave/DrzavaCreate";
import DrzavaDetails from "../views/pages/Postavke/Drzave/DrzavaDetails";
import DrzavaEdit from "../views/pages/Postavke/Drzave/DrzavaEdit";


import PoslovnicaBase from "../views/pages/Postavke/Poslovnice/PoslovnicaBase";
import Poslovnice from "../views/pages/Postavke/Poslovnice/Poslovnice";
import PoslovnicaCreate from "../views/pages/Postavke/Poslovnice/PoslovnicaCreate";
import PoslovnicaDetails from "../views/pages/Postavke/Poslovnice/PoslovnicaDetails";
import PoslovnicaEdit from "../views/pages/Postavke/Poslovnice/PoslovnicaEdit";


import PostaBase from "../views/pages/Postavke/Poste/PostaBase";
import Poste from "../views/pages/Postavke/Poste/Poste";
import PostaCreate from "../views/pages/Postavke/Poste/PostaCreate";
import PostaDetails from "../views/pages/Postavke/Poste/PostaDetails";
import PostaEdit from "../views/pages/Postavke/Poste/PostaEdit";


import ZupanijaBase from "../views/pages/Postavke/Zupanije/ZupanijaBase";
import Zupanije from "../views/pages/Postavke/Zupanije/Zupanije";
import ZupanijaCreate from "../views/pages/Postavke/Zupanije/ZupanijaCreate";
import ZupanijaDetails from "../views/pages/Postavke/Zupanije/ZupanijaDetails";
import ZupanijaEdit from "../views/pages/Postavke/Zupanije/ZupanijaEdit";


import SavjetnicaBase from "../views/pages/Savjetnice/SavjetnicaBase";
import Savjetnice from "../views/pages/Savjetnice/Savjetnice";
import SavjetnicaCreate from "../views/pages/Savjetnice/SavjetnicaCreate";
import SavjetnicaDetails from "../views/pages/Savjetnice/SavjetnicaDetails";
import SavjetnicaEdit from "../views/pages/Savjetnice/SavjetnicaEdit";


import EmailingSettingsBase from "../views/pages/Postavke/EmailingSettings/EmailingSettingsBase";
import EmailingSettings from "../views/pages/Postavke/EmailingSettings/EmailingSettings";
import EmailingSettingsCreate from "../views/pages/Postavke/EmailingSettings/EmailingSettingsCreate";
import EmailingSettingsDetails from "../views/pages/Postavke/EmailingSettings/EmailingSettingsDetails";
import EmailingSettingsEdit from "../views/pages/Postavke/EmailingSettings/EmailingSettingsEdit";


import PovijestPromjena from "../views/pages/Kupci/PovijestPromjena/PovijestPromjena";


import Email from "../views/emails/Email";
import EmailPotvrda from "../views/emails/EmailPotvrda.vue";


import PogodnostBase from "../views/pages/Pogodnosti/PogodnostBase";
import Pogodnosti from "../views/pages/Pogodnosti/Pogodnosti";
import PogodnostCreate from "../views/pages/Pogodnosti/PogodnostCreate";
import PogodnostDetails from "../views/pages/Pogodnosti/PogodnostDetails";
import PogodnostEdit from "../views/pages/Pogodnosti/PogodnostEdit";


import KategorijaKupacaBase from "../views/pages/KategorijeKupaca/KategorijaKupacaBase";
import KategorijeKupaca from "../views/pages/KategorijeKupaca/KategorijeKupaca";
import KategorijaKupacaCreate from "../views/pages/KategorijeKupaca/KategorijaKupacaCreate";
import KategorijaKupacaDetails from "../views/pages/KategorijeKupaca/KategorijaKupacaDetails";
import KategorijaKupacaEdit from "../views/pages/KategorijeKupaca/KategorijaKupacaEdit";


import Kategorija2Base from "../views/pages/Kategorije2/Kategorija2Base";
import Kategorije2 from "../views/pages/Kategorije2/Kategorije2";
import Kategorija2Create from "../views/pages/Kategorije2/Kategorija2Create";
import Kategorija2Details from "../views/pages/Kategorije2/Kategorija2Details";
import Kategorija2Edit from "../views/pages/Kategorije2/Kategorija2Edit";


import GrupaKupacaBase from "../views/pages/GrupeKupaca/GrupaKupacaBase"
import GrupeKupaca from "../views/pages/GrupeKupaca/GrupeKupaca";
import GrupaKupacaCreate from "../views/pages/GrupeKupaca/GrupaKupacaCreate";
import GrupaKupacaDetails from "../views/pages/GrupeKupaca/GrupaKupacaDetails";
import GrupaKupacaEdit from "../views/pages/GrupeKupaca/GrupaKupacaEdit";


import KupacGrupaBase from "../views/pages/Kupci/Grupe/KupacGrupaBase";
import KupacGrupe from "../views/pages/Kupci/Grupe/KupacGrupe";
import KupacGrupaCreate from "../views/pages/Kupci/Grupe/KupacGrupaCreate";
import KupacGrupaDetails from "../views/pages/Kupci/Grupe/KupacGrupaDetails";
import KupacGrupaEdit from "../views/pages/Kupci/Grupe/KupacGrupaEdit";


import KupacPogodnostBase from "../views/pages/Kupci/Pogodnosti/KupacPogodnostBase";
import KupacPogodnosti from "../views/pages/Kupci/Pogodnosti/KupacPogodnosti";
import KupacPogodnostCreate from "../views/pages/Kupci/Pogodnosti/KupacPogodnostCreate";
import KupacPogodnostDetails from "../views/pages/Kupci/Pogodnosti/KupacPogodnostDetails";
import KupacPogodnostEdit from "../views/pages/Kupci/Pogodnosti/KupacPogodnostEdit";


import KupacKategorija2Base from "../views/pages/Kupci/Kategorije2/KupacKategorija2Base";
import KupacKategorije2 from "../views/pages/Kupci/Kategorije2/KupacKategorije2";
import KupacKategorija2Create from "../views/pages/Kupci/Kategorije2/KupacKategorija2Create";
import KupacKategorija2Details from "../views/pages/Kupci/Kategorije2/KupacKategorija2Details";
import KupacKategorija2Edit from "../views/pages/Kupci/Kategorije2/KupacKategorija2Edit";


import PogodnostKategorija2Base from "../views/pages/Pogodnosti/PogodnostKategorija2/PogodnostKategorija2Base";
import PogodnostKategorije2 from "../views/pages/Pogodnosti/PogodnostKategorija2/PogodnostKategorije2";
import PogodnostKategorija2Create from "../views/pages/Pogodnosti/PogodnostKategorija2/PogodnostKategorija2Create";
import PogodnostKategorija2Details from "../views/pages/Pogodnosti/PogodnostKategorija2/PogodnostKategorija2Details";
import PogodnostKategorija2Edit from "../views/pages/Pogodnosti/PogodnostKategorija2/PogodnostKategorija2Edit";
import Izvjestaji from "../views/pages/Izvjestaji/Izvjestaji";




Vue.use(VueRouter);

const guest = (to, from, next) => {
  if (!localStorage.getItem("access_token")) {
    return next();
  } else {
    return next("/");
  }
};
const auth = (to, from, next) => {
  if (localStorage.getItem("access_token")) {
    return next();
  } else {
    return next("/login");
  }
};

const routes = [

  {
    path: "/login",
    name: "Login",
    beforeEnter: guest,
    component: Login
  },
  {
    path: "/forgot-password",
    beforeEnter: guest,
    component: ForgotPassword
  },
  {
    path: "/reset-password/:token",
    beforeEnter: guest,
    component: ResetPassword
  },
  {
    path: "/logout",
    beforeEnter: auth,
    component: Logout
  },
  {
    path: '/email/view/:id/:kupac_id',
    component: Email
  },
  {
    path: '/email/potvrda/:token',
    component: EmailPotvrda
  },
  {
    path: "/",
    beforeEnter: auth,
    component: Home,
    children: [
      {
        path: "/",
        beforeEnter: auth,
        component: KupacBase,
        children: [
          {
            path: '/',
            beforeEnter: auth,
            component: Kupci
          }
        ]
      },
      {
        path: "/pogodnosti",
        component:PogodnostBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: Pogodnosti,
          },
          {
            path: 'create',
            beforeEnter: auth,
            component: PogodnostCreate,
          },
          {
            path: ':pogodnost_id',
            beforeEnter: auth,
            component: PogodnostDetails,
          },
          {
            path: ':pogodnost_id/edit',
            beforeEnter: auth,
            component: PogodnostEdit,
          },
          {
            path: ":pogodnost_id/kategorije2",
            component: PogodnostKategorija2Base,
            children: [
              {
                path: '',
                beforeEnter: auth,
                component: PogodnostKategorije2,
              },
              {
                path: 'create',
                beforeEnter: auth,
                component: PogodnostKategorija2Create,
              },
              {
                path: ':pogodnost_kategorija2_id',
                beforeEnter: auth,
                component: PogodnostKategorija2Details,
              },
              {
                path: ':pogodnost_kategorija2_id/edit',
                beforeEnter: auth,
                component: PogodnostKategorija2Edit,
              },
            ]
          },
        ]
      },
      {
        path: "/kategorije-kupaca",
        component:KategorijaKupacaBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: KategorijeKupaca,
          },
          {
            path: 'create',
            beforeEnter: auth,
            component: KategorijaKupacaCreate,
          },
          {
            path: ':kategorija_kupca_id',
            beforeEnter: auth,
            component: KategorijaKupacaDetails,
          },
          {
            path: ':kategorija_kupca_id/edit',
            beforeEnter: auth,
            component: KategorijaKupacaEdit,
          },
        ]
      },
      {
        path: "/kategorije2",
        component:Kategorija2Base,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: Kategorije2,
          },
          {
            path: 'create',
            beforeEnter: auth,
            component: Kategorija2Create,
          },
          {
            path: ':kategorija2_id',
            beforeEnter: auth,
            component: Kategorija2Details,
          },
          {
            path: ':kategorija2_id/edit',
            beforeEnter: auth,
            component: Kategorija2Edit,
          },
        ]
      },
      {
        path: "/grupe-kupaca",
        component: GrupaKupacaBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: GrupeKupaca,
          },
          {
            path: 'create',
            beforeEnter: auth,
            component: GrupaKupacaCreate,
          },
          {
            path: ':grupa_kupca_id',
            beforeEnter: auth,
            component: GrupaKupacaDetails,
          },
          {
            path: ':grupa_kupca_id/edit',
            beforeEnter: auth,
            component: GrupaKupacaEdit,
          },
        ]
      },
      {
        path: "/savjetnice",
        component:SavjetnicaBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: Savjetnice,
          },
          {
            path: 'create',
            beforeEnter: auth,
            component: SavjetnicaCreate,
          },
          {
            path: ':savjetnica_id',
            beforeEnter: auth,
            component: SavjetnicaDetails,
          },
          {
            path: ':savjetnica_id/edit',
            beforeEnter: auth,
            component: SavjetnicaEdit,
          },
        ]
      },
      {
        path: "/administratori",
        component: AdministratorBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: Administratori,
          },
          {
            path: 'create',
            beforeEnter: auth,
            component: AdministratorCreate,
          },
          {
            path: ':admin_id',
            beforeEnter: auth,
            component: AdministratorDetails,
          },
          {
            path: ':admin_id/edit',
            beforeEnter: auth,
            component: AdministratorEdit,
          },
        ]
      },
      {
        path: "emailing",
        component: EmailingBase,
        beforeEnter: auth,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: Emailing,
          },
          {
            path: 'create',
            beforeEnter: auth,
            component: EmailingCreate,
          },
          {
            path: ':email_id',
            beforeEnter: auth,
            component: EmailingDetails,
          },
          {
            path: ':email_id/edit',
            beforeEnter: auth,
            component: EmailingEdit,
          },
          {
            path: ':email_id/log',
            beforeEnter: auth,
            component: EmailingLogovi,
          },
          {
            path: ':email_id/test',
            beforeEnter: auth,
            component: EmailingTest
          }
        ]
      },
      {
        path: "/kupci",
        component: KupacBase,
        beforeEnter: auth,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: Kupci,
          },
          {
            path: 'create',
            beforeEnter: auth,
            component: KupacCreate,
          },
          {
            path: ':kupac_id',
            beforeEnter: auth,
            component: KupacDetails,
          },
          {
            path: ':kupac_id/knjizica-15',
            beforeEnter: auth,
            component: Knjizica15Base,
            children: [
              {
                path: 'create',
                beforeEnter: auth,
                component: Knjizica15Create
              },
              {
                path: ':knjizica_15_id',
                beforeEnter: auth,
                component: Knjizica15Details
              }
            ]
          },
          {
            path: ':kupac_id/edit',
            beforeEnter: auth,
            component: KupacEdit,
          },
          {
            path: ':kupac_id/povijest-promjena',
            beforeEnter: auth,
            component: PovijestPromjena
          },
          {
            path: ":kupac_id/grupe",
            component: KupacGrupaBase,
            children: [
              {
                path: '',
                beforeEnter: auth,
                component: KupacGrupe,
              },
              {
                path: 'create',
                beforeEnter: auth,
                component: KupacGrupaCreate,
              },
              {
                path: ':kupac_grupa_id',
                beforeEnter: auth,
                component: KupacGrupaDetails,
              },
              {
                path: ':kupac_grupa_id/edit',
                beforeEnter: auth,
                component: KupacGrupaEdit,
              },
            ]
          },
          {
            path: ":kupac_id/kategorije2",
            component: KupacKategorija2Base,
            children: [
              {
                path: '',
                beforeEnter: auth,
                component: KupacKategorije2,
              },
              {
                path: 'create',
                beforeEnter: auth,
                component: KupacKategorija2Create,
              },
              {
                path: ':kupac_kategorija_id',
                beforeEnter: auth,
                component: KupacKategorija2Details,
              },
              {
                path: ':kupac_kategorija_id/edit',
                beforeEnter: auth,
                component: KupacKategorija2Edit,
              },
            ]
          },
          {
            path: ":kupac_id/pogodnosti",
            component: KupacPogodnostBase,
            children: [
              {
                path: '',
                beforeEnter: auth,
                component: KupacPogodnosti,
              },
              {
                path: 'create',
                beforeEnter: auth,
                component: KupacPogodnostCreate,
              },
              {
                path: ':kupac_pogodnost_id',
                beforeEnter: auth,
                component: KupacPogodnostDetails,
              },
              {
                path: ':kupac_pogodnost_id/edit',
                beforeEnter: auth,
                component: KupacPogodnostEdit,
              },
            ]
          },
        ]
      },
      {
        path: "ispisi",
        beforeEnter: auth,
        component: Ispisi
      },
      {
        path: "izvjestaji",
        beforeEnter: auth,
        component: Izvjestaji
      },
      {
        path: 'postavke/brendovi',
        component: BrendBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: Brendovi
          },
          {
            path: 'create',
            beforeEnter: auth,
            component: BrendCreate
          },
          {
            path: ':brend_id',
            beforeEnter: auth,
            component: BrendDetails
          },
          {
            path: ':brend_id/edit',
            beforeEnter: auth,
            component: BrendEdit
          },
        ]
      },
      {
        path: 'postavke/drzave',
        component: DrzavaBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: Drzave
          },
          {
            path: 'create',
            beforeEnter: auth,
            component: DrzavaCreate
          },
          {
            path: ':drzava_id',
            beforeEnter: auth,
            component: DrzavaDetails
          },
          {
            path: ':drzava_id/edit',
            beforeEnter: auth,
            component: DrzavaEdit
          },
        ]
      },
      {
        path: 'postavke/poslovnice',
        component: PoslovnicaBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: Poslovnice
          },
          {
            path: 'create',
            beforeEnter: auth,
            component: PoslovnicaCreate
          },
          {
            path: ':poslovnica_id',
            beforeEnter: auth,
            component: PoslovnicaDetails
          },
          {
            path: ':poslovnica_id/edit',
            beforeEnter: auth,
            component: PoslovnicaEdit
          },
        ]
      },
      {
        path: 'postavke/poste',
        component: PostaBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: Poste
          },
          {
            path: 'create',
            beforeEnter: auth,
            component: PostaCreate
          },
          {
            path: ':posta_id',
            beforeEnter: auth,
            component: PostaDetails
          },
          {
            path: ':posta_id/edit',
            beforeEnter: auth,
            component: PostaEdit
          },
        ]
      },
      {
        path: 'postavke/zupanije',
        component: ZupanijaBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: Zupanije
          },
          {
            path: 'create',
            beforeEnter: auth,
            component: ZupanijaCreate
          },
          {
            path: ':zupanija_id',
            beforeEnter: auth,
            component: ZupanijaDetails
          },
          {
            path: ':zupanija_id/edit',
            beforeEnter: auth,
            component: ZupanijaEdit
          },
        ]
      },
      {
        path: 'postavke/emailing-postavke',
        component: EmailingSettingsBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: EmailingSettings
          },
          {
            path: 'create',
            beforeEnter: auth,
            component: EmailingSettingsCreate
          },
          {
            path: ':emailing_postavke_id',
            beforeEnter: auth,
            component: EmailingSettingsDetails
          },
          {
            path: ':emailing_postavke_id/edit',
            beforeEnter: auth,
            component: EmailingSettingsEdit
          },
        ]
      }
    ]
  },

];

const router = new VueRouter({
  mode: "history",
  base: '/',
  routes
});

export default router;
