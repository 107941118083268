<template>
    <div>
        <breadcrumbs
                :title="'Žopanije'"
                :breadcrumbs="breadcrumbs"
        >
        </breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Županija: {{ naziv }}</h5>
            </div>
            <div class="ibox-content">
                <zupanija-forma
                        :disabled="false"
                        :id="id"
                        :modal="false"
                        :action="'edit'"
                        @returnData="setData"
                >
                </zupanija-forma>
            </div>
        </div>
    </div>

</template>

<script>
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import ZupanijaForma from "./ZupanijaForma";
export default {
    name: "ZupanijaEdit",
    components: {ZupanijaForma, Breadcrumbs},
    data(){
        return {
            id: this.$route.params.zupanija_id,
            breadcrumbs: [],
            naziv: ''
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/postavke/zuapnije',
                    name: 'Županije'
                },
                {
                    path: ('/postavke/zupanije/' + this.id ),
                    name: ('Županija: ' + this.naziv )
                },
                {
                    path: ( '/postavke/zupanije/' + this.id + '/edit' ),
                    name: ( 'Uredi županiju: ' + this.naziv )
                }
            ]
        },
        setData( data ){
            this.naziv = data.naziv ? data.naziv : ''
            this.setBreadcrumbs()
        }
    }
}
</script>

<style scoped>

</style>