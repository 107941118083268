<template>
    <div style="margin-bottom: 50px">
        <breadcrumbs
                :title="'Administratori'"
                :breadcrumbs="breadcrumbs"
        >
        </breadcrumbs>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Novi E-mail</h5>
            </div>
            <div class="ibox-content">
                <emailing-forma
                        :disabled="false"
                        :id="null"
                        :modal="false"
                        :action="'create'"
                        :user="user"
                ></emailing-forma>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import EmailingForma from "./EmailingForma";
export default {
    name: "EmailingCreate",
    components: {EmailingForma, Breadcrumbs},
    data(){
        return {
            breadcrumbs: []
        }
    },
    methods: {
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/emailing',
                    name: 'Emailing'
                },
                {
                    path: '/emailing/create',
                    name: 'Novi Email'
                }
            ]
        }
    }
}
</script>

<style scoped>

</style>