import { render, staticRenderFns } from "./PoslovnicaBase.vue?vue&type=template&id=2059a3cd&scoped=true&"
import script from "./PoslovnicaBase.vue?vue&type=script&lang=js&"
export * from "./PoslovnicaBase.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2059a3cd",
  null
  
)

export default component.exports