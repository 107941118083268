<template>
    <div>
        <pogodnosti-navigation
                :id="pogodnost_id"
                :breadcrumbs="breadcrumbs"
        >
        </pogodnosti-navigation>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Kategorija: {{ naziv }}</h5>
            </div>
            <div class="ibox-content">
                <pogodnost-kategorija2-forma
                        :disabled="false"
                        :id="id"
                        :modal="false"
                        :action="'edit'"
                        @returnData="setData"
                >
                </pogodnost-kategorija2-forma>
            </div>
        </div>
    </div>
</template>

<script>
import PogodnostiNavigation from "../PogodnostiNavigation";
import PogodnostKategorija2Forma from "./PogodnostKategorija2Forma";
export default {
    name: "PogodnostKategorija2Edit",
    components: {PogodnostKategorija2Forma, PogodnostiNavigation},
    data(){
        return {
            breadcrumbs: [],
            id: this.$route.params.pogodnost_kategorija2_id,
            pogodnost_id: this.$route.params.pogodnost_id,
            naziv: ''
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/pogodnosti/' + this.pogodnost_id + '/kategorije2',
                    name: 'Kategorije'
                },
                {
                    path: '/pogodnosti/' + this.pogodnost_id + '/kategorije2/' + this.id,
                    name: 'Kategorija: ' + this.naziv
                },
                {
                    path: '/pogodnosti/' + this.pogodnost_id + '/kategorije2/' + this.id + '/edit',
                    name: 'Uredi kategoriju: ' + this.naziv
                }
            ]
        },
        setData( data ){
            this.naziv = data.kategorija2 ? data.kategorija2.naziv : ''
            this.setBreadcrumbs()
        },
    }
}
</script>

<style scoped>

</style>