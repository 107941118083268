<template>
    <div class=" col-md-12 dataTables_paginate paging_simple_numbers">
        <ul class="pagination">
            <li v-if="first" class="paginate_button page-item" >
                <button class="btn btn-light" @click="setPage(first)">Početak</button>
            </li>
            <li v-if="back" class="paginate_button page-item" >
                <button class="btn btn-light" @click="setPage(back)">Nazad</button>
            </li>

            <li v-if="leftDot" class="paginate_button page-item" >
                <button class="btn btn-light disabled">...</button>
            </li>

            <li
                    v-for="i in middle"
                    :key="i"
                    class="paginate_button page-item previous disabled"
                >
                <button
                        :class="{'btn':true, 'btn-primary disabled': i === current_page, 'btn btn-light': i === current_page }"
                        :disabled="i === current_page"
                        @click="setPage(i)"
                    >
                    {{ i }}
                </button>
            </li>

            <li v-if="rightDot" class="paginate_button page-item">
                <button class="btn btn-light disabled">...</button>
            </li>
            <li v-if="next" class="paginate_button" >
                <button class="btn btn-light" @click="setPage(next)">Naprijed</button>
            </li>
            <li v-if="end" class="paginate_button">
                <button class="btn btn-light" @click="setPage(end)">Kraj</button>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "Pagination",
    props:{
        pagination: {
            type: Object,
            validator: function ( props ){
                return typeof props.number_of_pages === 'number'
                    && typeof props.current_page === 'number'
            }
        }
    },
    data(){
        return {
            current_page: 1,
            number_of_pages: 0,

            first: null,
            back: null,
            leftDot : false,
            middle: null,
            rightDot: false,
            next: null,
            end:null,
        }
    },
    methods:{
        setPage( no ){
            this.$emit( 'setPage', no )
        },

        init(){
            this.current_page = this.$props.pagination.current_page
            this.number_of_pages = this.$props.pagination.number_of_pages

            this.setup()
        },

        setup(){
            if( this.current_page > 1 ){
                this.first = 1
                this.back = this.current_page - 1
            } else {
                this.first = 0
                this.back = 0
            }

            if( this.current_page > 5 ) {
                this.leftDot = true;
            } else {
                this.rightDot = true;
            }

            this.middle = []
            for ( let i = 1; i <= this.number_of_pages; i++) {
                if( ( ( Math.abs(i - this.current_page ) ) <= 5 ) && ( this.number_of_pages > 1 ) ){
                    this.middle.push( i )
                }
            }

            this.rightDot = (this.number_of_pages - this.current_page) > 5 && this.number_of_pages > 1;

            if( this.current_page < this.number_of_pages){
                this.next = this.current_page + 1
            } else {
                this.next = 0
            }

            if( this.current_page < this.number_of_pages){
                this.end = this.number_of_pages
            } else {
                this.end = 0
            }
        }

    },
    mounted() {
        this.init()
    },
    watch:{
        pagination:{
            handler: function (){
                this.init()
            },
            deep: true
        },
    }

}
</script>

<style scoped>

</style>