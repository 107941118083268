import { render, staticRenderFns } from "./PogodnostCreate.vue?vue&type=template&id=64ec49f4&scoped=true&"
import script from "./PogodnostCreate.vue?vue&type=script&lang=js&"
export * from "./PogodnostCreate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64ec49f4",
  null
  
)

export default component.exports