<template>
    <div>
        <button
                :class="custom_class"
                @click.stop="buttonClick()"
        >
            {{ title }}
        </button>
    </div>
</template>

<script>
export default {
name: "ButtonCell",
    props:['row', 'col'],
    data(){
        return {
            title: this.col.config.title,
            custom_class: this.col.config.type ? ( 'btn btn-' + this.col.config.type ) : 'btn btn-success'
        }
    },
    methods:{
        buttonClick(){
            this.$emit( 'buttonClick', this.col, this.row )
        },
    }
}
</script>

<style scoped>

</style>