<template>
    <div>
        <kupci-navigation
                :id="kupac_id"
                :breadcrumbs="breadcrumbs"
        >
        </kupci-navigation>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Grupa: {{ naziv }}</h5>
            </div>
            <div class="ibox-content">
                <kupac-grupa-forma
                        :disabled="true"
                        :id="id"
                        :modal="false"
                        :action="'show'"
                        @returnData="setData"
                >
                </kupac-grupa-forma>
            </div>
        </div>
    </div>
</template>

<script>
import KupciNavigation from "../KupciNavigation";
import KupacGrupaForma from "./KupacGrupaForma";
export default {
    name: "KupacGrupaDetails",
    components: {KupacGrupaForma, KupciNavigation},
    data(){
        return {
            breadcrumbs: [],
            id:this.$route.params.kupac_grupa_id,
            kupac_id: this.$route.params.kupac_id,
            skup: {},
            naziv: ''
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/kupci/' + this.kupac_id + '/grupe',
                    name: 'Grupe'
                },
                {
                    path: '/kupci/' + this.kupac_id + '/grupe/' + this.id,
                    name: 'Grupa: ' + this.naziv
                }
            ]
        },
        setData( data ){
            this.naziv = data.naziv
            this.setBreadcrumbs()
        },

    },
    mounted() {
    }
}
</script>

<style scoped>

</style>