<template>
    <div>
        <breadcrumbs
                :title="'Kategorije kupaca'"
                :breadcrumbs="breadcrumbs"
        ></breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Kategorije kupaca</h5><br>
                <button
                        type="button"
                        class="btn btn-lg btn-primary"
                        @click.prevent="create"
                >
                    Novi unos
                </button>
            </div>
            <div class="ibox-content">
                <datatables
                        v-if="show_datatable"
                        :url="datatables_url"
                        :settings="datatables.settings"
                        :title="datatables.title"
                        @rowClick="rowClick"
                >
                </datatables>
            </div>
        </div>
    </div>

</template>

<script>
import Datatables from "@/components/datatable/Datatables";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import axios from "axios";
export default {
    name: "Kategorije2",
    components: {Breadcrumbs, Datatables},
    props: ['user'],
    data(){
        return {
            show_datatable : false,
            datatables_url: process.env.VUE_APP_API_URL + 'kategorija2',
            datatables: {},
            breadcrumbs: [],
        }
    },

    methods:{
        rowClick( row ){
            this.$router.push( { path: '/kategorije2/' + row.id } )
        },

        create(){
            this.$router.push( { path: '/kategorije2/create' } )
        },

        setDatatable(){
            let vm = this;

            let brendovi_filter = []
            let brendovi_filter_ids = []
            if( vm.user.user_brendovi ){
                vm.user.user_brendovi.forEach( function ( item ) {
                    brendovi_filter.push( { key: item.brend.id, value:item.brend.naziv } )
                    brendovi_filter_ids.push( item.brend.id )
                } )
            }


            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'datum',
                            'title': 'Datum',
                            'column_visibility_group': {
                                id: 'kategorija',
                            },
                            'filter': {
                                'type': 'date',
                            }
                        },
                        {
                            'id': 'naziv',
                            'title': 'Naziv',
                            'column_visibility_group': {
                                id: 'kategorija',
                            }
                        },
                        {
                            'id': 'opis',
                            'title': 'Opis',
                            'column_visibility_group': {
                                id: 'kategorija',
                            }
                        },
                        {
                            'id': 'brend_id',
                            'title': 'Brend',
                            'type': function( row ){
                                return row.brend ? row.brend.naziv : ''
                            },
                            'filter': {
                                'type': 'select',
                                'search': function ( ){
                                    let component = this;

                                    axios.get( process.env.VUE_APP_API_URL +  'brend', {
                                        params: {
                                            filters: {
                                                id: brendovi_filter_ids
                                            }
                                        }
                                    } )
                                            .then( function ( response ) {
                                                let tmp = [];

                                                response.data.data.forEach( function ( item ) {
                                                    tmp.push({
                                                        key: item.id,
                                                        value: item.naziv
                                                    } );
                                                } );
                                                component.$data.tmp = tmp;
                                            } )
                                            .catch( function ( error ) {
                                                if(error instanceof Error) {
                                                    console.log( 'Error: ' . error);
                                                } else {
                                                    console.log( 'Unexpected response: ' . error);
                                                }
                                            } )
                                },
                            },
                            'options' : brendovi_filter_ids,
                            'column_visibility_group': {
                                id: 'kategorija',
                            },
                        },
                        {
                            'id': 'aktivna',
                            'title': 'Aktivna',
                            'type': function( row ){
                                return row.aktivna ? 'Da' : 'Ne'
                            },
                            'filter': {
                                'type': 'select',
                                'search': [
                                    {
                                        key: '1',
                                        value: 'Da'
                                    },
                                    {
                                        key: '0',
                                        value: 'Ne'
                                    }
                                ],
                                'value': null
                            },
                            'column_visibility_group': {
                                id: 'kategorija',
                            },
                        },
                        {
                            'id': 'pocetna',
                            'title': 'Početna',
                            'type': function( row ){
                                return row.pocetna ? 'Da' : 'Ne'
                            },
                            'filter': {
                                'type': 'select',
                                'search': [
                                    {
                                        key: '1',
                                        value: 'Da'
                                    },
                                    {
                                        key: '0',
                                        value: 'Ne'
                                    }
                                ],
                                'value': null
                            },
                            'column_visibility_group': {
                                id: 'kategorija',
                            },
                        },

                    ],
                    column_visibility_groups:{
                        'kategorija' : 'Kategorije kupaca'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Kategorije kupaca',
            }
            vm.show_datatable = true;
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/kategorije2',
                    name: 'Kategorije'
                }
            ];
        }
    },
    mounted() {
        this.setDatatable()
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>