<template>
    <div class="form-group" style="height: 20px">
        <multiselect
                track-by="key"
                label="value"
                placeholder=""
                :options="[{key:'1', value:'1'},{key:'2', value:'2'}]"
                :searchable="true"
                :allow-empty="true"
                :showLabels="false"
                :multiple="false"
                :close-on-select="true"
                :disabled="false"
                :style="'height: 20px'"
        >
        </multiselect>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css';
export default {
    name: "SelectCell",
    components:{ Multiselect },
}
</script>

<style scoped>

</style>