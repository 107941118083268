import { render, staticRenderFns } from "./EmailingDetails.vue?vue&type=template&id=e8d6eb7e&scoped=true&"
import script from "./EmailingDetails.vue?vue&type=script&lang=js&"
export * from "./EmailingDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8d6eb7e",
  null
  
)

export default component.exports