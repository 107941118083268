<template>
    <div>
        <breadcrumbs
                :title="'Savjetnice'"
                :breadcrumbs="breadcrumbs"
        >
        </breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Nova savjetnica</h5><br>
            </div>
            <div class="ibox-content">
                <savjetnica-forma
                        :disabled="false"
                        :id="null"
                        :modal = false
                        :action="'create'"
                >
                </savjetnica-forma>
            </div>
        </div>
    </div>

</template>

<script>
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import SavjetnicaForma from "./SavjetnicaForma";
export default {
    name: "SavjetnicaCreate",
    components: {SavjetnicaForma, Breadcrumbs},
    data(){
        return {
            breadcrumbs: []
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/savjetnice',
                    name: 'Savjetnice'
                },
                {
                    path: '/savjetnice/create',
                    name: 'Nova savjetnica'
                }
            ]
        }
    },
    mounted() {
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>