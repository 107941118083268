<template>
    <div>
        <kupci-navigation
                :id="kupac_id"
                :breadcrumbs="breadcrumbs"
                :user="user"
        ></kupci-navigation>

        <div class="ibox ">
            <div class="ibox-title">
                <h5>Knjižica 15 godina </h5>
            </div>
            <div class="ibox-content">
                <knjizica15-forma
                        :disabled="false"
                        :id="null"
                        :modal="false"
                        :action="'create'"
                        :user="user"
                >
                </knjizica15-forma>
            </div>
        </div>
    </div>
</template>

<script>
import KupciNavigation from "../KupciNavigation";
import Knjizica15Forma from "./Knjizica15Forma";
export default {
    name: "Knjizica15Create",
    components: {Knjizica15Forma, KupciNavigation},
    props:['user'],
    data(){
        return {
            kupac_id: this.$route.params.kupac_id,
            breadcrumbs: [],
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/kupci/' + this.kupac_id + '/knjizica-15/create',
                    name: 'Unesi knjižicu 15 godina'
                }
            ]
        },
    },
    mounted() {
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>