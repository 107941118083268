<template>
    <div>
        <breadcrumbs
                :title="'Pošte'"
                :breadcrumbs="breadcrumbs"
        >
        </breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Pošta: {{ naziv }}</h5>
            </div>
            <div class="ibox-content">
                <posta-forma
                        :disabled="true"
                        :id="id"
                        :modal="false"
                        :action="'show'"
                        @returnData="setData"
                >
                </posta-forma>
            </div>
        </div>
    </div>

</template>

<script>
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import PostaForma from "./PostaForma";
export default {
    name: "PostaDetails",
    components: {PostaForma, Breadcrumbs},
    data(){
        return {
            id: this.$route.params.posta_id,
            breadcrumbs: [],
            naziv: ''
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/postavke/poste',
                    name: 'Pošte'
                },
                {
                    path: ('/postavke/posta/' + this.id ),
                    name: ('Pošta: ' + this.naziv )
                }
            ]
        },
        setData( data ){
            this.naziv = data.naziv ? data.naziv : ''
            this.setBreadcrumbs()
        }
    }
}
</script>

<style scoped>

</style>