<template>
    <div><router-view></router-view></div>
</template>

<script>
export default {
    name: "KupacGrupaBase"
}
</script>

<style scoped>

</style>