<template>
    <div>
        <breadcrumbs
            :title="'Administratori'"
            :breadcrumbs="breadcrumbs"
        ></breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Administratori</h5><br>
                <button
                        v-if="( [2,3].includes( this.user.permission.id ) )"
                        type="button"
                        class="btn btn-lg btn-primary"
                        @click.prevent="create"
                >
                    Novi unos
                </button>
            </div>
            <div class="ibox-content">
                <datatables
                        v-if="show_datatable"
                        :url="datatables_url"
                        :settings="datatables.settings"
                        :title="datatables.title"
                        @rowClick="rowClick"
                >
                </datatables>
            </div>
        </div>
    </div>

</template>

<script>
import Datatables from "@/components/datatable/Datatables";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import toastr from "toastr"
import axios from "axios";

export default {
name: "Administratori",
    components: {Breadcrumbs, Datatables},
    props: ['user'],
    data(){
        return {
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL +  'user',
            datatables: {},
            breadcrumbs: [],
        }

    },
    methods:{
        rowClick( row ){
            if( [2,3].includes( this.user.permission.id ) )
                this.$router.push( { path: '/administratori/' + row.id } )
            else
                toastr.error( 'Nemate ovlasti!')

        },

        create(){
            this.$router.push( { path: '/administratori/create' } )
        },

        setDatatable() {

            let vm = this;

            let permission_search_value = null
            if( vm.user.permission.id === 1 ){
                permission_search_value = [1]
            } else if( vm.user.permission.id === 2 ){
                permission_search_value = [1,2]
            } else {
                permission_search_value = [1,2,3]
            }

            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'username',
                            'title': 'Username',
                            'column_visibility_group': {
                                id: 'user',
                            }
                        },
                        {
                            'id': 'email',
                            'title': 'E-mail',
                            'column_visibility_group': {
                                id: 'user',
                            }
                        },
                        {
                            'id': 'permission_id',
                            'title': 'Ovlasti',
                            'type': function ( row ){
                                return row.permission ? row.permission.name : ''
                            },
                            'filter': {
                                'type': 'select',
                                'search': function ( ){
                                    let component = this;

                                    let filters = {}

                                    if( vm.user.permission.id === 1 ){
                                        filters['id'] = [1]
                                    } else if( vm.user.permission.id === 2 ){
                                        filters['id'] = [1,2]
                                    }

                                    axios.get( process.env.VUE_APP_API_URL +  'user-permission', {
                                        params: {
                                            filters: filters
                                        }
                                    })
                                            .then( function ( response ) {
                                                let tmp = [];
                                                response.data.data.forEach( function ( item ) {
                                                    tmp.push({
                                                        key: item.id,
                                                        value: item.name
                                                    } );
                                                } );
                                                component.$data.tmp = tmp;
                                            } )
                                            .catch( function ( error ) {
                                                if(error instanceof Error) {
                                                    console.log( 'Error: ' . error);
                                                } else {
                                                    console.log( 'Unexpected response: ' . error);
                                                }
                                            } )
                                },
                                'value': permission_search_value
                            },
                            'column_visibility_group': {
                                id: 'user',
                            }
                        },
                        {
                            'id': 'brend',
                            'title': 'Brendovi',
                            'searchable': false,
                            'type': function( row ){
                                let ret = ''
                                row.user_brendovi.forEach( function( item ) {
                                    ret += item.brend.naziv + '; '
                                } )
                                return ret
                            },
                            'column_visibility_group': {
                                id: 'user',
                            }
                        },

                    ],
                    column_visibility_groups:{
                        'user' : 'Administratori'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Administratori',
            }
            vm.show_datatable = true;
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/administratori',
                    name: 'Administratori'
                }
            ];
        }
    },
    mounted() {
        this.setDatatable()
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>