<template>
    <div class="col-lg-2" id="osobe_info" role="status" aria-live="polite">
        Prikazano {{ showed_results.from }} do {{ showed_results.to }} od {{ showed_results.total }} rezultata
    </div>
</template>

<script>
export default {
    name: "ResultsCount",
    props:{
        showed_results: {
            type: Object,
            required: true,
            validator: function( props ) {
                return (
                        Number.isInteger( props.from )
                        && Number.isInteger( props.to )
                        && Number.isInteger( props.total )
                )
            }
        },
    },
}
</script>

<style scoped>

</style>