<template>
    <div></div>
</template>

<script>
export default {
    name: "NoneFilter",
    props:[
        'data',
        'name',
    ],
}
</script>

<style scoped>

</style>