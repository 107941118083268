<template>
    <div>

        <breadcrumbs
                :title="'Kupci'"
                :breadcrumbs="allBreadcrumbs"
        >
        </breadcrumbs>

        <nav class="navbar navbar-expand-lg navbar-light" style="background-color: #2F4050;">
            <a
                    :class="{
                            'navbar-brand btn btn-light btn-xl': true,
                            'active': active_route === route
                        }"
                    @click="open(route)"
            >
                <strong>Kupac</strong>
            </a>

            <div v-if="kupac" class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li v-if="kupac.knjizica_15" class="nav-item">
                        <a
                                role="button"
                                :class="{
                                            'nav-link btn btn-light' : true,
                                            'active': active_route === route + '/knjizica-15/' + kupac.knjizica_15.id
                                        }"
                                @click.prevent="open( route + '/knjizica-15/' + kupac.knjizica_15.id )"
                        >
                            <strong>Pogledaj knjižicu 15. g</strong>
                        </a>
                    </li>
                    <li v-else class="nav-item">
                        <a
                                role="button"
                                :class="{
                                            'nav-link btn btn-light' : true,
                                            'active': active_route === route + '/knjizica-15/create'
                                        }"
                                @click.prevent="open( route + '/knjizica-15/create')"
                        >
                            <strong>Unesi knjižicu 15 g.</strong>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a
                                role="button"
                                :class="{
                                    'nav-link btn btn-light': true,
                                    'active': active_route === route + '/povijest-promjena'
                                }"
                                @click.prevent="open( route + '/povijest-promjena' )"
                        >
                            <strong>Povijest promjena</strong>
                        </a>
                    </li>

<!--                    <li class="nav-item">-->
<!--                        <a-->
<!--                                role="button"-->
<!--                                :class="{-->
<!--                                    'nav-link btn btn-light': true,-->
<!--                                    'active': active_route === route + '/grupe'-->
<!--                                }"-->
<!--                                @click.prevent="open( route + '/grupe' )"-->
<!--                        >-->
<!--                            <strong>Grupe</strong>-->
<!--                        </a>-->
<!--                    </li>-->

                    <li v-if="user && user.permission_id !== 1 " class="nav-item">
                        <a
                                role="button"
                                :class="{
                                    'nav-link btn btn-light': true,
                                    'active': active_route === route + '/kategorije2'
                                }"
                                @click.prevent="open( route + '/kategorije2' )"
                        >
                            <strong>Kategorije</strong>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a
                                role="button"
                                :class="{
                                    'nav-link btn btn-light': true,
                                    'active': active_route === route + '/pogosnosti'
                                }"
                                @click.prevent="open( route + '/pogodnosti' )"
                        >
                            <strong>Pogodnosti</strong>
                        </a>
                    </li>

                </ul>
            </div>
        </nav>


    </div>
</template>

<script>

import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import axios from "axios";
export default {
  name: "KupciNavigation",
    components: {Breadcrumbs},
    props:[
        'user',
        'id',
        'breadcrumbs'
    ],
    data(){
        return {
            kupac : null,
            naziv: '',
            route: '/kupci/' + this.id,
            active_route: '',
            allBreadcrumbs: []
        }
    },
    methods:{
        open( name ){
            if( name !== this.active_route ){
                this.active_route = name
                this.$router.push( name )
            }
        },
        getKupac(){
            let vm = this
            axios.get( process.env.VUE_APP_API_URL + 'kupac' + '/' + vm.id )
                    .then( function ( response ) {
                        vm.kupac = response.data
                        vm.setBreadcrumbs()
                    } )
                    .catch( function ( error ) {
                        if(error instanceof Error) {
                            console.log( 'Error: ' . error);
                        } else {
                            console.log( 'Unexpected response: ' . error);
                        }
                    } )
        },
        setBreadcrumbs(){
            let vm = this

            vm.allBreadcrumbs = [
                {
                    path: '/kupci',
                    name: 'Kupac'
                },
                {
                    path: ( '/kupci/' + this.id ),
                    name: ( 'Kupac: ' + this.kupac.ime + ' ' + this.kupac.prezime )
                },
            ]

            vm.breadcrumbs.forEach( function( b ){
                vm.allBreadcrumbs.push( b )
            } )
        }
    },
    mounted() {
        this.active_route = this.$route.path
        this.getKupac()
    },
    watch:{
        breadcrumbs: {
            deep: true,
            handler: function(){
                // this.setBreadcrumbs()
            }
        },
    },
};
</script>

<style scoped>

</style>