<template>
    <div class="col-lg-4">
        Prikaži/skrij kolone:
        <div class="row">
            <div v-for="(group, group_id ) in groups" :key="group_id">
            <div v-if="group.columns.length > 0 "  class="btn-group show">

                <button
                        @mouseover="opened = group_id"
                        @mouseleave="opened = '' "
                        data-toggle="dropdown"
                        class="btn btn-primary btn-sm dropdown-toggle"
                        aria-expanded="true"
                    >
                    {{group.title}}
                </button>
                <ul
                        @mouseover="opened = group_id"
                        @mouseleave="opened = '' "
                        :class="{'dropdown-menu':true, 'show':group_id === opened }"
                        x-placement="bottom-start"
                        style="position: absolute; top: 27px; left: 0px; will-change: top, left;"
                    >
                    <li>
                        <a
                                @click="colVisibility(group_id)"
                                class="dropdown-item" href="#"
                            >
                            {{ group.title }}
                        </a>
                    </li>
                    <li class="dropdown-divider"></li>
                    <li
                            v-for="col in group.columns"
                            :key="col.id"
                        >
                        <button
                                v-if="col.visible"
                                class="btn btn-primary col-lg-12"
                                @click.prevent="colVisibility(group_id, col.id)"
                            >
                            <i class="fa fa-check pull-left"></i>
                            {{col.title}}
                        </button>
                        <button
                                v-else class="btn btn-light col-lg-12"
                                @click.prevent="colVisibility(group_id, col.id)"
                            >
                            <i class="fa fa-times"></i>
                            {{col.title}}
                        </button>
                    </li>
                </ul>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ColumnVisibility",
    props:{
        table_header:{
            type: Array,
            required: true
        },
        column_visibility_groups:{
            type: Object,
            required: true,
        }
    },
    data(){
        return {
            opened: null,
            groups: []
        }
    },
    methods: {
        init(){
            let vm = this

            for ( let item in vm.groups ){
                vm.groups[item].columns = []
            }
            vm.$props.table_header.forEach( function ( item ) {
                if( vm.groups[item.column_visibility_group.id] && item.visible ){
                    vm.groups[item.column_visibility_group.id].columns.push( {
                        id: item.id,
                        title: item.title,
                        visible: item.column_visibility_group.visible
                    } )
                }
            } )
        },
        colVisibility( group_id = null, col_id = null ){
            this.opened = ''
            this.$emit( 'setColumnVisibility', group_id, col_id )
        },
    },
    mounted() {
        let vm = this
        vm.groups = vm.$props.column_visibility_groups
        this.init()
    },
    watch:{
        table_header:{
            handler: function ( ){
                this.init()
            },
            deep: true
        }
    }
}
</script>

<style scoped>

</style>