<template>
    <div>
        <breadcrumbs
                :title="'Savjetnice'"
                :breadcrumbs="breadcrumbs"
        >
        </breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Savjetnica: {{ naziv }}</h5>
            </div>
            <div class="ibox-content">
                <savjetnica-forma
                        :disabled="false"
                        :id="id"
                        :modal="false"
                        :action="'edit'"
                        @returnData="setData"
                >
                </savjetnica-forma>
            </div>
        </div>
    </div>

</template>

<script>
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import SavjetnicaForma from "./SavjetnicaForma";
export default {
    name: "SavjetnicaEdit",
    components: {SavjetnicaForma, Breadcrumbs},
    data(){
        return {
            id: this.$route.params.savjetnica_id,
            breadcrumbs: [],
            naziv: ''
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/savjetnice',
                    name: 'Savjetnice'
                },
                {
                    path: ('/savjetnice/' + this.id ),
                    name: ('Savjetnica: ' + this.naziv )
                },
                {
                    path: ( '/savjetnice/' + this.id + '/edit' ),
                    name: ( 'Uredi savjetnicu: ' + this.naziv )
                }
            ]
        },
        setData( data ){
            this.naziv = data.ime ? data.ime + ' ' + data.prezime : ''
            this.setBreadcrumbs()
        }
    }
}
</script>

<style scoped>

</style>