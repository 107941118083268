<template>
    <div>
        <forms
                :settings="settings"
                @onFormButtonClick="onFormButtonClick"
                @onInput="onInput"
        ></forms>
    </div>
</template>

<script>
import axios from "axios";
import toastr from 'toastr';
import Forms from "@/components/form/Forms";
export default {
    name: "KupacPogodnostForma",
    components:{ Forms },
    props:{
        disabled:{
            type: Boolean,
            default: false
        },
        id:{
            default: null
        },
        modal:{
            type: Boolean,
            default: false
        },
        action: {
            type: String
        },
        user: {
            type: Object
        },
    },
    data(){
        return {
            settings: {},
            errors: {},
            route: '/kupci/' + this.$route.params.kupac_id + '/pogodnosti',
            url: 'kupac-pogodnost',
            data: {},
            input_data: {},
            kupac_id: this.$route.params.kupac_id,
            kategorija2_ids: []
        }
    },

    methods: {

        init(){
            let vm = this

            axios.get( process.env.VUE_APP_API_URL + 'kupac-kategorija2', {
                params: {
                    filters: {
                        kupac_id: vm.kupac_id,
                        aktivna: [1]
                    }
                }
            } )
                .then( function ( response ) {

                    response.data.data.forEach( function( item ) {
                        vm.kategorija2_ids.push( item.kategorija2_id )
                    } )

                    if ( vm.action === 'edit' || vm.action === 'show' ){

                        axios.get( process.env.VUE_APP_API_URL + vm.url + '/' + vm.id )
                                .then( function ( response ) {
                                    vm.data = response.data
                                    vm.$emit( 'returnData', response.data )
                                    vm.setupForm()
                                } )
                                .catch( function ( error ) {
                                    if(error instanceof Error) {
                                        console.log( 'Error: ' . error);
                                    } else {
                                        console.log( 'Unexpected response: ' . error);
                                    }
                                } )

                    } else if ( vm.action === 'create' ) {
                        vm.data = {}
                        vm.setupForm()
                    } else {
                        return
                    }

                } )
                .catch( function ( error ) {
                    if(error instanceof Error) {
                        console.log( 'Error: ' . error);
                    } else {
                        console.log( 'Unexpected response: ' . error);
                    }
                } )

        },

        setupForm(){

            let vm = this;

            vm.buttons = []

            let button_cancel = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
                action: 'redirect',
                config: {
                    url: vm.route + ( vm.action === 'edit' ? ( '/' + vm.id) : '' )
                }
            }

            let button_delete = {
                id: 'delete',
                title: 'Obriši',
                type: 'danger',
                action: 'request',
                config: {
                    method: 'DELETE',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let button_edit = {
                id: 'edit',
                title: 'Uredi',
                type: 'success',
                action: 'redirect',
                config: {
                    url: vm.route +  '/' + vm.id +'/edit'
                }
            }

            let button_save = {
                id: 'save',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + vm.url
                }
            }

            let button_update = {
                id: 'update',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'PUT',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let buttons = []


            if( vm.action === 'edit' ){
                buttons.push( button_cancel)
                buttons.push( button_update)
            } else if( vm.action === 'show' ) {
                buttons.push( button_cancel )
                buttons.push( button_delete )
                buttons.push( button_edit )
            }
            else if ( vm.action === 'create' ) {
                buttons.push( button_cancel )
                buttons.push( button_save )
            } else {
                return
            }

            let selected_poslovnica = {
                key:'',
                value: ''
            }

            // default selected poslovnica for user permission 1
            if( vm.user.user_poslovnice.length === 1 ){
                selected_poslovnica.key =  vm.user.user_poslovnice[0].poslovnica.id
                selected_poslovnica.value = vm.user.user_poslovnice[0].poslovnica.naziv
            }

            let settings = {
                width: 6,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: [
                                    {
                                        name: 'kupac_id',
                                        type: 'hidden',
                                        value: vm.kupac_id,
                                        disabled: vm.disabled,
                                        config: {
                                            hidden:true
                                        }
                                    },
                                    {
                                        name: 'pogodnost_id',
                                        type: 'select',
                                        title: 'Pogodnost',
                                        value: {
                                            key: vm.input_data.pogodnost
                                                    ? vm.input_data.pogodnost.id
                                                    : (
                                                            vm.data.kategorija2_pogodnost && vm.data.kategorija2_pogodnost.pogodnost
                                                                    ? vm.data.kategorija2_pogodnost.pogodnost.id
                                                                    : ''
                                                    ),
                                            value: vm.input_data.pogodnost
                                                    ? vm.input_data.pogodnost.naziv
                                                    : (
                                                            vm.data.kategorija2_pogodnost && vm.data.kategorija2_pogodnost.pogodnost
                                                                    ? vm.data.kategorija2_pogodnost.pogodnost.naziv
                                                                    : ''
                                                    )
                                        },
                                        width: 12,
                                        error: vm.errors['pogodnost_id'] ? vm.errors['pogodnost_id'] : '',
                                        note: '',
                                        explanation: '',
                                        placeholder: '',
                                        required: false,
                                        readonly: vm.disabled,
                                        disabled: vm.disabled,
                                        config: {
                                            options: function () {
                                                let model = this

                                                if (vm.action === 'show') {
                                                    model.options = []
                                                    return
                                                }

                                                let filters = {
                                                    kategorija2_id: vm.kategorija2_ids,
                                                    pogodnost_aktivna: [1]
                                                }

                                                axios.get(process.env.VUE_APP_API_URL + 'kategorija2-pogodnost', {
                                                    params: {
                                                        page: 1,
                                                        per_page: -1,
                                                        filters: filters
                                                    }
                                                })
                                                        .then(function (response) {
                                                            let tmp = [];
                                                            response.data.data.forEach(function (item) {
                                                                tmp.push({
                                                                    key: item.pogodnost.id,
                                                                    value: item.pogodnost.naziv
                                                                });
                                                            });
                                                            model.options = tmp
                                                        })
                                                        .catch(function (error) {
                                                            if (error instanceof Error) {
                                                                console.log('Error: '.error);
                                                            } else {
                                                                console.log('Unexpected response: '.error);
                                                            }
                                                        })
                                            },
                                            title_width: 4,
                                            input_width: 8
                                        }
                                    },
                                    {
                                        name: 'poklon_id',
                                        type: 'select',
                                        title: 'Poklon',
                                        value: {
                                            key: vm.input_data.poklon
                                                ? vm.input_data.poklon.id
                                                : (
                                                    vm.data.poklon
                                                        ? vm.data.poklon.id
                                                        : ''
                                                ),
                                            value: vm.input_data.poklon
                                                ? ( vm.input_data.poklon.naziv )
                                                : (
                                                    vm.data.poklon
                                                        ? ( vm.data.poklon.naziv )
                                                        : ''
                                                )
                                        },
                                        width: 12,
                                        error: vm.errors['poklon_id'] ? vm.errors['poklon_id'] : '',
                                        note: '',
                                        explanation: '',
                                        placeholder: '',
                                        required: false,
                                        readonly: vm.disabled,
                                        disabled: vm.disabled,
                                        config: {
                                            options: function ( ) {
                                                let model = this

                                                if( vm.action === 'show'){
                                                    model.options = []
                                                    return
                                                }

                                                let filters = {}

                                                if( vm.input_data.pogodnost_id )
                                                    filters['pogodnost_id'] = vm.input_data.pogodnost_id
                                                else{
                                                    model.options = []
                                                    return
                                                }

                                                axios.get( process.env.VUE_APP_API_URL +  'poklon', {
                                                    params: {
                                                        page: 1,
                                                        per_page: -1,
                                                        filters:filters
                                                    }
                                                } )
                                                    .then( function ( response ) {
                                                        let tmp = [];
                                                        response.data.data.forEach( function ( item ) {
                                                            tmp.push({
                                                                key: item.id,
                                                                value: item.naziv
                                                            } );
                                                        } );
                                                        model.options = tmp
                                                    } )
                                                    .catch( function ( error ) {
                                                        if(error instanceof Error) {
                                                            console.log( 'Error: ' . error);
                                                        } else {
                                                            console.log( 'Unexpected response: ' . error);
                                                        }
                                                    } )
                                            },
                                            title_width: 4,
                                            input_width: 8
                                        }
                                    },
                                    {
                                        name: 'poslovnica_id',
                                        type: 'select',
                                        title: 'Poslovnica',
                                        value: {
                                            key: vm.input_data.poslovnica
                                                    ? vm.input_data.poslovnica.id
                                                    : (
                                                            vm.data.poslovnica
                                                                    ? vm.data.poslovnica.id
                                                                    : selected_poslovnica.key
                                                    ),
                                            value: vm.input_data.poslovnica
                                                    ? vm.input_data.poslovnica.naziv
                                                    : (
                                                            vm.data.poslovnica
                                                                    ? vm.data.poslovnica.naziv
                                                                    : selected_poslovnica.value
                                                    )
                                        },
                                        width: 12,
                                        error: vm.errors['poslovnica_id'] ? vm.errors['poslovnica_id'] : '',
                                        note: '',
                                        explanation: '',
                                        placeholder: '',
                                        required: false,
                                        readonly: vm.disabled,
                                        disabled: vm.disabled,
                                        config: {
                                            options: function ( ) {
                                                let model = this

                                                if( vm.action === 'show'){
                                                    model.options = []
                                                    return
                                                }

                                                let filters = {
                                                    'aktivna': '1',
                                                }

                                                let tmp_brendovi = []
                                                vm.user.user_brendovi.forEach( function ( item ) {
                                                    tmp_brendovi.push( item.brend.id )
                                                } )
                                                if( tmp_brendovi.length )
                                                    filters['brend_id'] = tmp_brendovi

                                                let tmp_poslovnice = [];
                                                vm.user.user_poslovnice.forEach( function( item ) {
                                                    tmp_poslovnice.push( item.poslovnica.id )
                                                } )
                                                if( tmp_poslovnice.length )
                                                    filters['id'] = tmp_poslovnice


                                                axios.get( process.env.VUE_APP_API_URL +  'poslovnica', {
                                                    params: {
                                                        page: 1,
                                                        per_page: -1,
                                                        filters: filters
                                                    }
                                                } )
                                                        .then( function ( response ) {
                                                            let tmp = [];
                                                            response.data.data.forEach( function ( item ) {
                                                                tmp.push({
                                                                    key: item.id,
                                                                    value: item.naziv
                                                                } );
                                                            } );
                                                            model.options = tmp
                                                        } )
                                                        .catch( function ( error ) {
                                                            if(error instanceof Error) {
                                                                console.log( 'Error: ' . error);
                                                            } else {
                                                                console.log( 'Unexpected response: ' . error);
                                                            }
                                                        } )
                                            },
                                            title_width: 4,
                                            input_width: 8
                                        }
                                    },
                                    {
                                        name: 'savjetnica_id',
                                        type: 'select',
                                        title: 'Savjetnica',
                                        value: {
                                            key: vm.input_data.savjetnica
                                                    ? vm.input_data.savjetnica.id
                                                    : (
                                                            vm.data.savjetnica
                                                                    ? vm.data.savjetnica.id
                                                                    : ''
                                                    ),
                                            value: vm.input_data.savjetnica
                                                    ? ( vm.input_data.savjetnica.ime + ' ' + vm.input_data.savjetnica.prezime )
                                                    : (
                                                            vm.data.savjetnica
                                                                    ? ( vm.data.savjetnica.ime + ' ' + vm.data.savjetnica.prezime )
                                                                    : ''
                                                    )
                                        },
                                        width: 12,
                                        error: vm.errors['savjetnica'] ? vm.errors['savjetnica'] : '',
                                        note: '',
                                        explanation: '',
                                        placeholder: '',
                                        required: false,
                                        readonly: vm.disabled,
                                        disabled: vm.disabled,
                                        config: {
                                            options: function ( ) {
                                                let model = this

                                                if( vm.action === 'show'){
                                                    model.options = []
                                                    return
                                                }

                                                let filters = {
                                                    'aktivna': '1',
                                                    'aktivna_poslovnica': '1'
                                                }
                                                let tmp_brendovi = []
                                                vm.user.user_brendovi.forEach( function ( item ) {
                                                    tmp_brendovi.push( item.brend.id )
                                                } )
                                                if( tmp_brendovi.length )
                                                    filters['brend_id'] = tmp_brendovi


                                                axios.get( process.env.VUE_APP_API_URL +  'savjetnica', {
                                                    params: {
                                                        page: 1,
                                                        per_page: -1,
                                                        filters:filters
                                                    }
                                                } )
                                                        .then( function ( response ) {
                                                            let tmp = [];
                                                            response.data.data.forEach( function ( item ) {
                                                                tmp.push({
                                                                    key: item.id,
                                                                    value: item.ime + ' ' + item.prezime
                                                                } );
                                                            } );
                                                            model.options = tmp
                                                        } )
                                                        .catch( function ( error ) {
                                                            if(error instanceof Error) {
                                                                console.log( 'Error: ' . error);
                                                            } else {
                                                                console.log( 'Unexpected response: ' . error);
                                                            }
                                                        } )
                                            },
                                            title_width: 4,
                                            input_width: 8
                                        }
                                    },
                                    {
                                        name: 'iznos_racuna',
                                        type: 'input',
                                        title: 'Iznos računa',
                                        value: vm.data['iznos_racuna'] ? vm.data['iznos_racuna'] : '',
                                        width: 12,
                                        error: vm.errors['iznos_racuna'] ? vm.errors['iznos_racuna'] : '',
                                        note: 'Npr. 1000.00',
                                        explanation: '',
                                        placeholder: '1000.00',
                                        required: !vm.disabled,
                                        readonly: vm.disabled,
                                        disabled: vm.disabled,
                                        config: {
                                            title_width: 4,
                                            input_width: 8
                                        }
                                    },
                                    {
                                        name: 'broj_racuna',
                                        type: 'input',
                                        title: 'Broj računa',
                                        value: vm.data['broj_racuna'] ? vm.data['broj_racuna'] : '',
                                        width: 12,
                                        error: vm.errors['broj_racuna'] ? vm.errors['broj_racuna'] : '',
                                        note: '',
                                        explanation: '',
                                        placeholder: '',
                                        required: false,
                                        readonly: vm.disabled,
                                        disabled: vm.disabled,
                                        config: {
                                            title_width: 4,
                                            input_width: 8
                                        }
                                    },
                                    {
                                        name: 'datum_i_vrijeme',
                                        type: 'input',
                                        title: 'Datum',
                                        value: vm.data['datum_i_vrijeme'] ? vm.data['datum_i_vrijeme'] : '',
                                        width: 12,
                                        error: vm.errors['datum_i_vrijeme'] ? vm.errors['datum_i_vrijeme'] : '',
                                        note: '',
                                        explanation: '',
                                        placeholder: '',
                                        required: false,
                                        readonly: true,
                                        disabled: true,
                                        config: {
                                            title_width: 4,
                                            input_width: 8
                                        }
                                    },
                                ],
                            }
                        ],
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },

        /**
         *  on input return data
         */
        onInput( name, value ){
            let vm = this;
            if( name === 'pogodnost_id') {
                if( value )
                    axios.get( process.env.VUE_APP_API_URL +  'pogodnost/' + value )
                            .then( function ( response ) {
                                vm.input_data['pogodnost'] = response.data
                                vm.setupForm()
                            } )
                            .catch( function ( error ) {
                                console.log( error )
                                if(error instanceof Error) {
                                    console.log( 'Error: ' . error);
                                } else {
                                    console.log( 'Unexpected response: ' . error);
                                }
                            } )
                else vm.input_data['pogodnost'] = null
            } if( name === 'poslovnica_id') {
                if( value )
                    axios.get( process.env.VUE_APP_API_URL +  'poslovnica/' + value )
                            .then( function ( response ) {
                                vm.input_data['poslovnica'] = response.data
                            } )
                            .catch( function ( error ) {
                                if(error instanceof Error) {
                                    console.log( 'Error: ' . error);
                                } else {
                                    console.log( 'Unexpected response: ' . error);
                                }
                            } )
                else vm.input_data['poslovnica'] = null
            } if( name === 'savjetnica_id') {
                if( value )
                    axios.get( process.env.VUE_APP_API_URL +  'savjetnica/' + value )
                            .then( function ( response ) {
                                vm.input_data['savjetnica'] = response.data
                            } )
                            .catch( function ( error ) {
                                if(error instanceof Error) {
                                    console.log( 'Error: ' . error);
                                } else {
                                    console.log( 'Unexpected response: ' . error);
                                }
                            } )
                else vm.input_data['savjetnica'] = null
            } else if ( name === 'poklon_id' ) {
                if( value )
                    axios.get( process.env.VUE_APP_API_URL +  'poklon/' + value )
                        .then( function ( response ) {
                            vm.input_data['poklon'] = response.data
                        } )
                        .catch( function ( error ) {
                            if(error instanceof Error) {
                                console.log( 'Error: ' . error);
                            } else {
                                console.log( 'Unexpected response: ' . error);
                            }
                        } )
                else vm.input_data['poklon'] = null
            } else {
                vm.input_data[name] = value
            }
        },

        /**
         *  on button click
         */
        onFormButtonClick( id, action, config, data  ){

            if( id === 'save' || id === 'update' ){
                if( data.success){
                    if( id == 'save' )
                        toastr.success( 'Uspješno spremljeno!' )
                    else if ( id == 'update' )
                        toastr.success( 'Uspješno ažurirano!' )
                    this.$router.push({ path: this.route + '/' + data.data.id } )
                } else {
                    this.errors = data.messages
                    this.init()
                }
            } else if( id == 'delete' ){
                toastr.error( 'Uspješno obrisano!' )
                this.$router.push({ path: this.route } )
            }

        },


    },
    mounted() {
        this.init()
    }
}
</script>

<style scoped>

</style>
