<template>
    <router-view
            v-if="user"
            :user="user"
    ></router-view>
</template>

<script>
import axios from "axios";
export default {
    name: "EmailingBase",
    data() {
        return {
            user: null
        }
    },
    mounted(){
        let vm = this
        axios.get( process.env.VUE_APP_API_URL + 'user-auth' )
            .then( function ( response ) {
                vm.user = response.data
            } )
            .catch( function ( error ) {
                if(error instanceof Error) {
                    console.log( 'Error: ' . error);
                } else {
                    console.log( 'Unexpected response: ' . error);
                }
            } )
    }
}
</script>

<style scoped>

</style>