<template>
    <router-view
            :user="user"
    ></router-view>
</template>

<script>
export default {
    name: "Knjizica15Base",
    props:['user']
}
</script>

<style scoped>

</style>