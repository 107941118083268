<template>
    <tr class="filter">
        <td v-for="header in headers" :key="header.id">

                <component
                        v-if="header.searchable"
                        :is="header.filter.type + '-filter'"
                        :name="header.id"
                        :data="header.filter"
                        @set_filter="setFilter"
                ></component>
        </td>
    </tr>
</template>

<script>
import DateFilter from "@/components/datatable/parts/filters/DateFilter";
import InputFilter from "@/components/datatable/parts/filters/InputFilter";
import MultiselectFilter from "@/components/datatable/parts/filters/MultiselectFilter";
import NoneFilter from "@/components/datatable/parts/filters/NoneFilter";
import NumberFilter from "@/components/datatable/parts/filters/NumberFilter";
import SelectFilter from "@/components/datatable/parts/filters/SelectFilter";

export default {
    name: "ColumnFilters",
    components:{
        DateFilter,
        InputFilter,
        MultiselectFilter,
        NoneFilter,
        NumberFilter,
        SelectFilter
    },
    props: {
        table_header: {
            type: Array,
            required: true
        }
    },
    data(){
        return {
            headers: []
        }
    },
    methods:{
        init(){
            let vm = this
            vm.headers = []
            vm.$props.table_header.forEach( function( item ) {
                if( item.visible && item.column_visibility_group.visible )
                    vm.headers.push( item )
            } )
        },
        setFilter( args ){
            this.$emit( 'setFilters', args );
        }
    },
    mounted() {
        this.init()
    },
    watch:{
        table_header:{
            handler: function (){
                this.init()
            },
            deep: true
        }
    }
}
</script>

<style scoped>

</style>