<template>
    <div class="row">

        <div :class="title_width"></div>

        <div :class="input_width">
            <span class="form-text m-b-none text-success">
                    {{ note }}
            </span>
            <div class="input-group-append">

                <input
                        :name="name"
                        v-model="ret_value"
                        :placeholder="placeholder"
                        :readonly="readonly"
                        :disabled="disabled"
                        :type="'hidden'"
                        class="form-control"
                        @input="_return"
                />

                <span
                            v-if="ret_value"
                            class="input-group-addon"
                            :style="{'cursor': disabled || readonly ? '' : 'pointer', 'width': '50px', 'height': '35px'}"
                            @click="change_value"
                    >
                    <i class="fa fa-check" aria-hidden="true"></i>
                </span>
                <span
                            v-else
                            class="input-group-addon"
                            :style="{'cursor': disabled || readonly ? '' : 'pointer', 'width': '50px', 'height': '35px'}"
                            @click="change_value"
                    >
<!--                    <i class="fa fa-times" aria-hidden="true"></i>-->
                </span>




                <label class="" :for="name" style="margin-top: 5px; margin-left: 10px" >
                    <strong class="float-md-left">{{title}} {{ required ? '*' : ''}}</strong>
                </label>

                <span
                        v-if="explanation"
                        class="input-group-addon"
                        data-toggle="tooltip"
                        data-placement="right"
                        :title="explanation"
                >
                    <i class="fa fa-info" aria-hidden="true"></i>
                </span>
            </div>
            <span class="form-text m-b-none text-danger">
                <div class="row">
                    <div class="col-lg-6">
                    {{ error[0] }}
                    </div>
                    <!--                <div class="col-md-6">-->
                    <!--                    <button class="btn float-right" style="margin-left: 2px; margin-right: 2px">123</button>-->
                    <!--                </div>-->
                </div>
            </span>

        </div>


    </div>
</template>

<script>
    export default {
        name: "CheckboxComponent",
        props: [
            'name',
            'value',
            'title',
            'error',
            'note',
            'explanation',
            'placeholder',
            'required',
            'readonly',
            'disabled',
            'config'
        ],
        data(){
            return {
                ret_value: this.value,
                disabled_input: this.disabled,
                title_width: ('col-lg-' + ( this.config.title_width ? this.config.title_width : '1' ) ),
                input_width: ('col-lg-' + ( this.config.input_width ? this.config.input_width : '11' ) )
            }
        },
        methods:{
            change_value(){
                if( this.disabled_input || this.$props.readonly )
                    return

                this.ret_value = !this.ret_value;
                this._return()
            },
            _return() {
                this.$emit('onInput', this.name, this.ret_value );
            }
        },
        mounted() {
            this.ret_value = this.value
            this._return()
        },
        watch: {
            value(){
                this.ret_value = this.value
                this._return()
            }
        }
    }
</script>

<style scoped>

</style>