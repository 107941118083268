<template>
    <div style="">
    <div class="progress" style="height: 20px; ">
        <div style="width: 1%; height: 20px" class="progress-bar progress-bar-danger">dsad</div>
    </div>
    </div>
</template>

<script>
export default {
name: "ProgressCell"
}
</script>

<style scoped>

</style>