<template>
    <div>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Pokloni</h5><br>
                <button
                    type="button"
                    class="btn btn-lg btn-primary"
                    @click.prevent="createForm"
                >
                    Novi poklon
                </button>
            </div>
            <div class="ibox-content">
                <poklon-forma
                    v-if="show_form"
                    :disabled="false"
                    :id="form_id"
                    :modal = false
                    :action="form_action"
                    :pogodnost_id="pogodnost_id"
                    @hideForm="hideForm"
                    @reloadDatatable="setDatatable"
                ></poklon-forma>
                <br><br>
                <datatables
                    v-if="show_datatable"
                    :url="datatables_url"
                    :settings="datatables.settings"
                    :title="datatables.title"
                    @buttonClick="buttonClick"
                >
                </datatables>
            </div>
        </div>
    </div>
</template>

<script>
import PoklonForma from "./PoklonForma";
import Datatables from "../../../../components/datatable/Datatables";
export default {
    name: "PoklonBase",
    components: { PoklonForma, Datatables },
    props: ['pogodnost_id'],
    data(){
        return {
            // datatable
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL + 'poklon',
            datatables: {},

            // form
            show_form: false,
            form_action: '',
            form_id: null,
        }
    },

    methods:{

        hideForm(){
            this.show_form = false
            this.form_action = ''
            this.form_id = null
        },

        createForm(){
            this.hideForm()
            this.form_action = 'create'
            this.form_id = null
            this.show_form = true
        },

        editForm( row ){
            this.hideForm()
            this.form_action = 'edit'
            this.form_id = row.id
            this.show_form = true
        },

        setDatatable(){
            let vm = this;

            vm.show_datatable = false

            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'pogodnost_id',
                            'visible' : false,
                            'filter' : {
                                'type': 'select',
                                'value': vm.pogodnost_id
                            },
                        },
                        {
                            'id': 'naziv',
                            'title': 'Naziv',
                            'column_visibility_group': {
                                id: 'pokloni',
                            }
                        },
                        {
                            'id': 'vrijednost',
                            'title': 'Vrijednost',
                            'column_visibility_group': {
                                id: 'pokloni',
                            }
                        },
                        {
                            'id': 'uredi',
                            'title': '',
                            'searchable': false,
                            'type': 'button',
                            'filter': {
                                'type': 'none',
                                'search': '',
                                'value': null,
                            },
                            'column_visibility_group': {
                                id: 'pokloni',
                            },
                            config: {
                                title: 'Uredi',
                                type: 'primary',
                            }
                        },

                    ],
                    column_visibility_groups:{
                        'pokloni' : 'Pokloni'
                    },
                    order: 'desc',
                    order_by: 'id',
                    enable_export: null,
                },
                title: 'Pokloni',
            }
            vm.show_datatable = true;
        },


        buttonClick( col, row ){
            this.editForm( row )
        }

    },
    mounted() {
        this.setDatatable()
    }

}
</script>

<style scoped>

</style>
