var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('breadcrumbs',{attrs:{"title":'Kupci',"breadcrumbs":_vm.allBreadcrumbs}}),_c('nav',{staticClass:"navbar navbar-expand-lg navbar-light",staticStyle:{"background-color":"#2F4050"}},[_c('a',{class:{
                            'navbar-brand btn btn-light btn-xl': true,
                            'active': _vm.active_route === _vm.route
                        },on:{"click":function($event){return _vm.open(_vm.route)}}},[_c('strong',[_vm._v("Kupac")])]),(_vm.kupac)?_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"navbar-nav mr-auto"},[(_vm.kupac.knjizica_15)?_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                            'nav-link btn btn-light' : true,
                                            'active': _vm.active_route === _vm.route + '/knjizica-15/' + _vm.kupac.knjizica_15.id
                                        },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/knjizica-15/' + _vm.kupac.knjizica_15.id )}}},[_c('strong',[_vm._v("Pogledaj knjižicu 15. g")])])]):_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                            'nav-link btn btn-light' : true,
                                            'active': _vm.active_route === _vm.route + '/knjizica-15/create'
                                        },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/knjizica-15/create')}}},[_c('strong',[_vm._v("Unesi knjižicu 15 g.")])])]),_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                    'nav-link btn btn-light': true,
                                    'active': _vm.active_route === _vm.route + '/povijest-promjena'
                                },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/povijest-promjena' )}}},[_c('strong',[_vm._v("Povijest promjena")])])]),(_vm.user && _vm.user.permission_id !== 1 )?_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                    'nav-link btn btn-light': true,
                                    'active': _vm.active_route === _vm.route + '/kategorije2'
                                },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/kategorije2' )}}},[_c('strong',[_vm._v("Kategorije")])])]):_vm._e(),_c('li',{staticClass:"nav-item"},[_c('a',{class:{
                                    'nav-link btn btn-light': true,
                                    'active': _vm.active_route === _vm.route + '/pogosnosti'
                                },attrs:{"role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.open( _vm.route + '/pogodnosti' )}}},[_c('strong',[_vm._v("Pogodnosti")])])])])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }