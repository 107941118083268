<template>
    <div>
        <kupci-navigation
                :id="kupac_id"
                :breadcrumbs="breadcrumbs"
                user="user"
        >
        </kupci-navigation>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Pogodnost: {{ naziv }}</h5>
            </div>
            <div class="ibox-content">
                <kupac-pogodnost-forma
                        :disabled="false"
                        :id="id"
                        :modal="false"
                        :action="'edit'"
                        @returnData="setData"
                        :user="user"
                >
                </kupac-pogodnost-forma>
            </div>
        </div>
    </div>
</template>

<script>
import KupciNavigation from "../KupciNavigation";
import KupacPogodnostForma from "./KupacPogodnostForma";
export default {
    name: "KupacPogodnostiaEdit",
    components: {KupacPogodnostForma, KupciNavigation},
    props: ['user'],
    data(){
        return {
            breadcrumbs: [],
            id: this.$route.params.kupac_pogodnost_id,
            kupac_id: this.$route.params.kupac_id,
            naziv: ''
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/kupci/' + this.kupac_id + '/pogodnosti',
                    name: 'Grupe'
                },
                {
                    path: '/kupci/' + this.kupac_id + '/pogodnosti/' + this.id,
                    name: 'Grupa: ' + this.naziv
                },
                {
                    path: '/kupci/' + this.kupac_id + '/pogodnosti/' + this.id + '/edit',
                    name: 'Uredi grupu: ' + this.naziv
                }
            ]
        },
        setData( data ){
            this.naziv = data.naziv
            this.setBreadcrumbs()
        },
    }
}
</script>

<style scoped>

</style>