<template>
    <div>
        <breadcrumbs
                :title="'Kupci'"
                :breadcrumbs="breadcrumbs"
        >
        </breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Kupci</h5><br>
                <button
                        type="button"
                        class="btn btn-lg btn-primary"
                        @click.prevent="create"
                >
                    Novi unos
                </button>
            </div>
            <div class="ibox-content">
                <datatables
                        v-if="show_datatable"
                        :url="datatables_url"
                        :settings="datatables.settings"
                        :title="datatables.title"
                        @rowClick="rowClick"
                >
                </datatables>
            </div>
        </div>
    </div>
</template>



<script>
import Datatables from "@/components/datatable/Datatables";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import axios from "axios";
export default {
  name: "Kupci",
    components: {Breadcrumbs, Datatables},
    props: ['user'],
    data(){
        return {
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL + 'kupac',
            datatables: {},
            breadcrumbs: []
        }
    },
    methods: {
        rowClick( row ){
            this.$router.push( { path: '/kupci/' + row.id } )
        },

        create(){
            this.$router.push( { path: '/kupci/create' } )
        },


        setDatatable(){

            let vm = this
            let brendovi_filter = []
            let brendovi_filter_ids = []
            brendovi_filter_ids.push( 0 )
            if( vm.user.user_brendovi ){
                vm.user.user_brendovi.forEach( function ( item ) {
                    brendovi_filter.push( { key: item.brend.id, value:item.brend.naziv } )
                    brendovi_filter_ids.push( item.brend.id )
                } )
            }

            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'ime',
                            'title': 'Ime',
                            'column_visibility_group': {
                                id: 'kupci',
                            }
                        },
                        {
                            'id': 'prezime',
                            'title': 'Prezime',
                            'column_visibility_group': {
                                id: 'kupci',
                            }
                        },
                        {
                            'id': 'datum_rodjenja',
                            'title': 'Datum rođenja',
                            'column_visibility_group': {
                                id: 'kupci',
                            },
                            'filter': {
                                'type': 'date',
                            }
                        },
                        {
                            'id': 'spol',
                            'title': 'Spol',
                            'type': function( row ){
                                return row && [0,1,2].includes( row.spol )
                                        ? (
                                                row.spol === 0
                                                        ? 'Ž'
                                                        : (
                                                                row.spol === 1
                                                                        ? 'M'
                                                                        : 'Nedefinirano'
                                                        )
                                        )
                                        : ''
                            },
                            'filter': {
                                'type': 'select',
                                'search': [
                                    {
                                        key: 0,
                                        value: 'Ž'
                                    },
                                    {
                                        key: 1,
                                        value: 'M'
                                    },
                                    {
                                        key: 2,
                                        value: 'Nedefinirano'
                                    },

                                ],
                                'value': null
                            },
                            'column_visibility_group': {
                                id: 'kupci',
                            },
                        },
                        {
                            'id': 'email',
                            'title': 'Email',
                            'column_visibility_group': {
                                id: 'kupci',
                            },
                        },
                        {
                            'id': 'mobitel',
                            'title': 'Mobitel',
                            'column_visibility_group': {
                                id: 'kupci',
                            },
                        },
                        {
                            'id': 'brend_id',
                            'title': 'Brend',
                            'type': function( row ){
                                return row.brend ? row.brend.naziv : 'Nedefiniran'
                            },
                            'filter': {
                                'type': 'select',
                                'search': function ( ){
                                    let component = this;

                                    axios.get( process.env.VUE_APP_API_URL +  'brend', {
                                        params: {
                                            filters: {
                                                id: brendovi_filter_ids
                                            }
                                        }
                                    } )
                                            .then( function ( response ) {
                                                let tmp = [];

                                                tmp.push({
                                                    key: 0,
                                                    value: 'Nedefiniran'
                                                })

                                                    response.data.data.forEach( function ( item ) {
                                                    tmp.push({
                                                        key: item.id,
                                                        value: item.naziv
                                                    } );
                                                } );
                                                component.$data.tmp = tmp;
                                            } )
                                            .catch( function ( error ) {
                                                if(error instanceof Error) {
                                                    console.log( 'Error: ' . error);
                                                } else {
                                                    console.log( 'Unexpected response: ' . error);
                                                }
                                            } )
                                },
                            },
                            'options' : brendovi_filter_ids,
                            'column_visibility_group': {
                                id: 'kupci',
                            },
                        },
                        // {
                        //     'id': 'adresa',
                        //     'title': '',
                        //     'column_visibility_group': {
                        //         id: 'kupci',
                        //     },
                        // },
                        // {
                        //     'id': 'posta.naselje',
                        //     'title': 'Naselje',
                        //     'column_visibility_group': {
                        //         id: 'kupci',
                        //     },
                        // },
                        // {
                        //     'id': 'posta.mjesto',
                        //     'title': 'Mjesto',
                        //     'column_visibility_group': {
                        //         id: 'kupci',
                        //     },
                        // },
                        // {
                        //     'id': 'posta.postanski_broj',
                        //     'title': '',
                        //     'column_visibility_group': {
                        //         id: 'kupci',
                        //     },
                        // },
                        // {
                        //     'id': 'posta.zupanija.naziv',
                        //     'title': '',
                        //     'column_visibility_group': {
                        //         id: 'kupci',
                        //     },
                        // },
                        // {
                        //     'id': 'posta.zupanija.drzava.naziv',
                        //     'title': '',
                        //     'column_visibility_group': {
                        //         id: 'kupci',
                        //     },
                        // },
                        {
                            'id': 'privola_email',
                            'title': 'Privola e-mail',
                            'type': function( row ){
                                return row.privola_email ? 'Da' : 'Ne'
                            },
                            'filter': {
                                'type': 'select',
                                'search': [
                                    {
                                        key: '1',
                                        value: 'Da'
                                    },
                                    {
                                        key: '0',
                                        value: 'Ne'
                                    }
                                ],
                                'value': null
                            },
                            'column_visibility_group': {
                                id: 'kupci',
                            },
                        },
                        {
                            'id': 'privola_mobitel',
                            'title': 'Privola mobitel',
                            'type': function( row ){
                                return row.privola_mobitel ? 'Da' : 'Ne'
                            },
                            'filter': {
                                'type': 'select',
                                'search': [
                                    {
                                        key: '1',
                                        value: 'Da'
                                    },
                                    {
                                        key: '0',
                                        value: 'Ne'
                                    }
                                ],
                                'value': null
                            },
                            'column_visibility_group': {
                                id: 'kupci',
                            },
                        },
                        {
                            'id': 'privola_posta',
                            'title': 'Privola pošta',
                            'type': function( row ){
                                return row.privola_posta ? 'Da' : 'Ne'
                            },
                            'filter': {
                                'type': 'select',
                                'search': [
                                    {
                                        key: '1',
                                        value: 'Da'
                                    },
                                    {
                                        key: '0',
                                        value: 'Ne'
                                    }
                                ],
                                'value': null
                            },
                            'column_visibility_group': {
                                id: 'kupci',
                            },
                        },
                        {
                            'id': 'poslovnica_id',
                            'title': 'Poslovnica',
                            'type': function( row ){
                                return row.poslovnica ? row.poslovnica.naziv : ''
                            },
                            'filter': {
                                'type': 'select',
                                'search': function ( ){
                                    let component = this;

                                    axios.get( process.env.VUE_APP_API_URL +  'poslovnica')
                                            .then( function ( response ) {
                                                let tmp = [];
                                                response.data.data.forEach( function ( item ) {
                                                    tmp.push({
                                                        key: item.id,
                                                        value: item.naziv
                                                    } );
                                                } );
                                                component.$data.tmp = tmp;
                                            } )
                                            .catch( function ( error ) {
                                                if(error instanceof Error) {
                                                    console.log( 'Error: ' . error);
                                                } else {
                                                    console.log( 'Unexpected response: ' . error);
                                                }
                                            } )
                                },
                                'value': null
                            },
                            'column_visibility_group': {
                                id: 'kupci',
                            },
                        },
                        {
                            'id': 'savjetnica_id',
                            'title': 'Savjetnica',
                            'type': function( row ){
                                return row.savjetnica ? ( row.savjetnica.ime + ' ' + row.savjetnica.prezime ) : ''
                            },
                            'filter': {
                                'type': 'select',
                                'search': function ( ){
                                    let component = this;

                                    axios.get( process.env.VUE_APP_API_URL +  'savjetnica')
                                            .then( function ( response ) {
                                                let tmp = [];
                                                response.data.data.forEach( function ( item ) {
                                                    tmp.push({
                                                        key: item.id,
                                                        value: item.ime + ' ' + item.prezime
                                                    } );
                                                } );
                                                component.$data.tmp = tmp;
                                            } )
                                            .catch( function ( error ) {
                                                if(error instanceof Error) {
                                                    console.log( 'Error: ' . error);
                                                } else {
                                                    console.log( 'Unexpected response: ' . error);
                                                }
                                            } )
                                },
                                'value': null
                            },
                            'column_visibility_group': {
                                id: 'kupci',
                            },
                        },
                    ],
                    column_visibility_groups:{
                        'kupci' : 'Kupci'
                    },
                    order: 'desc',
                    order_by: 'id',
                    enable_export: [2,3].includes( vm.user.permission_id )
                },
                title: 'Kupci',
            }
            vm.show_datatable = true
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/kupci',
                    name: 'Kupci'
                }
            ];
        }
    },
    mounted() {
        this.setDatatable()
        this.setBreadcrumbs()
    }
};
</script>

<style scoped>

</style>