<template>
    <div>
        <breadcrumbs
                :title="'Pogodnosti'"
                :breadcrumbs="breadcrumbs"
        >
        </breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Nova pogodnost</h5><br>
            </div>
            <div class="ibox-content">
                <pogodnost-forma
                        :disabled="false"
                        :id="null"
                        :modal = false
                        :action="'create'"
                        :user="user"
                >
                </pogodnost-forma>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import PogodnostForma from "./PogodnostForma";
export default {
    name: "PogodnostCreate",
    components: {PogodnostForma, Breadcrumbs},
    props:['user'],
    data(){
        return {
            breadcrumbs: []
        }
    },
    methods: {
        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/pogodnosti',
                    name: 'Pogodnost'
                },
                {
                    path: '/pogodnosti/create',
                    name: 'Nova pogodnost'
                }
            ]
        }
    },
    mounted() {
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>
