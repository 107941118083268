<template>
    <div class="row">
        <label :class="title_width" :for="name">
            <strong class="float-lg-right float-md-left">{{title}} {{ required ? '*' : ''}}</strong>
        </label>

        <div v-if="!disabled" :class="input_width">
            <span class="form-text m-b-none text-success">
                    {{ note }}
            </span>
            <!--            <vue-editor-->
            <!--                    :name="name"-->
            <!--                    v-model="content"-->
            <!--                    :disabled="disabled"-->
            <!--                    :readonly="readonly"-->
            <!--                    class=""-->
            <!--                    @input="_return"-->
            <!--            >-->

            <!--                <span-->
            <!--                        class="input-group-addon"-->
            <!--                        data-toggle="tooltip"-->
            <!--                        data-placement="right"-->
            <!--                        :title="explanation"-->
            <!--                >-->
            <!--                    <i class="fa fa-info" aria-hidden="true"></i>-->
            <!--                </span>-->
            <!--            </vue-editor>-->
            <editor
                v-if="config"
                :name="name"
                :disabled="disabled || readonly"
                :readonly="readonly"
                v-model="content"
                @input="_return"
                :init="{
                        skin_url: '/tinymce/skins/ui/oxide',
                        content_css: '/tinymce/skins/content/default/content.css',
                        height: 500,
                        menubar: true,
                        plugins: [
                                    'advlist autolink lists link image charmap',
                                    'searchreplace visualblocks code fullscreen',
                                    'print preview anchor insertdatetime media',
                                    'paste code help wordcount table'
                                ],
                        file_picker_types: 'image',
                        automatic_uploads: false,
                        paste_data_images: true,
                        images_upload_handler: function(blobInfo, success, failure, progress){
                            success('data:' + blobInfo.blob().type + ';base64,' + blobInfo.base64());
                        },
                        toolbar: 'undo redo ' +
                                '| removeformat ' +
                                '| formatselect fontselect fontsizeselect forecolor backcolor ' +
                                '| bold italic underline ' +
                                '| alignleft aligncenter alignright alignjustify ' +
                                '| bullist numlist outdent indent ' +
                                '| help' +
                                '| ' + toolbar_addon_buttons ,
                        content_style: `
                                @font-face {
                                    font-family: 'LOccitaneSans-Bold';
                                    src: url('/fonts/custom/LOccitaneSans-Bold.woff') format('woff');
                                }
                                 @font-face {
                                    font-family: 'LOccitaneSans-Regular';
                                    src: url('/fonts/custom/LOccitaneSans-Regular.woff') format('woff');
                                }
                                 @font-face {
                                    font-family: 'LOccitaneSerif-Bold';
                                    src: url('/fonts/custom/LOccitaneSerif-Bold.woff') format('woff');
                                }
                                 @font-face {
                                    font-family: 'LOccitaneSerif-BoldItalic';
                                    src: url('/fonts/custom/LOccitaneSerif-BoldItalic.woff') format('woff');
                                }
                                 @font-face {
                                    font-family: 'LOccitaneSerif-Italic';
                                    src: url('/fonts/custom/LOccitaneSerif-Italic.woff') format('woff');
                                }
                                 @font-face {
                                    font-family: 'LOccitaneSerif-Regular';
                                    src: url('/fonts/custom/LOccitaneSerif-Regular.woff') format('woff');
                                }
                              `,
                        font_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats;'
                            + 'LOccitane Sans Bold=LOccitaneSans-Bold;'
                            + 'LOccitane Sans Regular=LOccitaneSans-Regular;'
                            + 'LOccitane Serif Bold=LOccitaneSerif-Bold;'
                            + 'LOccitane Serif BoldItalic=LOccitaneSerif-BoldItalic;'
                            + 'LOccitane Serif Italic=LOccitaneSerif-Italic;'
                            + 'LOccitane Serif Regular=LOccitaneSerif-Regular;'
                        ,


                        setup: toolbar_addon_buttons_f ? toolbar_addon_buttons_f :  function( editor ){}
                    }"
            >
            </editor>
            <span class="form-text m-b-none text-danger">
                <div class="row">
                    <div class="col-md-6">
                    {{ error }}
                    </div>
                    <!--                <div class="col-md-6">-->
                    <!--                    <button class="btn float-right" style="margin-left: 2px; margin-right: 2px">123</button>-->
                    <!--                </div>-->
                </div>
            </span>
        </div>

        <div v-else :class="input_width">
            <div class="col-lg-12">
                <div
                    class=""
                    style=" min-height: 30px; width: 100%"
                    v-html="content"
                >
                </div>
            </div>
        </div>

    </div>
</template>

<script>


import 'tinymce/tinymce'
import 'tinymce/icons/default/icons'
import 'tinymce/themes/silver/theme'




import 'tinymce/plugins/image';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/link';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/help';
import 'tinymce/plugins/table';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/template';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/toc';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/textpattern';
import 'tinymce/plugins/noneditable';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/autosave';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/importcss';


import Editor from '@tinymce/tinymce-vue';


export default {
    name: "EditorComponent",
    components: {
        // VueEditor,
        //Quill,
        editor: Editor
    },
    props: [
        'name',
        'value',
        'title',
        'error',
        'note',
        'explanation',
        'placeholder',
        'required',
        'readonly',
        'disabled',
        'config'
    ],
    data(){
        return {
            content: '',
            title_width: ('col-form-label col-lg-' + ( this.config.title_width ? this.config.title_width : '4' ) ),
            input_width: ('col-lg-' + ( this.config.input_width ? this.config.input_width : '8' ) ),
            toolbar_addon_buttons: this.config.addons ? this.config.addons.buttons : '',
            toolbar_addon_buttons_f: this.config.addons ? this.config.addons.buttons_f : null
        }
    },
    methods:{
        _return(){
            this.$emit('onInput',this.name, this.content);
        }
    },
    mounted() {
        this.content = this.value;
        this._return()
    },
    watch: {
        value() {
            this.content = this.value;
        },
    }
}
</script>

<style>

</style>
