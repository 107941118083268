<template>
    <div class="container">
        <div class="vld-parent">
            <loading :active.sync="open_loader"
                     :can-cancel="false"
                     :is-full-page="true">

            </loading>
        </div>

        <div class="row" style="margin-top: 10%">

            <div class="col-md-3"></div>
            <div class="col-md-6">
                <div class="panel panel-default">
                    <div class="panel-heading">Oporavak lozinke</div>

                    <div class="panel-body">
                        <div class="col-md-12" style="text-align: center">
                            <img src="" style="align-items: center">
                        </div>

                        <form class="form-horizontal">

                            <div class="form-group">
                                <label for="email" class="col-md-4 control-label">E-Mail / korisničko ime</label>

                                <div class="col-md-12">
                                    <input id="email" type="email" class="form-control" name="email" v-model="email" required autofocus>
                                    <span v-if="errors && errors.email" class="form-text m-b-none text-danger">
                                        {{ errors.email }}
                                    </span>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-md-8 col-md-offset-4">
                                    <button type="submit" class="btn btn-primary" @click.prevent="forgotPassword">
                                        Poštalji
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import toastr from 'toastr';
import Loading from 'vue-loading-overlay'
export default {
    name: "ForgotPassword",
    components: { Loading },
    data(){
        return{
            email: null,
            errors: null,
            open_loader: false,
        }
    },
    methods:{
        forgotPassword(){
            let vm = this
            vm.open_loader = true;
            axios.post(process.env.VUE_APP_API_URL + "forgot-password", {
                email: vm.email
            })
                .then(response => {
                    vm.open_loader = false
                    if( response.data['success'] ){
                        toastr.success('Link za oporavak lozinke poslan je na vaš e-mail')
                        vm.$router.push( '/login' );
                    } else {
                        if( response.data.messages.length ){
                            vm.errors = response.data.messages
                        } else {
                            toastr.error('Došlo je do greške, molimo javite se administratoru')
                        }
                    }
                })
                .catch( function ( error ) {
                    vm.open_loader = false
                    if(error instanceof Error) {
                        console.log( 'Error: ' . error);
                    } else {
                        console.log( 'Unexpected response: ' . error);
                    }
                } );
        }
    }
}
</script>

<style scoped>

</style>