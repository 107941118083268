<template>
    <div>
        <forms
                :settings="settings"
                @onFormButtonClick="onFormButtonClick"
                @onInput="onInput"
        ></forms>
    </div>
</template>

<script>
import axios from "axios";
import toastr from 'toastr';
import Forms from "@/components/form/Forms";
export default {
    name: "PogodnostForma",
    components:{ Forms },
    props:{
        disabled:{
            type: Boolean,
            default: false
        },
        id:{
            default: null
        },
        modal:{
            type: Boolean,
            default: false
        },
        action: {
            type: String
        },
        user: {
            type: Object
        },
    },
    data(){
        return {
            settings: {},
            errors: {},
            route: '/pogodnosti',
            url: 'pogodnost',
            data: {},
            input_data: {},

            brend_select_options: [],
            tip_pogodnosti_select_options: [],
        }
    },

    methods: {

        async fetchTipoviPogodnosti(){
            let vm = this
            await axios.get(process.env.VUE_APP_API_URL + 'tip-pogodnosti', {
                params: {
                    page: 1,
                    per_page: -1,
                }
            })
                .then(function (response) {
                    let tmp = [];
                    response.data.data.forEach(function (item) {
                        tmp.push({
                            key: item.id,
                            value: item.naziv
                        });
                    });
                    vm.tip_pogodnosti_select_options = tmp
                })
                .catch(function (error) {
                    if (error instanceof Error) {
                        console.log('Error: '.error);
                    } else {
                        console.log('Unexpected response: '.error);
                    }
                })
        },

        async fetchBrendovi(){
            let vm = this


            let filters = {}
            let tmp_brendovi = []
            vm.user.user_brendovi.forEach(function (item) {
                tmp_brendovi.push(item.brend.id)
            })
            if (tmp_brendovi.length)
                filters['id'] = tmp_brendovi

            await axios.get(process.env.VUE_APP_API_URL + 'brend', {
                params: {
                    page: 1,
                    per_page: -1,
                    filters: filters
                }
            })
                .then(function (response) {
                    let tmp = [];
                    response.data.data.forEach(function (item) {
                        tmp.push({
                            key: item.id,
                            value: item.naziv
                        });
                    });
                    vm.brend_select_options = tmp
                })
                .catch(function (error) {
                    if (error instanceof Error) {
                        console.log('Error: '.error);
                    } else {
                        console.log('Unexpected response: '.error);
                    }
                })
        },

        async init(){
            let vm = this

            await vm.fetchTipoviPogodnosti()
            await vm.fetchBrendovi()

            if ( vm.action === 'edit' || vm.action === 'show' ){

                axios.get( process.env.VUE_APP_API_URL + vm.url + '/' + vm.id )
                        .then( function ( response ) {
                            vm.data = response.data
                            vm.$emit( 'returnData', response.data )
                            vm.setupForm()
                        } )
                        .catch( function ( error ) {
                            if(error instanceof Error) {
                                console.log( 'Error: ' . error);
                            } else {
                                console.log( 'Unexpected response: ' . error);
                            }
                        } )

            } else if ( vm.action === 'create' ) {
                vm.data = {}
                vm.setupForm()
            } else {
                return
            }
        },

        setupForm(){

            let vm = this;

            vm.buttons = []

            let button_cancel = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
                action: 'redirect', // request
                config: {
                    url: vm.route + ( vm.action === 'edit' ? ( '/' + vm.id) : '' )
                }
            }

            let button_delete = {
                id: 'delete',
                title: 'Obriši',
                type: 'danger',
                action: 'request',
                config: {
                    method: 'DELETE',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let button_edit = {
                id: 'edit',
                title: 'Uredi',
                type: 'success',
                action: 'redirect',
                config: {
                    url: vm.route +  '/' + vm.id +'/edit'
                }
            }

            let button_save = {
                id: 'save',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + vm.url
                }
            }

            let button_update = {
                id: 'update',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'PUT',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let buttons = []


            if( vm.action === 'edit' ){
                buttons.push( button_cancel)
                buttons.push( button_update)
            } else if( vm.action === 'show' ) {
                buttons.push( button_cancel )
                buttons.push( button_delete )
                buttons.push( button_edit )
            }
            else if ( vm.action === 'create' ) {
                buttons.push( button_cancel )
                buttons.push( button_save )
            } else {
                return
            }

            let inputs = [];

            let naziv_input = {
                name: 'naziv',
                type: 'input',
                title: 'Naziv',
                value: vm.data['naziv'] ? vm.data['naziv'] : '',
                width: 6,
                error: vm.errors['naziv'] ? vm.errors['naziv'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 2,
                    input_width: 10
                }
            }

            let tip_pogodnosti_id_input = {
                name: 'tip_pogodnosti_id',
                type: 'select',
                title: 'Tip pogodnosti',
                value: {
                    key: vm.input_data.tip_pogodnosti
                        ? vm.input_data.tip_pogodnosti.id
                        : (
                            vm.data.tip_pogodnosti
                                ? vm.data.tip_pogodnosti.id
                                : ''
                        ),
                    value: vm.input_data.tip_pogodnosti
                        ? vm.input_data.tip_pogodnosti.naziv
                        : (
                            vm.data.tip_pogodnosti
                                ? vm.data.tip_pogodnosti.naziv
                                : ''
                        )
                },
                width: 6,
                error: vm.errors['tip_pogodnosti_id'] ? vm.errors['tip_pogodnosti_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.tip_pogodnosti_select_options,
                    title_width: 2,
                    input_width: 10
                }
            }

            let opis_input = {
                name: 'opis',
                type: 'input',
                title: 'Opis',
                value: vm.data['opis'] ? vm.data['opis'] : '',
                width: 12,
                error: vm.errors['opis'] ? vm.errors['opis'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 1,
                    input_width: 11
                }
            }

            let brend_id_input = {
                name: 'brend_id',
                type: 'select',
                title: 'Brend',
                value: {
                    key: vm.input_data.brend
                        ? vm.input_data.brend.id
                        : (
                            vm.data.brend
                                ? vm.data.brend.id
                                : ''
                        ),
                    value: vm.input_data.brend
                        ? vm.input_data.brend.naziv
                        : (
                            vm.data.brend
                                ? vm.data.brend.naziv
                                : ''
                        )
                },
                width: 3,
                error: vm.errors['brend_id'] ? vm.errors['brend_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: vm.brend_select_options,
                    title_width: 4,
                    input_width: 8
                }
            }

            let broj_input = {
                name: 'broj',
                type: 'input',
                title: 'Broj',
                value: vm.data['broj'] ? vm.data['broj'] : '',
                width: 3,
                error: vm.errors['broj'] ? vm.errors['broj'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: true,
                disabled: true,
                config: {
                    title_width: 4,
                    input_width: 8
                }
            }

            let vrijedi_od_input = {
                name: 'vrijedi_od',
                type: 'datepicker',
                title: 'Vrijedi od',
                value: vm.input_data.vrijedi_od
                    ? vm.input_data.vrijedi_od
                    : (
                        vm.data.vrijedi_od
                            ? vm.data.vrijedi_od
                            : null
                    ),
                width: 3,
                error: vm.errors['vrijedi_od'] ? vm.errors['vrijedi_od'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    mode: 'date',
                    title_width: 4,
                    input_width: 8
                }
            }

            let vrijedi_do_input = {
                name: 'vrijedi_do',
                type: 'datepicker',
                title: 'Vrijedi do',
                value: vm.input_data.vrijedi_do
                    ? vm.input_data.vrijedi_do
                    : (
                        vm.data.vrijedi_do
                            ? vm.data.vrijedi_do
                            : null
                    ),
                width: 3,
                error: vm.errors['vrijedi_do'] ? vm.errors['vrijedi_do'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    mode: 'date',
                    title_width: 4,
                    input_width: 8
                }
            }

            let vrijednost_input = {
                name: 'vrijednost',
                type: 'input',
                title: 'Vrijednost',
                value: vm.input_data['vrijednost'] ? vm.data['vrijednost'] : '',
                width: 4,
                error: vm.errors['vrijednost'] ? vm.errors['vrijednost'] : '',
                note: 'Npr. 1000.00',
                explanation: '',
                placeholder: '1000.00',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 3,
                    input_width: 6
                }
            }

            let minimalni_iznos_racuna_input = {
                name: 'minimalni_iznos_racuna',
                type: 'input',
                title: 'Minimalni iznos računa',
                value: vm.input_data['minimalni_iznos_racuna'] ? vm.data['minimalni_iznos_racuna'] : '',
                width: 8,
                error: vm.errors['minimalni_iznos_racuna'] ? vm.errors['minimalni_iznos_racuna'] : '',
                note: 'Npr. 1000.00',
                explanation: '',
                placeholder: '1000.00',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 3,
                    input_width: 3
                }
            }




            inputs.push( naziv_input )
            inputs.push( tip_pogodnosti_id_input )
            inputs.push( opis_input )
            inputs.push( brend_id_input )
            inputs.push( broj_input )
            inputs.push( vrijedi_od_input )
            inputs.push( vrijedi_do_input )
            inputs.push( vrijednost_input )
            inputs.push( minimalni_iznos_racuna_input )



            let settings = {
                width: 12,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: inputs,
                            },
                        ],
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },


        /**
         *  on input return data
         */

        onInput( name, value ){
            let vm = this;
            if( name === 'brend_id') {
                if( value && vm.input_data.brend && value !== vm.input_data.brend.id )
                    axios.get( process.env.VUE_APP_API_URL +  'brend/' + value )
                            .then( function ( response ) {
                                vm.input_data['brend'] = response.data
                            } )
                            .catch( function ( error ) {
                                if(error instanceof Error) {
                                    console.log( 'Error: ' . error);
                                } else {
                                    console.log( 'Unexpected response: ' . error);
                                }
                            } )
                else vm.input_data['brend'] = null
            } else if( name === 'tip_pogodnosti_id' ){
                if( value && vm.input_data.tip_pogodnosti && value !== vm.input_data.tip_pogodnosti.id )
                    axios.get( process.env.VUE_APP_API_URL +  'tip-pogodnosti/' + value )
                        .then( function ( response ) {
                            vm.input_data['tip_pogodnosti'] = response.data
                        } )
                        .catch( function ( error ) {
                            if(error instanceof Error) {
                                console.log( 'Error: ' . error);
                            } else {
                                console.log( 'Unexpected response: ' . error);
                            }
                        } )
                else vm.input_data['tip_pogodnosti'] = null
            } else {
                vm.input_data[name] = value
                this.setupForm()
            }
        },

        /**
         *  on button click
         */
        onFormButtonClick( id, action, config, data  ){

            if( id === 'save' || id === 'update' ){
                if( data.success){
                    if( id == 'save' )
                        toastr.success( 'Uspješno spremljeno!' )
                    else if ( id == 'update' )
                        toastr.success( 'Uspješno ažurirano!' )
                    this.$router.push({ path: this.route + '/' + data.data.id } )
                } else {
                    this.errors = data.messages
                    this.init()
                }
            } else if( id == 'delete' ){
                toastr.error( 'Uspješno obrisano!' )
                this.$router.push({ path: this.route } )
            }

        },


    },
    mounted() {
        this.init()
    }
}
</script>

<style scoped>

</style>
