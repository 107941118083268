<template>
    <div>

        <breadcrumbs
                :title="'E-mails'"
                :breadcrumbs="allBreadcrumbs"
        >
        </breadcrumbs>


        <nav class="navbar navbar-expand-lg navbar-light" style="background-color: #2F4050;">
            <a
                    :class="{
                            'navbar-brand btn btn-light btn-xl': true,
                            'active': active_route === route
                        }"
                    @click="open(route)"
            >
                <strong>E-mail</strong>
            </a>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">


                    <li class="nav-item">
                        <a
                                role="button"
                                :class="{
                                    'nav-link btn btn-light': true,
                                    'active': active_route === route + '/log'
                                }"
                                @click.prevent="open( route + '/log' )"
                        >
                            <strong>Logovi</strong>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a
                                role="button"
                                :class="{
                                    'nav-link btn btn-light': true,
                                    'active': active_route === route + '/test'
                                }"
                                @click.prevent="open( route + '/test' )"
                        >
                            <strong>Test</strong>
                        </a>
                    </li>

                </ul>
            </div>
        </nav>

    </div>
</template>

<script>
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import axios from "axios";
export default {
    name: "EmailingNavigation",
    components: {Breadcrumbs},
    props:[
            'id',
            'breadcrumbs'
    ],
    data(){
        return {
            email: null,
            naziv: '',
            route: '/emailing/' + this.id,
            active_route: '',
            allBreadcrumbs: []
        }
    },
    methods: {
        open( name ) {
            if( name !== this.active_route ){
                this.active_route = name
                this.$router.push( name )
            }
        },
        getKupac(){
            let vm = this
            axios.get( process.env.VUE_APP_API_URL + 'emailing-predlozak' + '/' + vm.id )
                    .then( function ( response ) {
                        vm.email = response.data
                        vm.setBreadcrumbs()
                    } )
                    .catch( function ( error ) {
                        if(error instanceof Error) {
                            console.log( 'Error: ' . error);
                        } else {
                            console.log( 'Unexpected response: ' . error);
                        }
                    } )
        },
        setBreadcrumbs(){
            let vm = this

            vm.allBreadcrumbs = [
                {
                    path: '/emailing',
                    name: 'E-mails'
                },
                {
                    path: ( '/emailing/' + this.id ),
                    name: ( 'E-mail: ' + this.email.naslov + ' | ' + this.email.brend.naziv + ': ' + this.email.emailing_tip.naziv )
                },
            ]

            vm.breadcrumbs.forEach( function( b ){
                vm.allBreadcrumbs.push( b )
            } )
        }
    },
    mounted() {
        this.active_route = this.$route.path
        this.getKupac()
    },
    watch:{
        breadcrumbs: {
            deep: true,
            handler: function(){
                // this.setBreadcrumbs()
            }
        },
    },
}
</script>

<style scoped>

</style>