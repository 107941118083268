<template>
    <div class="" style="color: black">
        <div class="input-group" style="min-width: 150px">
            <input
                    @input="setFilter"
                    type="text"
                    placeholder=""
                    class="form-control"
                    v-if="filter"
                    v-model="filter.value"
            >
            <span
                    class="input-group-append"
            >
                <button
                        @click="unsetFilter"
                        type="button"
                        class="btn btn-sm btn-dark"
                >
                    x
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "InputFilter",
        props:[
            'data',
            'name',
        ],
        data(){
            return {
                filter: '',
            }
        },
        methods:{
            setFilter(){
                let vm = this;

                setTimeout(function() {
                    vm.$emit( 'set_filter', {
                        key: vm.$props.name,
                        value: vm.filter.value
                    } );
                }, 500);
            },
            unsetFilter(){
                this.filter.value = '';
                this.setFilter();
            }
        },
        beforeMount(){
            this.filter = this.$props.data;
        },
        mounted() {
            // let vm = this;
            // vm.$emit( 'set_filter', {
            //     key: vm.$props.name,
            //     value: vm.filter.value
            // } );
        },

    }
</script>

<style scoped>

</style>