<template>
    <div class="footer">
        <div class="" style="text-align: center">
            <strong>&copy;</strong> Saguaro Info d.o.o.,  {{ year }}., <strong>IzzyCRM</strong>
        </div>
    </div>
</template>

<script>
import moment from "moment";
export default {
    name: "NavigationBottom",
    props:[],
    data(){
        return {
            year: moment().format('YYYY')
        }
    }
}
</script>

<style scoped>

</style>