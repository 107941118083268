<template>
    <div>
        <router-view></router-view>
    </div>
</template>


<script>
export default {
    name: "PogodnostKategorija2Base"
}
</script>

<style scoped>

</style>