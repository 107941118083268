<template>
    <div>
        <forms
                :settings="settings"
                @onFormButtonClick="onFormButtonClick"
        >
        </forms>
    </div>
</template>

<script>
import axios from "axios";
import toastr from 'toastr';
import Forms from "@/components/form/Forms";
export default {
    name: "Knjizica15Forma",
    components: { Forms },
    props:{
        user: {
            type: Object
        },
        disabled:{
            type: Boolean,
            default: false
        },
        id:{
            default: null
        },
        modal:{
            type: Boolean,
            default: false
        },
        action: {
            type: String
        }
    },
    data(){
        return {
            settings: {},
            errors: {},
            route: '/kupci/' + this.$route.params.kupac_id + '/knjizica-15',
            kupac_id: this.$route.params.kupac_id,
            url: 'knjizica-15',
            data: {},
            input_data: {}
        }
    },

    methods: {

        init(){
            let vm = this
            if ( vm.action === 'edit' || vm.action === 'show' ){

                axios.get( process.env.VUE_APP_API_URL + vm.url + '/' + vm.id )
                        .then( function ( response ) {
                            vm.data = response.data
                            vm.$emit( 'returnData', response.data )
                            vm.setupForm()
                        } )
                        .catch( function ( error ) {
                            if(error instanceof Error) {
                                console.log( 'Error: ' . error);
                            } else {
                                console.log( 'Unexpected response: ' . error);
                            }
                        } )

            } else if ( vm.action === 'create' ) {
                vm.data = {}
                vm.setupForm()
            } else {
                return
            }
        },

        setupForm(){

            let vm = this;

            vm.buttons = []

            let button_cancel = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
                action: 'redirect', // request
                config: {
                    url: vm.route + ( vm.action === 'edit' ? ( '/' + vm.id) : '' )
                }
            }

            // let button_delete = {
            //     id: 'delete',
            //     title: 'Obriši',
            //     type: 'danger',
            //     action: 'request',
            //     config: {
            //         method: 'DELETE',
            //         url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
            //     }
            // }

            // let button_edit = {
            //     id: 'edit',
            //     title: 'Uredi',
            //     type: 'success',
            //     action: 'redirect',
            //     config: {
            //         url: vm.route +  '/' + vm.id +'/edit'
            //     }
            // }

            let button_save = {
                id: 'save',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + vm.url
                }
            }

            let button_update = {
                id: 'update',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'PUT',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let buttons = []


            if( vm.action === 'edit' ){
                buttons.push( button_cancel)
                buttons.push( button_update)
            } else if( vm.action === 'show' ) {
                buttons = []
                //buttons.push( button_cancel )
                //buttons.push( button_delete )
                //buttons.push( button_edit )
            }
            else
            if ( vm.action === 'create' ) {
                buttons.push( button_cancel )
                buttons.push( button_save )
            } else {
                return
            }

            let inputs = [];

            let kupac_id_input = {
                name: 'kupac_id',
                type: 'hidden',
                value: vm.kupac_id,
                disabled: vm.disabled,
                config: {
                    hidden:true
                }
            }


            let user_id_input = {
                name: 'user_id',
                type: 'hidden',
                value: vm.user.id,
                disabled: vm.disabled,
                config: {
                    hidden:true
                }
            }



            let  datum = vm.input_data.datum
                    ? vm.input_data.datum
                    : (
                            vm.data.datum
                                    ? vm.data.datum
                                    : ''
                    )
            let datum_input = {
                name: 'datum',
                type: 'datepicker',
                title: 'Datum',
                value: vm.action !== 'create' ? datum : '',
                width: 12,
                error: vm.errors['datum'] ? vm.errors['datum'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: true,
                config: {
                    mode: 'date',
                    title_width: 4,
                    input_width: 8
                }
            }



            // let poslovnica = {
            //     key: vm.input_data.poslovnica
            //             ? vm.input_data.poslovnica.id
            //             : (
            //                     vm.data.poslovnica
            //                             ? vm.data.poslovnica.id
            //                             : ''
            //             ),
            //     value: vm.input_data.poslovnica
            //             ? vm.input_data.poslovnica.naziv
            //             : (
            //                     vm.data.poslovnica
            //                             ? vm.data.poslovnica.naziv
            //                             : ''
            //             )
            // }

            let poslovnice_select_options = []
            let poslovnice_selected_value = {}
            vm.user.user_poslovnice.forEach( function ( item ) {
                poslovnice_select_options.push( {
                    key: item.poslovnica.id,
                    value: item.poslovnica.naziv
                } )
            } )

            if( poslovnice_select_options.length === 1 )
                poslovnice_selected_value = poslovnice_select_options[0]


            let poslovnica_input = {
                name: 'poslovnica_id',
                type: 'select',
                title: 'Poslovnica',
                value: poslovnice_selected_value,
                width: 12,
                error: vm.errors['poslovnica'] ? vm.errors['poslovnica'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: poslovnice_select_options,
                    title_width: 4,
                    input_width: 8
                }
            }



            // let savjetnica = {
            //     key: vm.input_data.savjetnica
            //             ? vm.input_data.savjetnica.id
            //             : (
            //                     vm.data.savjetnica
            //                             ? vm.data.savjetnica.id
            //                             : ''
            //             ),
            //     value: vm.input_data.savjetnica
            //             ? ( vm.input_data.savjetnica.ime + ' ' + vm.input_data.savjetnica.prezime )
            //             : (
            //                     vm.data.savjetnica
            //                             ? ( vm.data.savjetnica.ime + ' ' + vm.data.savjetnica.prezime )
            //                             : ''
            //             )
            // }
            // let savjetnica_input = {
            //     name: 'savjetnica_id',
            //     type: 'select',
            //     title: 'Savjetnica',
            //     value: savjetnica,
            //     width: 12,
            //     error: vm.errors['savjetnica'] ? vm.errors['savjetnica'] : '',
            //     note: '',
            //     explanation: '',
            //     placeholder: '',
            //     required: false,
            //     readonly: vm.disabled,
            //     disabled: vm.disabled,
            //     config: {
            //         options: function ( ) {
            //             let model = this
            //
            //             if( vm.action === 'show'){
            //                 model.options = []
            //                 return
            //             }
            //
            //             let filters = {
            //                 'aktivna': '1',
            //                 'aktivna_poslovnica': '1'
            //             }
            //             let tmp_brendovi = []
            //             vm.user.user_brendovi.forEach( function ( item ) {
            //                 tmp_brendovi.push( item.brend.id )
            //             } )
            //             if( tmp_brendovi.length )
            //                 filters['brend_id'] = tmp_brendovi
            //
            //
            //             axios.get( process.env.VUE_APP_API_URL +  'savjetnica', {
            //                 params: {
            //                     page: 1,
            //                     per_page: -1,
            //                     filters:filters
            //                 }
            //             } )
            //                     .then( function ( response ) {
            //                         let tmp = [];
            //                         response.data.data.forEach( function ( item ) {
            //                             tmp.push({
            //                                 key: item.id,
            //                                 value: item.ime + ' ' + item.prezime
            //                             } );
            //                         } );
            //                         model.options = tmp
            //                     } )
            //                     .catch( function ( error ) {
            //                         if(error instanceof Error) {
            //                             console.log( 'Error: ' . error);
            //                         } else {
            //                             console.log( 'Unexpected response: ' . error);
            //                         }
            //                     } )
            //         },
            //         title_width: 4,
            //         input_width: 8
            //     }
            // }



            inputs.push( kupac_id_input )
            inputs.push( poslovnica_input )
            inputs.push( datum_input )
            //inputs.push( savjetnica_input )
            inputs.push( user_id_input )




            let settings = {
                width: 6,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: inputs,
                            }
                        ],
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },

        onInput( name, value ){
            console.log( name, value )
            // let vm = this
            // if( vm.action === 'show' )
            //     return
            // if ( name === 'poslovnica_id' ){
            //     axios.get( process.env.VUE_APP_API_URL +  'poslovnica/' + value )
            //             .then( function ( response ) {
            //                 vm.input_data['poslovnica'] = response.data
            //             } )
            //             .catch( function ( error ) {
            //                 if(error instanceof Error) {
            //                     console.log( 'Error: ' . error);
            //                 } else {
            //                     console.log( 'Unexpected response: ' . error);
            //                 }
            //             } )
            // } else if( name === 'savjetnica_id' ){
            //     axios.get( process.env.VUE_APP_API_URL +  'savjetnica/' + value )
            //             .then( function ( response ) {
            //                 vm.input_data['savjetnica'] = response.data
            //             } )
            //             .catch( function ( error ) {
            //                 if(error instanceof Error) {
            //                     console.log( 'Error: ' . error);
            //                 } else {
            //                     console.log( 'Unexpected response: ' . error);
            //                 }
            //             } )
            // } else {
            //     vm.input_data[name] = value
            // }
        },

        /**
         *  on button click
         */
        onFormButtonClick( id, action, config, data  ){

            if( id === 'save' || id === 'update' ){
                if( data.success){
                    if( id == 'save' )
                        toastr.success( 'Uspješno spremljeno!' )
                    else if ( id == 'update' )
                        toastr.success( 'Uspješno ažurirano!' )
                    this.$router.push({ path: this.route + '/' + data.data.id } )
                } else {
                    this.errors = data.messages
                    this.init()
                }
            } else if( id == 'delete' ){
                toastr.error( 'Uspješno obrisano!' )
                this.$router.push({ path: this.route } )
            }

        },
    },

    mounted() {
        this.init()
    }
}
</script>

<style scoped>

</style>