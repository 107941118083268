<template>
    <div>

        <div class="tabs-container">
            <ul

                    class="nav nav-tabs" role="tablist"
            >
                <li
                        v-for="(val, key) in tabs"
                        :key="key"
                >
                    <a
                            v-if="tabs.length > 1"
                            :id="'tab-' + key"
                            :class="{
                                'nav-link':true,
                                'active': key === active_tab
                            }"
                            @click.prevent="change( key )"
                    >
                        {{val.name}}
                    </a>
                </li>
            </ul>

            <div class="tab-content">
                <div class="tab-pane active">
                    <div class="panel-body">

                        <div
                                v-for="(val, key) in tabs"
                                :key="key" class="tab-content"
                        >
                            <div
                                v-if="key===active_tab"
                                class="row"
                            >

                                <div
                                        v-for="(section, s_index) in val.sections"
                                        :key="s_index"
                                        :class="section.width"
                                >
                                    <div style="font-size: large"><strong>{{section.name}}</strong></div>
                                    <div v-if="section.name" class=""><br></div>
                                    <div class="row">
                                        <div
                                                v-for="(input, i_index) in section.inputs"
                                                :key="i_index"
                                                :class="input.width"
                                        >
                                            <component
                                                    :is="input.type"
                                                    :name="input.name"
                                                    :value="input.value"
                                                    :title="input.title"
                                                    :error="input.error"
                                                    :note="input.note"
                                                    :explanation="input.explanation"
                                                    :placeholder="input.placeholder"
                                                    :required="input.required"
                                                    :readonly="input.readonly"
                                                    :disabled="input.disabled"
                                                    :config="input.config"
                                                    @onInput="onInput"
                                                    @returnComponent="returnComponent"
                                            ></component>
                                        </div>
                                    </div>
                                    <div v-if="section.name" class="hr-line-dashed"></div>
                                </div>

                            </div>
                        </div>

                        <br>
                        <div
                                v-if="tabs.length > 1"
                        >
                            <a
                                    @click.prevent="change(active_tab + 1)"
                                    :class="{
                                    'btn float-right btn-outline-success':true,
                                    'disabled': active_tab === tabs.length - 1,
                                }"
                                    style="margin-left: 5px"
                            >
                                <i class="fa fa-angle-right"></i>
                            </a>

                            <a
                                    @click.prevent="change( active_tab - 1 )"
                                    :class="{
                                    'btn float-right btn-outline-success': true,
                                    'disabled': active_tab === 0
                                }"
                            >
                                <i class="fa fa-angle-left"></i>
                            </a>
                        </div>
                        <br>
                        <br>

                    </div>
                </div>
            </div>
        </div>
        <div>
        </div>
    </div>
</template>

<script>
import CheckboxComponent from "@/components/form/inputs/CheckboxComponent";
import DatepickerComponent from "@/components/form/inputs/DatepickerComponent";
import EditorComponent from "@/components/form/inputs/EditorComponent";
import InputComponent from "@/components/form/inputs/InputComponent";
import RadioComponent from "@/components/form/inputs/RadioComponent";
import SelectComponent from "@/components/form/inputs/SelectComponent";
import SwitchComponent from "@/components/form/inputs/SwitchComponent";
import UploadComponent from "@/components/form/inputs/UploadComponent";
import HiddenComponent from "@/components/form/inputs/HiddenComponent";
export default {
    name: "Tabs",
    components:{
        CheckboxComponent,
        DatepickerComponent,
        EditorComponent,
        InputComponent,
        RadioComponent,
        SelectComponent,
        SwitchComponent,
        UploadComponent,
        HiddenComponent
    },
    props:{
        list:{
            type: Array,
            required: true
        },
        active:{
            type: Number,
            required: true
        }
    },
    // props:['list','active'],
    data(){
        return {
            active_tab: 0,
            tabs: [],
        }
    },
    methods:{

        init: async function() {

            let vm = this

            vm.active_tab = vm.active;

            vm.tabs = []

            if( !Array.isArray( vm.list ) )
                return;

            vm.$props.list.forEach( function( tab ) {


                let tab_name = tab.name ? tab.name : '???'
                let sections = []

                if( !Array.isArray( tab.sections ) )
                    return;

                tab.sections.forEach( function ( section ) {

                    let section_name = section.name ? section.name : ''
                    let section_width = 'col-md-' + ( section.width ? section.width : 12 )
                    let inputs = []

                    if( !Array.isArray( section.inputs ) )
                        return;


                    section.inputs.forEach( function( input ) {
                        inputs.push( {
                            name: input.name ? input.name : '' ,
                            //class: input.class ?? '',
                            type: input.type ? ( input.type + '-component') : 'input-component',
                            title: input.title ? input.title : '',
                            value: input.value ? input.value : '',
                            width: 'form-group col-md-' + ( input.width ? input.width : '12' ),
                            error: input.error ? input.error : '',
                            note: input.note ? input.note : '',
                            explanation: input.explanation ? input.explanation : '',
                            placeholder: input.placeholder ? input.placeholder : '',
                            required: input.required ? input.required : false,
                            readonly: input.readonly ? input.readonly : false,
                            disabled: input.disabled ? input.disabled : false,
                            config: input.config ? input.config : {}
                        } );

                    } )
                    sections.push( {
                        name: section_name,
                        width: section_width,
                        inputs: inputs
                    } )
                } )
                vm.tabs.push( {
                    name: tab_name,
                    sections:sections
                } )
            } )
        },

        change( key ){
            if( key !== this.active_tab )
                this.$emit( 'change', key )
        },

        /**
         *  on input return data
         */
        onInput( name, value ){
            this.$emit( 'onInput', name, value )
        },

        returnComponent( name, component ){
            this.$emit( 'returnComponent', name, component )
        }
    },
    mounted() {
        this.init()
    },

    watch:{
        list: {
            deep: true,
            handler: function(){
                this.init()
            }
        }
    }


}
</script>

<style scoped>

</style>