<template>
    <div class="" style="">{{ print( row, col ) }}</div>
</template>

<script>
export default {
    name: "TextCell",
    props:{
        row: {
            type: Object,
            required: true
        },
        col: {
            type: Object,
            required: true
        }
    },
    methods:{
        print( row, col ){
            let tmp = col.id.split('.')
            let tmp2 = row
            for( let i in tmp ){
                tmp2 = tmp2[ tmp[i] ]
            }
            return tmp2
        }
    },
    mounted() {
    }
}
</script>

<style scoped>

</style>