<template>
    <div>
        <breadcrumbs
                :title="'Dodatni sadržaji'"
                :breadcrumbs="breadcrumbs"
        >
        </breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Dodatni sadržaj: {{ naziv }}</h5>
            </div>
            <div class="ibox-content">
                <drzava-forma
                        :disabled="false"
                        :id="id"
                        :modal="false"
                        :action="'edit'"
                        @returnData="setData"
                >
                </drzava-forma>
            </div>
        </div>
    </div>

</template>

<script>
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import DrzavaForma from "./DrzavaForma";
export default {
    name: "DrzavaEdit",
    components: {DrzavaForma, Breadcrumbs},
    data(){
        return {
            id: this.$route.params.drzava_id,
            breadcrumbs: [],
            naziv: ''
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/postavke/drzave',
                    name: 'Države'
                },
                {
                    path: ('/postavke/drzave/' + this.id ),
                    name: ('Država: ' + this.naziv )
                },
                {
                    path: ( '/postavke/drzave/' + this.id + '/edit' ),
                    name: ( 'Uredi državu: ' + this.naziv )
                }
            ]
        },
        setData( data ){
            this.naziv = data.naziv ? data.naziv : ''
            this.setBreadcrumbs()
        }
    }
}
</script>

<style scoped>

</style>