<template>
    <div class="">
        <div class="input-group" style="min-width: 150px">
            <multiselect
                    v-model="filter"
                    track-by="key"
                    label="value"
                    placeholder=""
                    :options="options"
                    :searchable="true"
                    :allow-empty="true"
                    :showLabels="false"
                    :multiple="true"
                    :close-on-select="false"
                    @input="setFilter"
            >
            </multiselect>
        </div>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import 'vue-multiselect/dist/vue-multiselect.min.css';


    export default {
        name: "MultiselectFilter",
        components:{ Multiselect },

        props:[
            'data',
            'name',
        ],
        data(){
            return {
                filter: null,
                options: [],
                tmp:[]
            }
        },
        methods:{
            init(){
                let vm = this;
                for( let i in vm.tmp ){
                    vm.options.push( {
                        key: vm.tmp[i].key,
                        value: vm.tmp[i].value
                    } );
                }
            },
            setFilter( val ){
                let ret = []
                for( let i in val ){
                    ret.push( val[i].key );
                }
                this.$emit( 'set_filter', {
                    key: this.$props.name,
                    value: ret.length ? ret : null
                } );
            }
        },
        beforeMount(){
            let vm = this;
            for( let i in vm.$props.data.value ){
                vm.filter.push( vm.$props.data.value[i] )
            }
        },
        mounted() {
            let vm = this
            if(typeof vm.$props.data.search === 'function' ){
                vm.$props.data.search.call( vm )
            } else{
                vm.tmp = vm.$props.data.search;
                vm.init()
            }
        },
        watch:{
            tmp:function(){
                if( typeof this.$props.data.search === 'function' )
                this.init();
            }
        }

    }
</script>

<style scoped>

</style>