<template>
    <div>
        <pogodnosti-navigation
                :id="id"
                :breadcrumbs="breadcrumbs"
        >
        </pogodnosti-navigation>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Pogodnost: {{ naziv }}</h5>
            </div>
            <div class="ibox-content">
                <pogodnost-forma
                        :disabled="true"
                        :id="id"
                        :modal="false"
                        :action="'show'"
                        @returnData="setData"
                        :user="user"
                >
                </pogodnost-forma>
            </div>
        </div>
        <poklon-base
            :pogodnost_id="id"
        ></poklon-base>
    </div>

</template>

<script>
import PogodnostForma from "./PogodnostForma";
import PogodnostiNavigation from "./PogodnostiNavigation";
import PoklonBase from "./Pokloni/PoklonBase";
export default {
    name: "PogodnostDetails",
    components: {PoklonBase, PogodnostiNavigation, PogodnostForma},
    props:['user'],
    data(){
        return {
            id: this.$route.params.pogodnost_id,
            breadcrumbs: [],
            naziv: ''
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/pogosnosti',
                    name: 'Pogodnosti'
                },
                {
                    path: ('/pogodnosti/' + this.id ),
                    name: ('Pogodnosti: ' + this.naziv )
                }
            ]
        },
        setData( data ){
            this.naziv = data.naziv ? data.naziv : ''
            this.setBreadcrumbs()
        }
    }
}
</script>

<style scoped>

</style>
