<template>
    <div>
        <breadcrumbs
                :title="'Kategorije kupaca'"
                :breadcrumbs="breadcrumbs"
        >
        </breadcrumbs>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Kategorije kupaca: {{ naziv }}</h5>
            </div>
            <div class="ibox-content">
                <kategorija2-forma
                        :disabled="false"
                        :id="id"
                        :modal="false"
                        :action="'edit'"
                        @returnData="setData"
                        :user="user"
                >
                </kategorija2-forma>
            </div>
        </div>
    </div>

</template>

<script>
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import Kategorija2Forma from "./Kategorija2Forma";
export default {
    name: "Kategorija2Edit",
    components: {Kategorija2Forma, Breadcrumbs},
    props: ['user'],
    data(){
        return {
            id: this.$route.params.kategorija2_id,
            breadcrumbs: [],
            naziv: ''
        }
    },
    methods:{
        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/kategorije-kupaca2',
                    name: 'Kategorije kupaca'
                },
                {
                    path: ('/kategorije-kupaca2/' + this.id ),
                    name: ('Kategorija: ' + this.naziv )
                },
                {
                    path: ( '/kategorije-kupaca2/' + this.id + '/edit' ),
                    name: ( 'Kategorija : ' + this.naziv )
                }
            ]
        },
        setData( data ){
            this.naziv = data.naziv ? data.naziv : ''
            this.setBreadcrumbs()
        }
    }
}
</script>

<style scoped>

</style>