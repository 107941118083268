<template>
    <tr v-if="headers">
        <th
                class="text-primary"
                v-for="(header, index) in headers"
                :key="header.id"
                style="min-width: 150px"
        >
            <div class="row" >
                <div
                        class="btn-group"
                        role="group"
                        style="margin-left: 10px; margin-right: 5px"
                >

                    <div class="btn-group float-left show">
                        <button
                                class="btn btn-xs float-left btn-light dropdown-toggle"
                                style="height: 25px"
                                @click="open( header.id )"
                                data-toggle="dropdown"
                        >
                            <i class="fa fa-cog"></i>
                        </button>
                        <ul
                                :class="{'dropdown-menu':true, 'show':opened === header.id }"
                        >
                            <li v-if="header.orderable" >
                                <a :class="{
                                        'btn':true,
                                        'btn-xs': true,
                                        'btn-primary': header.id === order_by && order === 'asc',
                                        'btn-light': header.id !== order_by || order === 'asc'
                                    }"
                                        :disabled="header.id === order_by && order === 'asc'"
                                        @click="orderBy( header.id, 'asc')"
                                        style="height: 25px"
                                >
                                    <i class="glyphicon glyphicon-sort-by-attributes"></i>
                                    A - Z
                                </a>
                            </li>
                            <li v-if="header.orderable" >
                                <a :class="{
                                            'btn':true,
                                            'btn-xs': true,
                                            'btn-primary': header.id === order_by && order === 'desc',
                                            'btn-light': header.id !== order_by || order !== 'desc'
                                        }"
                                        :disabled="header.id === order_by && order === 'desc'"
                                        @click="orderBy( header.id, 'desc')"
                                        style="height: 25px"
                                >
                                    <i class="glyphicon glyphicon-sort-by-attributes-alt"></i>
                                    Z -> A
                                </a>
                            </li>
                            <li>
                                <a
                                        v-if="index !== 0"
                                        class="btn btn-xs  btn-light"
                                        @click="move( header.id, -1 )"
                                        style="height: 25px"
                                >
                                    <i class="fa fa-arrow-left"></i>
                                    Pomakni lijevo
                                </a>
                            </li>
                            <li>
                                <a
                                        v-if="index !== headers.length -1"
                                        class="btn btn-xs btn-light"
                                        @click="move( header.id, 1 )"
                                        style="height: 25px"
                                >
                                    <i class="fa fa-arrow-right"></i>
                                    Pomakni desno
                                </a>
                            </li>


                        </ul>
                    </div>

                    <h5 style="margin-left: 10px; margin-right: 10px">
                        <strong>
                            {{ header.title }}
                        </strong>
                    </h5>

                </div>
            </div>
        </th>
    </tr>

</template>

<script>
export default {
    name: "Title",
    props:{
        table_header: {
            type: Array,
            required: true,
        },
        order_by: {
            'type': String,
            required: true,
        },
        order: {
            'type': String,
            required: true
        }
    },
    data(){
        return{
            opened: false,
            headers: [],
        }
    },
    methods:{
        init(){
            let vm = this
            vm.headers = []
            vm.$props.table_header.forEach( function ( item ) {
                if ( item.visible && item.column_visibility_group.visible ){
                    vm.headers.push( item )
                }
            } )
        },
        orderBy( id, order ){
            this.opened = ''
            this.$emit( 'orderBy', id, order )
        },
        move( id, direction){
            this.opened = ''
            this.$emit( 'move', id, direction );
        },
        open( id ){
            if ( id === this.opened ){
                this.opened = ''
            } else {
                this.opened = id
            }
        }
    },
    mounted() {
        this.init()
    },
    watch:{
        table_header:{
            handler: function (){
                this.init()
            },
            deep: true
        }
    }
}
</script>

<style scoped>

</style>